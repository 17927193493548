import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import color from './color';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export const newBreakpoints = {
  mb_0: 0,
  mb_345: 345,
  mb_360: 360,
  st_600: 600,
  st_768: 768,
  lt_1024: 1024,
  lt_1366: 1366,
  dt_1440: 1440,
  dt_1720: 1720,
  dt_1920: 1920,
  dt_2184: 2184,
  dt_2560: 2560,
  dt_3840: 3840,
};

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 836,
    lg: 1200,
    xl: 1536,
    // 아래는 메인 개편 후 정해진 디바이스 사이즈 입니다!
    ...newBreakpoints,
  },
} as const;

export const theme = createTheme({
  // background 타입때문에 임시로 추가 ignore 처리
  // @ts-ignore
  palette: {
    // [todo]: color.json의 경우 플린님이 만둘어두신 components/ads 디렉토리로 이동해야 함.
    ...color,
    primary: {
      main: '#f300ba',
    },
    secondary: {
      main: color.violet['600'],
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: '10px',
          '& .MuiSvgIcon-root': {
            height: 1,
            width: 1,
          },
        },
      },
    },
  },
  breakpoints,
});

export default function ThemeMui({ children }: Props) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
