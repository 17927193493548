export const colors = {
  pink: {
    /** #ff00cd */
    c1: '#ff00cd',
    /** #f300ba */
    c2: '#f300ba',
    /** #FFE7FA */
    c3: '#FFE7FA',
    /** #f300ba */
    c4: '#f300ba',
    /** #923086 */
    c5: '#923086',
    /** #EF0039 */
    c6: '#EF0039',
    /** #fff2fe */
    c7: '#fff2fe',
    /** #ffd1f6 */
    c8: '#ffd1f6',
    /** #e500af */
    c9: '#e500af',
    /** #fbf3ff */
    c10: '#fbf3ff',
    /** #efe7f4 */
    c11: '#efe7f4',
  },
  gray: {
    /** #f0f0f0 */
    c1: '#f0f0f0',
    /** #eaeaea */
    c2: '#eaeaea',
    /** #cdcdcd */
    c3: '#cdcdcd',
    /** #aaaaaa */
    c4: '#aaaaaa',
    /** 7c7c7c */
    c5: '#7c7c7c',
    /** cacaca */
    c6: '#cacaca',
    /** d2d2d2 */
    c7: '#d2d2d2',
    /** #e0e0e0 */
    c8: '#e0e0e0',
    /** #777777 */
    c9: '#777777',
    /** #dddddd */
    c10: '#dddddd',
    /** #9e9e9e */
    c11: '#9e9e9e',
    /** #adadada */
    c12: '#adadad',
    /** #fafafc */
    c13: '#fafafc',
    /** #dfdfdf */
    c14: '#dfdfdf',
    /** #c2c2c2 */
    c15: '#c2c2c2',
    /** #bcbcbc */
    c16: '#bcbcbc',
    /** #f7f7fc */
    c17: '#f7f7fc',
    /** #eeeeff */
    c18: '#eeeeff',
    /** #eaeaf6 */
    c19: '#eaeaf6',
    /** #f2f2f2 */
    c20: '#f2f2f2',
  },
  black: {
    /** #000000 */
    c1: '#000000',
    /** #333333 */
    c2: '#333333',
    /** #545454 */
    c3: '#545454',
    /** #121212 */
    c4: '#121212',
  },
  purple: {
    /** #9b64c6 */
    c1: '#9b64c6',
  },
  blue: {
    /** #3B43DD */
    c1: '#3B43DD',
    /** #3a3e94 */
    c2: '#3a3e94',
    /** #ecf9fa */
    c3: '#ecf9fa',
    /** #d5f3ee */
    c4: '#d5f3ee',
  },
  /** #ffffff */
  white: '#ffffff',
  orange: {
    /** #FFF5EE */
    c1: '#FFF5EE',
    /** #FF6B00 */
    c2: '#FF6B00',
  },
};
