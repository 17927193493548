import { useEffect, useState } from 'react';
import ReactDom from 'react-dom';

export interface PortalProps {
  children: any;
  container?: string | HTMLElement;
  disablePortal?: boolean;
}
export const Portal = ({ children, container, disablePortal }: PortalProps) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    setIsClient(true);
  }, [disablePortal]);

  if (!isClient || disablePortal) return children;
  const containerBody = typeof container === 'string' ? document.querySelector(container) : (container || document.body);
  return <>{ReactDom.createPortal(children, containerBody)}</>;
};
