import {
  ComponentType,
  createElement,
  ReactElement,
  forwardRef,
  Ref, ComponentPropsWithRef,
} from 'react';

type TDefaultHtmlProps = Record<string, any>;
type TWithClass<T = any> = { className?: string } & Omit<T, 'className'>;

export const withAttrs = <T extends ComponentType<any>>(
  OriginComponent: T | string,
  attrs:
    | Partial<TWithClass<ComponentPropsWithRef<T> | TDefaultHtmlProps>>
    | ((propsInAttrs: Partial<TWithClass<ComponentPropsWithRef<T> | TDefaultHtmlProps>>) => Partial<TWithClass<ComponentPropsWithRef<T> | TDefaultHtmlProps>>),
) => {
  return forwardRef(
    (
      props: Partial<TWithClass<ComponentPropsWithRef<T> | TDefaultHtmlProps>>,
      ref: Ref<any>,
    ): ReactElement<Partial<TWithClass<ComponentPropsWithRef<T> | TDefaultHtmlProps>>> => {
      const resolvedAttrs =
        typeof attrs === 'function' ? attrs(props) : attrs;

      const className = [
        (resolvedAttrs.className || ''),
        (props.className || ''),
      ].join(' ');

      return createElement(OriginComponent, {
        ...props,
        ...resolvedAttrs,
        className,
        ref,
      });
    },
  );
};
