import React from 'react';
import { ScrollTopPropsType } from './ScrollTop.types';
import { ScrollTopContainer } from './ScrollTop.styles';

const ScrollTop = ({ showButton, showDeviceSizes, showButtonThreshold, offsetTop, target }: ScrollTopPropsType): React.ReactElement => {
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false);

  const getTargetBaseline = (additionalValue?: number) => {
    let scrollToValue = 0;
    if (additionalValue && additionalValue > 0) scrollToValue += additionalValue;
    if (!target) return scrollToValue;

    const targetElement = target.ref.current;
    if (!targetElement) return scrollToValue;

    scrollToValue += targetElement.offsetTop - window.innerHeight;
    if (target.position === 'bottom') scrollToValue += targetElement.getBoundingClientRect().height;
    return scrollToValue;
  };

  const onClickScrollTop = () => {
    const topValue = getTargetBaseline(offsetTop);
    window.scrollTo({ top: topValue < 0 ? 0 : topValue, behavior: 'smooth' });
  };

  const tapEvent = {
    [typeof window !== 'undefined' && 'ontouchstart' in window ? 'onTouchStart' : 'onClick']: onClickScrollTop,
  };

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const handleDocumentScroll = () => {
      setIsScrolled(Math.floor(window.scrollY) > getTargetBaseline(showButtonThreshold));
    };

    handleDocumentScroll();
    document.addEventListener('scroll', handleDocumentScroll);
    return () => {
      document.removeEventListener('scroll', handleDocumentScroll);
    };
  }, [target, showButtonThreshold]);

  return (
    <ScrollTopContainer showTopButton={showButton && isScrolled} showDeviceSizes={showDeviceSizes} {...tapEvent}>
      <div className="mobile-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20C12.5523 20 13 19.5523 13 19V5C13 4.44771 12.5523 4 12 4C11.4477 4 11 4.44771 11 5V19C11 19.5523 11.4477 20 12 20Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 12.7071C4.68342 13.0976 5.31658 13.0976 5.70711 12.7071L12 6.41421L18.2929 12.7071C18.6834 13.0976 19.3166 13.0976 19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071Z"
            fill="#313135"
          />
        </svg>
      </div>

      <div className="desktop-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.99985 10L9.99985 2M9.99985 2L17.9998 10M9.99985 2L9.99985 18" stroke="#313135" strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </ScrollTopContainer>
  );
};

export default ScrollTop;
