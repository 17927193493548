import { PREV_CATEGORY_CODES, PREV_CATEGORY_CODES_STARTWITH } from '@components/productList/ProductList.constants';
import { CategoryOutputV4 } from '../generated/graphql';

export const findLeafNodeCategories = (categories: CategoryOutputV4[]) => {
  const result = [];

  const loop = (category: CategoryOutputV4) => {
    if (!category?.children || (category?.children || []).length === 0) {
      result.push(category);
      return;
    }

    return category.children.map((categories) => loop(categories));
  };

  categories.map((category) => loop(category));
  return result;
};

export const checkPrevCategory = (categoryCode: string) => {
  if (!categoryCode) return false;
  if (PREV_CATEGORY_CODES.includes(categoryCode)) return true;
  return PREV_CATEGORY_CODES_STARTWITH.some((prevCategoryCode) => {
    return prevCategoryCode === categoryCode?.substring(0, prevCategoryCode.length);
  });
};
