import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from '@api/mutation';
import { CommonContext, Notifications } from '../provider';

// notification에 대한 custom hook 입니다.
export const useNotification = (): [any, (type: string, message?: string) => void, (type: string[]) => void, boolean] => {
  const { notiLoading, notification, onAddNotification, onRemoveNotification } = useContext(CommonContext);
  // 알림 추가하기 액션
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  // 알림 제거하기 액션
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  const findNoti = (type: string) => {
    return notification.find((x: Notifications) => x.type === type);
  };

  const addNoti = async (type: string, message?: string) => {
    if (!type) {
      return;
    }

    // 알림 추가
    const notiInfo = { type: type, message: message };
    await addNotification({
      variables: notiInfo,
    }).then(() => {
      onAddNotification(type, message);
    });
  };

  const deleteNoti = async (type: string[]) => {
    if (!type) {
      return;
    }

    // 알림 추가
    await deleteNotification({
      variables: { type: type },
    }).then(() => {
      onRemoveNotification(type);
    });
  };

  return [findNoti, addNoti, deleteNoti, notiLoading];
};
