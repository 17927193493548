import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import NoticeOutlineIcon from 'src/assets/icons/NoticeOutlineIcon';
import { ISnackbarProps } from '@components/_new_components/Common/layout/BottomController/Snackbar/Snackbar.types';
import CloseIcon from '../../../../../../src/assets/icons/snackbar/CloseIcon';
import { IconButton } from '../../../../../../src/assets/icons/styled';
import { SnackbarContainer, SnackbarMessageText } from './Snackbar.styles';
export default function Snackbar({ snackbarData, onClose }: ISnackbarProps) {
  useEffect(() => {
    let timeout = setTimeout(onClose, snackbarData?.options?.hideDuration || 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [snackbarData.message]);

  return (
    <SnackbarContainer className={'snackbar'} type={snackbarData?.options?.type}>
      {snackbarData?.options?.type === 'info' && <NoticeOutlineIcon size={16} color={'#4E4EFF'} />}
      <SnackbarMessageText>
        <div dangerouslySetInnerHTML={{ __html: snackbarData.message }} />
      </SnackbarMessageText>
      {snackbarData?.options?.isShowCloseButton && (
        <IconButton onClick={onClose}>
          <CloseIcon size={20} color={'white'} />
        </IconButton>
      )}
    </SnackbarContainer>
  );
}
