import React from 'react';

interface Props {
  isActive: boolean;
}

export default function SearchIcon({ isActive }: Props) {
  return (
    <>
      {isActive && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="11" cy="11" r="6.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M16 16L19.5 19.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
      )}

      {!isActive && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="11" cy="11" r="6.5" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M16 16L19.5 19.5" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
      )}
    </>
  );
}
