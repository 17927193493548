import React from 'react';

export default function WeiboGrayIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1834_35222)">
        <path
          d="M10 0C4.47734 0 0 4.47734 0 10C0 15.5227 4.47734 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47734 15.5227 0 10 0ZM8.86588 15.7093C5.94128 15.7093 2.95202 14.2918 2.95202 11.9609C2.95202 10.7421 3.72399 9.33299 5.0535 8.00307C6.82946 6.22834 8.89985 5.41954 9.67836 6.19887C10.0219 6.5422 10.055 7.13637 9.83448 7.84614C9.71928 8.20378 10.1698 8.00552 10.1698 8.00634C11.6053 7.40522 12.8573 7.37023 13.3146 8.02394C13.5585 8.37238 13.5353 8.86077 13.3103 9.42731C13.2061 9.68777 13.3424 9.7287 13.5409 9.78783C14.3495 10.0389 15.2495 10.6449 15.2495 11.7136C15.2493 13.4819 12.6993 15.7093 8.86588 15.7093ZM14.1598 8.29729C14.2545 8.00512 14.195 7.67223 13.9746 7.42772C13.7545 7.18404 13.4287 7.09115 13.1284 7.15478V7.15437C12.878 7.2088 12.631 7.04798 12.578 6.79795C12.5236 6.5465 12.684 6.29954 12.9348 6.24614C13.5491 6.11581 14.2144 6.30568 14.6648 6.80511C15.1165 7.30496 15.237 7.98588 15.0441 8.58292C14.9653 8.82742 14.7036 8.96061 14.4595 8.88266C14.2154 8.80307 14.0818 8.54097 14.1606 8.29729H14.1598ZM16.8671 9.17197C16.8671 9.17238 16.8671 9.17361 16.8671 9.17402C16.7752 9.45698 16.4708 9.61207 16.1876 9.52041C15.9032 9.42895 15.7481 9.12511 15.84 8.84113L15.8398 8.84031C16.1215 7.9687 15.9429 6.97453 15.2853 6.24552C14.6269 5.51652 13.6567 5.23765 12.7599 5.42834C12.4681 5.49074 12.1817 5.30455 12.1193 5.0132C12.0569 4.72184 12.2429 4.43499 12.5344 4.37258H12.5348C13.7952 4.10476 15.1607 4.49575 16.0861 5.52225C17.0124 6.54731 17.2624 7.94517 16.8671 9.17197Z"
          fill="#6D6D70"
        />
        <path
          d="M8.88884 11.7499C8.75012 11.6946 8.57661 11.7615 8.49518 11.8978C8.4162 12.0353 8.45978 12.1914 8.59891 12.2483C8.74009 12.3066 8.92076 12.2395 9.00239 12.0995C9.08035 11.9592 9.02981 11.8014 8.88884 11.7499Z"
          fill="#6D6D70"
        />
        <path
          d="M8.32537 9.32666C5.99248 9.55765 4.22286 10.9862 4.37427 12.5181C4.52588 14.0501 6.54061 15.1049 8.87371 14.8747C11.2072 14.6441 12.976 13.2154 12.825 11.6825C12.6734 10.1506 10.6595 9.09566 8.32537 9.32666ZM10.3728 12.94C9.89672 14.017 8.52711 14.5911 7.36475 14.2167C6.24312 13.8543 5.76823 12.7462 6.25949 11.7485C6.74153 10.7699 7.99698 10.2167 9.10716 10.5058C10.2564 10.8029 10.8428 11.8871 10.3728 12.94Z"
          fill="#6D6D70"
        />
        <path
          d="M7.99806 12.1183C7.63653 11.9671 7.16963 12.1226 6.94681 12.4722C6.72093 12.8231 6.82671 13.2415 7.18558 13.405C7.54957 13.5714 8.03285 13.4134 8.25853 13.0533C8.48032 12.6893 8.36328 12.274 7.99806 12.1183Z"
          fill="#6D6D70"
        />
      </g>
      <defs>
        <clipPath id="clip0_1834_35222">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
