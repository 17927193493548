import { PageNameEnum, TPageName } from '@components/common/CardGrid/type';

export function pageHistoryToPageName(pageHistory: string): TPageName | '-' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, pageName, pageSubName] = pageHistory.replace(/^\//, '').split(/[/?]/);
  if (pageName === undefined || pageName === '') {
    return 'main';
  }

  if (pageName === `${PageNameEnum.event}`) {
    // 할인은 /event/sale로 들어오는데, 이때는 pageSubName이 sale이다.
    return pageSubName === `${PageNameEnum.sale}` ? `${PageNameEnum.sale}` : `${PageNameEnum.event}`;
  }

  if (pageName === `${PageNameEnum.category}`) {
    // 카테고리 페이지에서는 카테고리 아이디를 pageSubName으로 받는다.
    // 만약 이 카테고리가 프로모션 카테고리라면, pageName을 event로 바꿔준다.
    const categoryIdsThatArePromotion = (JSON.parse(localStorage.getItem('categoryIdsThatArePromotion')) ?? []) as string[];
    if (categoryIdsThatArePromotion.includes(pageSubName)) {
      return `${PageNameEnum.event}`;
    }
    return `${PageNameEnum.category}`;
  }

  if ([`${PageNameEnum['acon-only']}`, `${PageNameEnum.new}`, `${PageNameEnum.best}`, `${PageNameEnum.adult}`, `${PageNameEnum.brand}`].includes(pageName)) {
    return pageName as TPageName;
  }

  if (pageName === 'tag') {
    return PageNameEnum['tag search'];
  }

  if (pageName === 'search') {
    return PageNameEnum['keyword search'];
  }

  if (pageName === 'product') {
    return PageNameEnum['prod detail'];
  }

  return '-';
}
