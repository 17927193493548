import styled from '@emotion/styled';

export const Skeleton = styled.div<{ width?: string; height?: string; marginTop?: number }>`
  background-color: #f5f5f6;
  border-radius: 4px;

  ${(props) => (props?.width ? `width: ${props.width};` : 'width: 100%;')}
  ${(props) => (props?.height ? `height: ${props.height};` : 'height: 100%;')}
  ${(props) => (props?.marginTop ? `margin-top: ${props.marginTop}px;` : '')}
`;
