import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { CategoryContainer } from '@components/_new_components/Common/layout/Category/Category.styles';
import CategoryItem from '@components/_new_components/Common/layout/Category/CategoryItem';
import { CategorySkeleton } from '@components/_new_components/Common/layout/Category/CategorySkeleton';
import { CategoryCodes } from '@components/_new_components/Common/layout/Category/Category.constants';
import { CategoryPropsType } from '@components/_new_components/Common/layout/Category/Category.types';
import { Categories_Input_Sort_Criterion, Categories_Input_Sort_Key, Language_Code, useCategoriesV2Query } from '../../../../../src/generated/graphql';

export default function Category({ isSideCategory = false, ...rest }: CategoryPropsType) {
  const { i18n } = useTranslation();
  const { isReady, query, pathname } = useRouter();
  const categoryCode = pathname === '/category/[id]' ? query?.id?.toString() : null;

  const [expandedCategoryCode, setExpandedCategoryCode] = React.useState<string | null>(CategoryCodes[0]);

  const { data, loading } = useCategoriesV2Query({
    variables: {
      language: i18n.language.toUpperCase() as Language_Code,
      condition: {
        visibleOnly: true,
        codes: CategoryCodes,
      },
      sort: {
        key: Categories_Input_Sort_Key.Code,
        criterion: Categories_Input_Sort_Criterion.Asc,
      },
    },
  });
  const categories = data?.categoriesV2 || [];

  const handleCategoryGroupClick = (categoryCode: string) => {
    setExpandedCategoryCode(expandedCategoryCode === categoryCode ? null : categoryCode);
  };

  // 현재 카테고리 코드에 맞는 카테고리 활성화
  React.useEffect(() => {
    if (!isReady || !categoryCode || categories.length === 0) return;

    categories.forEach((firstLevelCategory) => {
      const result = firstLevelCategory.children.some((secondLevelCategory) => {
        return secondLevelCategory.code === categoryCode || secondLevelCategory.children.some((thirdLevelCategory) => thirdLevelCategory.code === categoryCode);
      });
      if (result) setExpandedCategoryCode(firstLevelCategory.code);
    });
  }, [isReady, categoryCode, categories]);

  return (
    <CategoryContainer {...rest}>
      {loading && <CategorySkeleton />}

      {categories.map((category) => (
        <CategoryItem
          key={category.id}
          isActiveCategoryCode={categoryCode}
          firstLevelCategory={category}
          expandedCategoryCode={expandedCategoryCode}
          isSideCategory={isSideCategory}
          handleCategoryGroupClick={handleCategoryGroupClick}
        />
      ))}
    </CategoryContainer>
  );
}
