import React from 'react';
import { useTheme } from '@emotion/react';

export default function GnbUser({ className = '', size = 24 }) {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <div className={className}>
      <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 4.56666C9.97828 4.56666 9.15001 5.39494 9.15001 6.41666C9.15001 7.43839 9.97828 8.26666 11 8.26666C12.0217 8.26666 12.85 7.43839 12.85 6.41666C12.85 5.39494 12.0217 4.56666 11 4.56666ZM7.35001 6.41666C7.35001 4.40082 8.98417 2.76666 11 2.76666C13.0158 2.76666 14.65 4.40082 14.65 6.41666C14.65 8.4325 13.0158 10.0667 11 10.0667C8.98417 10.0667 7.35001 8.4325 7.35001 6.41666Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.69235 17.4333H17.3077C17.0703 16.5927 16.5019 15.8066 15.6455 15.1643C14.4886 14.2966 12.8491 13.7333 11 13.7333C9.15086 13.7333 7.51142 14.2966 6.35455 15.1643C5.4981 15.8066 4.92969 16.5927 4.69235 17.4333ZM5.27455 13.7243C6.77183 12.6013 8.79906 11.9333 11 11.9333C13.201 11.9333 15.2282 12.6013 16.7255 13.7243C18.223 14.8474 19.2333 16.4678 19.2333 18.3333C19.2333 18.8304 18.8304 19.2333 18.3333 19.2333H3.66667C3.16961 19.2333 2.76667 18.8304 2.76667 18.3333C2.76667 16.4678 3.777 14.8474 5.27455 13.7243Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
