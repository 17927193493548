import React from 'react';
import styled from '@emotion/styled';

interface Props {
  isExpanded: boolean;
}

const CategoryChevronIconBox = styled.div`
  cursor: pointer;
`;

export default function CategoryChevronIcon({ isExpanded }: Props) {
  return (
    <CategoryChevronIconBox>
      {isExpanded ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5405 9.53999C11.7943 9.28615 12.2059 9.28615 12.4597 9.53999L16.4597 13.54C16.7136 13.7938 16.7136 14.2054 16.4597 14.4592C16.2059 14.7131 15.7943 14.7131 15.5405 14.4592L12.0001 10.9188L8.45972 14.4592C8.20588 14.7131 7.79432 14.7131 7.54048 14.4592C7.28664 14.2054 7.28664 13.7938 7.54048 13.54L11.5405 9.53999Z"
            fill="#18181B"
          />
        </svg>
      ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4595 14.4581C12.2057 14.7119 11.7941 14.7119 11.5403 14.4581L7.54028 10.4581C7.28644 10.2042 7.28644 9.79266 7.54028 9.53882C7.79412 9.28498 8.20568 9.28498 8.45952 9.53882L11.9999 13.0792L15.5403 9.53882C15.7941 9.28498 16.2057 9.28498 16.4595 9.53882C16.7134 9.79266 16.7134 10.2042 16.4595 10.4581L12.4595 14.4581Z"
            fill="#18181B"
          />
        </svg>
      )}
    </CategoryChevronIconBox>
  );
}
