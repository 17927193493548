import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { getCurrencyUnit } from '@lib/currencyUnit';
import { exchangeRate, exchangeIntoForeignCurrency, estimatedPaymentAmount } from '@lib/price';
import { LanguageCodeEnum, useGetExchangeRateQuery } from 'src/generated/graphql';
import { LANGUAGE_CODE, LanguageCode } from '../constants';

type PriceWithCurrency = {
  amount: number;
  currency: LanguageCode;
  formattedPrice: (space?: boolean) => string;
  currencyUnit: (isAbbreviated?: boolean) => CurrencyUnit;
  fixedPrice: (toFixed?: 0 | 1 | 2 | null) => string;
};

type CurrencyUnit = {
  unit: string;
  position: 'left' | 'right';
};

type ExchangePrice = {
  payment: PriceWithCurrency;
  display: PriceWithCurrency;
};

/**
 * 영문몰의 경우 소수점 2번째 자리까지 고정하여 표시
 * 일문몰의 경우 소수점을 버리고 정수로 표시
 * 중문몰은 기존처럼 유지
 */
export function fixedPrice(amount: number, languageCode: LanguageCode) {
  return (toFixed?: 0 | 1 | 2 | null) => {
    const isFixed = Number.isInteger(toFixed);
    let amountStr = amount.toString();

    const isNegative = amount < 0;
    if (isNegative) {
      // eslint-disable-next-line no-param-reassign
      amount = Math.abs(amount);
      amountStr = amountStr.replace('-', '');
    }

    try {
      const decimalAmount = new Decimal(amount);
      if (!isFixed && languageCode === LANGUAGE_CODE.EN) {
        amountStr = decimalAmount.toFixed(2);
      }
      if (!isFixed && languageCode === LANGUAGE_CODE.JP) {
        amountStr = decimalAmount.toDecimalPlaces(0, Decimal.ROUND_FLOOR).toString();
      }
      if (isFixed && [LANGUAGE_CODE.JP, LANGUAGE_CODE.EN].includes(languageCode)) {
        amountStr = decimalAmount.toDecimalPlaces(toFixed, Decimal.ROUND_FLOOR).toString();
      }
    } catch (e) {
      /*ignoreError*/
    }
    return amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
}

export default function useExchange(languageCode: LanguageCode) {
  const isNotSupportedCurrencyForPayment = languageCode === LANGUAGE_CODE.CN || languageCode === LANGUAGE_CODE.JP;

  const { data, loading } = useGetExchangeRateQuery({
    variables: {
      // [TODO] : LanguageCode => LanguageCodeEnum
      languageCode: languageCode as string as LanguageCodeEnum,
    },
    skip: !isNotSupportedCurrencyForPayment,
    pollInterval: 12 * 60 * 60 * 1000,
  });

  const isReadyExchange = isNotSupportedCurrencyForPayment ? !loading : true;

  const currencyUnit = (languageCode: LanguageCode) => {
    return (isAbbreviated?: boolean) => {
      return getCurrencyUnit(languageCode, isAbbreviated);
    };
  };

  const formattedPrice = (amount: number, languageCode: LanguageCode) => {
    return (space?: boolean) => {
      const formattedPrice = fixedPrice(amount, languageCode)(null);
      const spaceStr = space ? ' ' : '';

      const { unit, position } = currencyUnit(languageCode)();

      if (position === 'left') {
        return unit + spaceStr + formattedPrice;
      }
      if (position === 'right') {
        return formattedPrice + spaceStr + unit;
      }
    };
  };

  const krwToUsdExchangeRate = exchangeRate(LANGUAGE_CODE.KO, LANGUAGE_CODE.EN);

  /**
   * 지정한 언어에 맞는 표시용 금액과 실 결제 금액을 표시해주는 메소드입니다.
   * @param krwPrice 원 금액
   * @returns 표시용 금액과 실 결제 금액을 표시합니다.
   */
  const ex = (krwPrice: number) => {
    if (!isReadyExchange) {
      throw new Error('isReadyExchange가 준비되고 난 이후에 실행해주세요.');
    }

    const paymentGateExchangeRate = isNotSupportedCurrencyForPayment ? 1 / data?.exchangeRate : 1;

    const usdAmount = exchangeIntoForeignCurrency(krwPrice, krwToUsdExchangeRate);

    if (languageCode === LANGUAGE_CODE.KO) {
      return {
        payment: {
          currency: languageCode,
          amount: krwPrice,
          formattedPrice: formattedPrice(krwPrice, languageCode),
          fixedPrice: fixedPrice(krwPrice, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
        display: {
          currency: languageCode,
          amount: krwPrice,
          formattedPrice: formattedPrice(krwPrice, languageCode),
          fixedPrice: fixedPrice(krwPrice, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
      } as ExchangePrice;
      // eslint-disable-next-line brace-style
    }
    // 원화가 아닐 경우에는 모두다 달러로 결제가 됨
    else if (languageCode === LANGUAGE_CODE.EN) {
      return {
        payment: {
          currency: languageCode,
          amount: usdAmount,
          formattedPrice: formattedPrice(usdAmount, languageCode),
          fixedPrice: fixedPrice(usdAmount, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
        display: {
          currency: languageCode,
          amount: usdAmount,
          formattedPrice: formattedPrice(usdAmount, languageCode),
          fixedPrice: fixedPrice(usdAmount, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
      } as ExchangePrice;
    }
    const displayAmount = estimatedPaymentAmount(usdAmount, paymentGateExchangeRate);
    return {
      payment: {
        currency: LANGUAGE_CODE.EN,
        amount: usdAmount,
        formattedPrice: formattedPrice(usdAmount, LANGUAGE_CODE.EN),
        fixedPrice: fixedPrice(usdAmount, LANGUAGE_CODE.EN),
        currencyUnit: currencyUnit(LANGUAGE_CODE.EN),
      },
      display: {
        currency: languageCode,
        amount: displayAmount,
        formattedPrice: formattedPrice(displayAmount, languageCode),
        fixedPrice: fixedPrice(displayAmount, languageCode),
        currencyUnit: currencyUnit(languageCode),
      },
    } as ExchangePrice;
  };

  const rate = useMemo(
    () =>
      (1 / (data?.exchangeRate || 1)) * // 엔화/위안화일때는 usd->${curr} 환율을 받게됨
      (languageCode === LANGUAGE_CODE.KO ? 1 : krwToUsdExchangeRate),
    [data?.exchangeRate, languageCode],
  ); // 원화일때는 그대로 받고 아닌경우 usd->krw 환율 곱함

  return {
    ex,
    rate,
    isReady: isReadyExchange,
    isNotSupportedCurrencyForPayment,
  };
}
