import React from 'react';
import { CategorySkeletonBox, CategorySkeletonWrapper } from '@components/_new_components/Common/layout/Category/Category.styles';

export const CategorySkeleton = () => {
  return (
    <CategorySkeletonWrapper>
      <CategorySkeletonBox width={'100%'} height={'37px'} />
      <CategorySkeletonBox width={'70%'} height={'35px'} />
      <CategorySkeletonBox width={'50%'} height={'35px'} />
      <CategorySkeletonBox width={'60%'} height={'35px'} />

      <CategorySkeletonBox width={'100%'} height={'37px'} marginTop={16} />
      <CategorySkeletonBox width={'70%'} height={'35px'} />
      <CategorySkeletonBox width={'50%'} height={'35px'} />
      <CategorySkeletonBox width={'60%'} height={'35px'} />

      <CategorySkeletonBox width={'100%'} height={'37px'} marginTop={16} />
      <CategorySkeletonBox width={'70%'} height={'35px'} />
      <CategorySkeletonBox width={'50%'} height={'35px'} />
      <CategorySkeletonBox width={'60%'} height={'35px'} />
    </CategorySkeletonWrapper>
  );
};
