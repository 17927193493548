import React from 'react';

export default function KakaoGrayIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1834_35217)">
        <path
          d="M9.99806 0C4.77839 0 0.541992 3.91213 0.541992 8.74477C0.541992 12.3117 2.03781 14.0167 4.07756 15.5649H4.08802V19.749C4.08802 19.9477 4.31814 20.0628 4.47505 19.9372L8.06291 17.2699L8.13613 17.3013C8.73237 17.4163 9.35998 17.4686 9.99806 17.4686C15.2177 17.4686 19.4541 13.5565 19.4541 8.72385C19.4541 3.89121 15.2177 0 9.99806 0ZM6.8286 10.9623C7.75956 10.9623 8.54408 10.4079 8.78467 9.55021H10.155C9.82024 11.2029 8.53362 12.2803 6.8286 12.2803C4.84116 12.2803 3.26166 10.795 3.26166 8.73431C3.26166 6.67364 4.84116 5.18828 6.8286 5.18828C8.55454 5.18828 9.85161 6.29707 10.1654 7.97071H8.79513C8.58593 7.06067 7.77003 6.48535 6.81814 6.48535C5.47923 6.48535 4.59011 7.43724 4.59011 8.73431C4.59011 10.0314 5.61521 10.9623 6.8286 10.9623ZM15.8558 12.1444H14.6006V9.30962C14.6006 8.66109 14.224 8.30544 13.6382 8.30544C12.9897 8.30544 12.5713 8.70293 12.5713 9.51883V12.1444H11.3161V5.2092H12.5713V7.81381C12.8746 7.35356 13.3558 7.13389 14.0253 7.13389C14.5587 7.13389 14.9981 7.31172 15.3432 7.66736C15.6989 8.02301 15.8663 8.51464 15.8663 9.15272V12.1444H15.8558Z"
          fill="#6D6D70"
        />
      </g>
      <defs>
        <clipPath id="clip0_1834_35217">
          <rect width="18.9121" height="20" fill="white" transform="translate(0.541992)" />
        </clipPath>
      </defs>
    </svg>
  );
}
