import React from 'react';

export default function HeaderSearchIconTablet() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.9333" cy="10.9333" r="6.93333" stroke="#313135" strokeWidth="2.13333" strokeLinecap="round" />
      <path d="M16.2676 16.2676L20.0009 20.0009" stroke="#313135" strokeWidth="2.13333" strokeLinecap="round" />
    </svg>
  );
}
