import parse, { DOMNode, Element } from 'html-react-parser';

export const stripParagraphTag = (text: string) => text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');

export const parseReplacePtag = (domNode: DOMNode): DOMNode => {
  if (domNode instanceof Element && domNode.name === 'p') {
    domNode.tagName = 'DIV';
    domNode.name = 'div';
    domNode.attribs.style = 'display: block;' + (domNode.attribs.style || '');
  }
  return domNode;
};

export const stripParagraphTagParser = (html) =>
  parse(html, {
    replace: parseReplacePtag,
  });
