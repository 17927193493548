import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ContentsContainer = styled.div<{
  contentsMaxWidth: number;
  isResponsiveContents?: boolean;
  viewportWidth?: number;
  isRemoveContentsPadding?: boolean;
  isOverflow?: boolean;
}>`
  width: calc(100% - 280px);
  display: flex;
  flex-direction: column;

  ${(props) => props.isOverflow && 'overflow: hidden;'}

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: 100%;
  }

  ${(props) =>
    !props?.isRemoveContentsPadding &&
    `
      --content-side-padding: 40;
      padding: 40px 40px 64px 40px;
      
      ${props.theme.breakpoints.down('dt_1720')} {
        padding: 40px 40px 54px 40px;
      }
      
      ${props.theme.breakpoints.down('dt_1440')} {
        padding: 40px 40px 56px 40px;
      }
    
      ${props.theme.breakpoints.down('lt_1024')} {
        --content-side-padding: 24;
        padding: 24px 24px 48px 24px;
      }
    
      ${props.theme.breakpoints.down('st_600')} {
        --content-side-padding: 16;
        padding: 16px 16px 32px 16px;
      }
  `}

  ${(props) => {
    if (props.isResponsiveContents || props.viewportWidth === 0) return '';
    const interpolation = props.viewportWidth / props.contentsMaxWidth;
    const zoom = interpolation > 1 ? 1 : interpolation;

    return css`
      --zoom: ${zoom};
      --zoomRevert: ${1 / zoom}; // hotfix
      zoom: var(--zoom);
      -webkit-text-size-adjust: calc(100% * var(--zoom));
      -moz-text-size-adjust: calc(100% * var(--zoom));
      -ms-text-size-adjust: calc(100% * var(--zoom));
      text-size-adjust: calc(100% * var(--zoom));
    `;
  }}
`;
