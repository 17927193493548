import styled from '@emotion/styled';
import Link from 'next/link';
import { css } from '@emotion/react';

export const CategoryContainer = styled.div`
  min-width: 280px;
  min-height: 960px;
  padding: 24px 40px 48px 40px;
  position: sticky;
  top: 0;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 40px;
    right: 0;
    width: 1px;
    height: calc(100% - 40px);
    background-color: rgba(0, 0, 0, 0.2);
    transform: scaleX(0.35);
  }
`;

export const FirstLevelTitleIconBoxOverflow = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
`;

export const FirstLevelTitleIconBox = styled.div<{ categoryCode: string }>`
  display: flex;
  flex-direction: column;

  & > svg:first-of-type {
    order: 1;
  }

  & > svg:last-of-type {
    order: 2;
  }
`;

export const FirstLevelCategory = styled.div`
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  display: flex;
  flex-direction: column;
`;

export const FirstLevelTitleAndDropdown = styled.div<{ isExpanded: boolean }>`
  padding: 24px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover ${FirstLevelTitleIconBox} {
    & > svg:first-of-type {
      order: 2;
    }

    & > svg:last-of-type {
      order: 1;
    }
  }

  ${(props) =>
    props.isExpanded &&
    css`
      & ${FirstLevelTitleIconBox} {
        & > svg:first-of-type {
          order: 2;
        }

        & > svg:last-of-type {
          order: 1;
        }
      }
    `}
`;

export const FirstLevelTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SecondLevelCategoryLink = styled(Link)<{ isActive: boolean }>`
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: #18181b;
  border-radius: 4px;

  &:hover {
    @media (min-width: 1440px) {
      background-color: rgba(245, 245, 246, 0.5);
    }

    ${(props) => props.theme.breakpoints.down('dt_1440')} {
      background: #f5f5f6;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      background: #f5f5f6;
    `}
`;

export const SecondLevelCategoryLinkText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: #18181b;
  width: fit-content;
`;

export const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThirdLevelCategories = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ThirdLevelCategoryLink = styled(Link)`
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: 25%;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    width: 33.3333%;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 50%;
  }
`;

export const ThirdLevelCategoryText = styled.div<{ isActive: boolean }>`
  padding: 7px 10px;
  color: #313135;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  white-space: pre;
  border-radius: 4px;

  &:hover {
    @media (min-width: 1440px) {
      background-color: rgba(245, 245, 246, 0.5);
    }

    ${(props) => props.theme.breakpoints.down('dt_1440')} {
      font-weight: 600;
      background: #f5f5f6;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 600;
      background: #f5f5f6;
    `}
`;

export const SecondAndThirdLevelWrapper = styled.div<{ height: number; isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  transition: 0.2s;
  max-height: 0;
  overflow: hidden;

  ${(props) => props.isExpanded && `max-height: ${props.height}px;`};
`;

export const SecondAndThirdLevelPadding = styled.div`
  padding-bottom: 22px;
`;

export const CategorySkeletonBox = styled.div<{ width: string; height: string; marginTop?: number }>`
  background-color: #f5f5f5;

  ${(props) => css`
    width: ${props.width};
    height: ${props.height};
  `};

  ${(props) =>
    props?.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}
`;

export const CategorySkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
`;
