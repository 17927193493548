import { useLocalStorage } from 'react-use';
import { useSearchKeywordStore } from '../../stores/searchKeyword/useSearchKeyword.store';

export default function useRecentSearches() {
  const [setKeyword] = useSearchKeywordStore((state) => [state.setSearchKeyword]);
  const [recentItems, setRecentItems] = useLocalStorage<string[]>('acon-search-keyword', []);

  const handleRemoveAllRecentItemsClick = () => {
    setRecentItems([]);
  };

  const handleRemoveRecentItemClick = (keyword: string) => {
    setRecentItems(recentItems.filter((item) => item !== keyword));
  };

  const handleRecentItemClick = (keyword: string) => setKeyword(keyword);

  const pushRecentItem = (keyword: string) => {
    const deepCopyRecentItems = [...recentItems];

    if (deepCopyRecentItems.includes(keyword)) {
      deepCopyRecentItems.splice(deepCopyRecentItems.indexOf(keyword), 1);
    } else {
      // 최근 검색 항목이 4이상이면 오래된 항목 삭제
      if (deepCopyRecentItems.length >= 10) {
        deepCopyRecentItems.pop();
      }
    }
    // 최근 검색어 추가
    deepCopyRecentItems.unshift(keyword);
    setRecentItems(deepCopyRecentItems);
  };

  return {
    recentItems,
    handleRemoveAllRecentItemsClick,
    handleRemoveRecentItemClick,
    handleRecentItemClick,
    pushRecentItem,
  };
}
