import React, { useEffect, useState } from 'react';
import { AblurIcon, Button, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Box, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import BottomNavigations from '@components/_new_components/Common/layout/BottomController/BottomNavigations/BottomNavigations';
import RecommendLanguage from '@components/_new_components/Common/layout/Footer/RecommendLanguage/RecommendLanguage';
import BandBanner from '@components/_new_components/Common/layout/BottomController/BandBanner/BandBanner';
import SnackbarList from '@components/_new_components/Common/layout/BottomController/Snackbar/SnackbarList';
import ScrollTop from '@components/ScrollTop/ScrollTop';
import { CustomLink } from '@components/ui/Link';
import { ABLUR_FAB_VISIBLE_PAGES } from '@components/layout/Layout/Layout.constants';
import { LANGUAGE_CODE } from 'src/constants';
import MessageIcon from 'src/assets/icons/MessageIcon';
import { useLayoutStore } from '../../../../../src/stores/layout/useLayout.store';
import { AbsoluteContents, BottomControllerContainer, BottomControllerWhiteSpace, BottomPortalContents, KeyboardSpacer, SidePadding } from './BottomController.styles';

export default function BottomController({ hideMobileBottomTab }: { hideMobileBottomTab: boolean }) {
  const [scrollTop, fixedButtonsBottom] = useLayoutStore((state) => [state.scrollTop, state.fixedButtonsBottom]);

  const [isShowRecommendLanguage, setIsShowRecommendLanguage] = useState<boolean>(false);
  const [documentMounted, setDocumentMounted] = useState(false);
  const [viewportHeightDiff, setViewportHeightDiff] = useState<number>(0);
  const theme = useDesignSystemTheme();
  const router = useRouter();
  const { i18n } = useTranslation();

  useEffect(() => {
    setDocumentMounted(true);
    if (typeof window === 'undefined') {
      return;
    }

    const getViewportHeightDiff = () => {
      return window.innerHeight - window.visualViewport.height;
    };

    const resizeHandler = () => {
      const visualOffset = window.visualViewport.offsetTop;
      const heightDiff = getViewportHeightDiff();

      if (Math.abs(heightDiff) < 100) {
        setViewportHeightDiff(0);
        return;
      }

      setViewportHeightDiff(heightDiff + 16 - visualOffset);
    };
    resizeHandler();

    const scrollHandler = () => {
      if (getViewportHeightDiff() > 0) {
        resizeHandler();
      }
    };

    window.visualViewport.addEventListener('resize', resizeHandler);
    window.visualViewport.addEventListener('scroll', scrollHandler);
    return () => {
      window.visualViewport.removeEventListener('resize', resizeHandler);
      window.visualViewport.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  if (!documentMounted) {
    return <div></div>;
  }

  return (
    <>
      {!hideMobileBottomTab && <BottomControllerWhiteSpace />}

      <BottomControllerContainer>
        <AbsoluteContents bottom={fixedButtonsBottom} hideMobileBottomTab={hideMobileBottomTab}>
          <SidePadding>
            {/** ios, android input 키패드 노출 시 가려지는 문제 수정을 위해 키보드 높이만큼 띄워줌 */}
            <KeyboardSpacer bottom={viewportHeightDiff}>
              {/** 모바일에서만 표시됩니다. */}
              <ScrollTop
                showButton={scrollTop.showButton}
                showDeviceSizes={scrollTop.showDeviceSizes}
                showButtonThreshold={scrollTop.showButtonThreshold}
                offsetTop={scrollTop.offsetTop}
                target={scrollTop.target}
              />
              {/** 스낵 바 */}
              <SnackbarList />
            </KeyboardSpacer>

            {/** 에이블러 2.0 출시 버튼*/}
            {ABLUR_FAB_VISIBLE_PAGES.includes(router.pathname) && i18n.language === LANGUAGE_CODE.KO && (
              <Box
                display="flex"
                flexDirection="column"
                gap="8px"
                justifyContent="flex-end"
                sx={{
                  [theme.breakpoints.up('largeTablet')]: {
                    padding: '0 24px 0 0',
                  },
                }}
              >
                <Box display="flex" justifyContent="flex-end">
                  <CustomLink href="/order/ablur" newWindow>
                    <Stack direction="row" gap="4px" p="12px 16px" sx={{ background: theme.palette['color/gray/900'], borderRadius: '24px' }}>
                      <AblurIcon color="color/white" />
                      <Typography
                        variant="typography/body/medium/bold"
                        color="color/white"
                        sx={{
                          [theme.breakpoints.down('smallTablet')]: {
                            fontSize: '14px',
                          },
                        }}
                      >
                        에이블러 2.0 출시!
                      </Typography>
                    </Stack>
                  </CustomLink>
                </Box>
              </Box>
            )}
            {/** 모바일 하단 띠 배너 */}
            <BandBanner />
          </SidePadding>

          {/** 몰 추천 배너 */}
          <RecommendLanguage isShow={isShowRecommendLanguage} setIsShow={setIsShowRecommendLanguage} />
        </AbsoluteContents>
        {/** 하단 고정을 위한 포탈 */}
        <BottomPortalContents id={'bottomPortal'} />
        {/** 모바일 하단 네비게이션 */}
        {!hideMobileBottomTab && <BottomNavigations />}
      </BottomControllerContainer>
    </>
  );
}
