import notifly from 'notifly-js-sdk';
import { DynamicEventCode, INotiflyProperties, NotiflyEventCode, NotiflyEventHandlers } from 'src/types/notifly.types';

export enum NotiflyCouponEventCode {
  WELCOME_TO_ACON = 2414,
  WELCOME_AGAIN = 3210,
}

const getSegmentationEventParamKeys = (eventCode: NotiflyEventCode | DynamicEventCode) => {
  const keys = [];
  if (eventCode === NotiflyEventCode.CashCharge) keys.push('chargePrice');
  if (eventCode === NotiflyEventCode.Checkout) keys.push('realPrice');
  return keys.length ? keys : null;
};

export const notiflyController = async (handller: NotiflyEventHandlers, properties?: INotiflyProperties) => {
  if (typeof window === 'undefined') return;
  notifly.requestPermission();

  try {
    const { userId, userProperties, eventCode, eventProperties } = properties || {};
    // console.log('notifly', handller, properties, getSegmentationEventParamKeys(eventCode));

    switch (handller) {
      case NotiflyEventHandlers.getUserId: {
        return await notifly.getUserId();
      }
      case NotiflyEventHandlers.userSessionStart: {
        return await notifly.setUserId(userId);
      }
      case NotiflyEventHandlers.userSessionEnd: {
        return await notifly.setUserId(null);
      }
      case NotiflyEventHandlers.SetUserProperties: {
        return await notifly.setUserProperties(userProperties);
      }
      case NotiflyEventHandlers.TrackEvent: {
        return await notifly.trackEvent(eventCode, eventProperties, getSegmentationEventParamKeys(eventCode));
      }
    }
  } catch (error) {
    console.error('notifly error', error);
  }
};
