import React from 'react';
import { useQuery as useReactQuery } from 'react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import { searchPopularKeyword } from '@api/artemis/artemis';
import { usePopularSearchesReturnType } from '@hooks/search/usePopularSearches.types';
import { getBaseDateTime, getPrevBaseDateTime } from '@hooks/search/useSearches.utils';
import { popularItemsType } from '../../types/search/Search.types';
import { useSearchKeywordStore } from '../../stores/searchKeyword/useSearchKeyword.store';
import { LanguageCodeEnum } from '../../generated/graphql';

export default function usePopularSearches(): usePopularSearchesReturnType {
  const { i18n } = useTranslation();
  const [setKeyword] = useSearchKeywordStore((state) => [state.setSearchKeyword]);
  const [baseDateTime, setBaseDateTime] = React.useState<Date>(getBaseDateTime());
  const [prevBaseDateTime, setPrevBaseDateTime] = React.useState<Date>(getPrevBaseDateTime());

  const staleAndCacheTime = 1000 * 60 * 10;
  const popularKeywordSize = 10;

  const { data: currentPopularKeywordsData, isFetching: currentPopularKeywordsFetching } = useReactQuery(
    ['search', 'popularKeyword', dayjs(baseDateTime).format('DDHHmm')],
    () =>
      searchPopularKeyword({
        language: i18n.language as LanguageCodeEnum,
        since: dayjs(baseDateTime).subtract(3, 'day').toISOString(),
        until: baseDateTime.toISOString(),
        size: popularKeywordSize,
      }),
    {
      retry: false,
      staleTime: staleAndCacheTime,
      cacheTime: staleAndCacheTime,
      keepPreviousData: true,
    },
  );
  const currentPopularItems = currentPopularKeywordsData || [];

  const { data: prevPopularKeywordsData, isFetching: prevPopularKeywordsFetching } = useReactQuery(
    ['search', 'popularKeyword', dayjs(prevBaseDateTime).format('DDHHmm')],
    () =>
      searchPopularKeyword({
        language: i18n.language as LanguageCodeEnum,
        since: dayjs(prevBaseDateTime).subtract(3, 'day').toISOString(),
        until: prevBaseDateTime.toISOString(),
        size: popularKeywordSize,
      }),
    {
      retry: false,
      staleTime: staleAndCacheTime,
      cacheTime: staleAndCacheTime,
      keepPreviousData: true,
    },
  );
  const prevPopularItems = prevPopularKeywordsData || [];

  // 이전 인기 검색어와 현재 인기 검색어를 비교하여 순위 변동 여부를 판단
  const popularItems = React.useMemo(() => {
    if (currentPopularKeywordsFetching || prevPopularKeywordsFetching) return [];
    return currentPopularItems.map((keyword, idx): popularItemsType => {
      const findRankIdx = prevPopularItems.findIndex((item) => item === keyword);
      return {
        rank: idx === findRankIdx ? 'unchanged' : idx < findRankIdx || findRankIdx === -1 ? 'increased' : 'decreased',
        keyword,
      };
    });
  }, [currentPopularKeywordsFetching, prevPopularKeywordsFetching]);

  const handlePopularItemClick = (keyword: string) => setKeyword(keyword);

  React.useEffect(() => {
    if (!baseDateTime) return;

    const expireBaseDateTime = () => {
      const newBaseDateTime = getBaseDateTime();
      if (baseDateTime.getTime() === newBaseDateTime.getTime()) return;

      setBaseDateTime(newBaseDateTime);
      setPrevBaseDateTime(getPrevBaseDateTime());
    };

    let timer = setInterval(expireBaseDateTime, 1000 * 60);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [baseDateTime]);

  return {
    popularItems,
    baseDateTime,
    handlePopularItemClick,
  };
}
