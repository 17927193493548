import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

// ----------------------------------------------------------------------

const PulseKeyFrame = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .25;
        transform: scale(.75);
    }
`;
const RootStyle = styled.div`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &.isDisplay {
    opacity: 1;
    z-index: 99999;
  }

  &:not(.isDisplay) {
    display: none;
  }
`;
const Container = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpinnerBox = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const Item = styled.div<{ second: number }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #7c17f8;

  animation: ${PulseKeyFrame} 0.4s ease ${(props) => props.second}s infinite alternate;
`;

// ----------------------------------------------------------------------

interface ILoadingScreen {
  isDisplay: boolean;
  style?: React.CSSProperties;
}

export function LoadingScreen({ isDisplay, style }: ILoadingScreen): React.ReactElement {
  return (
    <RootStyle className={isDisplay ? 'isDisplay' : null} style={{ ...style }}>
      <SpinnerBox>
        <Container>
          <Item second={0} />
          <Item second={0.2} />
          <Item second={0.4} />
        </Container>
      </SpinnerBox>
    </RootStyle>
  );
}
