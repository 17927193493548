import React from 'react';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import { ContentsContainer } from '@components/_new_components/Common/layout/Contents/Contents.styles';
import { EXEPTION_HEADERS, EXEPTION_HEADERS_STARTHWITH } from '@components/layout/Layout/Layout.constants';
import { checkPrevCategory } from '@util/Category';

interface Props {
  isRemoveContentsPadding?: boolean;
  contentsMaxWidth?: number;
  children: React.ReactNode;
}

export default function Contents({
  isRemoveContentsPadding,
  contentsMaxWidth = 1024,
  children,
}: Props) {
  const [viewportWidth, setViewportWidth] = React.useState(0);
  const [triggerViewportRecalculation, setTriggerViewportRecalculation] = React.useState<'start' | 'stop'>('stop');
  const router = useRouter();
  const isResponsiveContents = EXEPTION_HEADERS.includes(router.pathname) || EXEPTION_HEADERS_STARTHWITH.some((path) => router.pathname.startsWith(path));

  React.useEffect(() => {
    if (typeof window === 'undefined' || isResponsiveContents) return;

    setTriggerViewportRecalculation('start');
    const resizeDebounced = debounce(() => {
      setTriggerViewportRecalculation('start');
    }, 100);

    window.addEventListener('resize', resizeDebounced);
    return () => {
      window.removeEventListener('resize', resizeDebounced);
    };
  }, [isResponsiveContents]);

  /**
   * 모바일 디바이스에서 zoom을 이용하여 모바일 대응을 한 페이지로 접근할 경우 컨텐츠가 깨지는 현상이 있었습니다.
   * 깨지는 원인으로는 window.innerWidth를 체크할 때, 컨텐츠의 너비가 초과한 시점에서 innerWidth를 체크하여 zoom을 정상적으로 계산하지 못하는 문제였습니다.
   * 이러한 문제를 해결하고자, innerWidth 체크를 하기 전 contents를 overflow: hidden으로 최대 뷰포트 너비로 막아두고,
   * innerWidth 체크 후 overflow: hidden을 해제하여 정상적인 viewportWidth를 구하도록 변경하였습니다.
   *
   * 다음과 같이 동작하게 됩니다.
   * 1. triggerViewportRecalculation start로 변경 시 contents의 overflow: hidden을 적용합니다.
   * 2. innerWidth를 구하고 상태에 업데이트 합니다.
   * 3. triggerViewportRecalculation stop으로 변경하여 overflow: hidden을 해제합니다.
   */
  React.useEffect(() => {
    if (triggerViewportRecalculation === 'stop') return;
    setViewportWidth(window.innerWidth);
    setTriggerViewportRecalculation('stop');
  }, [triggerViewportRecalculation]);

  // 메인개편과 더불어 카테고리도 개편을 하였지만 기존 카테고리를 사용하는 경우가 있는데, 이미지 맵의 zoom으로 인해 페이지 세로 길이가 너무 길게 잡히는 경우가 있음.
  // 이를 해결하기 위해 우선 overflow 처리함.
  const isOverflow = router?.pathname?.includes('category/') && checkPrevCategory(String(router?.query?.id));

  return (
    <ContentsContainer
      isResponsiveContents={isResponsiveContents}
      viewportWidth={viewportWidth}
      isRemoveContentsPadding={isRemoveContentsPadding}
      isOverflow={isOverflow || triggerViewportRecalculation === 'start'}
      contentsMaxWidth={contentsMaxWidth}
    >
      {children}
    </ContentsContainer>
  );
}
