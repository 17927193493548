import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { useGetPopupsHereQuery } from 'src/generated/graphql';
import { preventDefault, preventDefaultForScrollKeys } from '@hooks/useDisableScroll';
import { Background } from './styled';
import { ModalType } from './type';
import Modal from './Modal';

export function ModalGroup() {
  const router = useRouter();
  const { i18n } = useTranslation();
  const [cookie, setCookie] = useCookies();

  const url = (() => {
    let path = router.asPath.split('?')[0].split('#')[0];

    if (path.substring(path.length, path.length - 1) === '/') {
      path = path.substring(0, path.length - 1);
    }

    // [todo]: toon, game을 url에서 제거하여 임시로 toon을 붙이도록 변경, 추후 모달 어드민 개선하면 제거해야 함.
    return `/${i18n.language}/toon${path}`;
  })();
  // Modal 데이터 호출
  const { data } = useGetPopupsHereQuery({
    variables: {
      url: url,
      origin: 'https://www.acon3d.com',
    },
  });
  const [modals, setModals] = useState<ModalType[]>();

  const getCookieIds = () => {
    const idStr: string = cookie.ALLOW_HIDE;
    const cookieIds: number[] = idStr ? idStr.split('_').map((x) => Number(x)) : [];
    return cookieIds;
  };

  const setCookieIds = (id: number) => {
    const cookieIds = getCookieIds();
    cookieIds.push(id);

    const todayDate = new Date();
    todayDate.setFullYear(2099);

    const cookieStr = cookieIds.join('_');
    setCookie('ALLOW_HIDE', cookieStr, { path: '/', expires: todayDate });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const idStr: string = cookie.ALLOW_HIDE;
    const cookieIds: number[] = idStr ? idStr.split('_').map((x) => Number(x)) : [];

    const displayPopups = data?.getPopupsHere?.filter((x) => !cookieIds.includes(x.id)) || [];
    // 팝업이 존재하지 않을경우, 종료
    if (displayPopups.length === 0) {
      return;
    }
    setModals(displayPopups);
  }, [data]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!modals) {
      return;
    }

    let supportsPassive = false;
    try {
      window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
          get() {
            supportsPassive = true;
            return true;
          },
        }),
      );
    } catch (e) {
      /**/
    }

    let wheelOpt = supportsPassive ? { passive: false } : false;
    let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    if (modals.length === 0) {
      // 모달창이 표시되지 않은 상태일 경우, body 태그에 적용된 스타일 제거
      // document.body.removeAttribute('style');
      window.removeEventListener(wheelEvent, preventDefault, false); // modern desktop
      window.removeEventListener('touchmove', preventDefault, false); // tabs
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    } else {
      // body 태그에 hidden을 줌으로 써 모달에 대한 스크롤만 적용되도록 함
      // document.body.style.overflowY = 'hidden';
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // tabs
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }
  }, [modals]);

  if (!modals || (modals && modals.length === 0)) {
    return <></>;
  }

  const getAllowHideAction = (modalTypeId: number) => {
    return () => {
      getHideAction(modalTypeId)();
      setCookieIds(modalTypeId);
    };
  };

  const getHideAction = (modalTypeId: number) => {
    return () => {
      setModals((prev) => prev.filter((x) => x.id !== modalTypeId));
    };
  };

  return (
    <Background>
      {modals[0] && (
        <>
          <Modal onClickAllowHide={getAllowHideAction(modals[0].id)} onClickHide={getHideAction(modals[0].id)} modalType={modals[0]} />

          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            onClick={getHideAction(modals[0].id)}
            data-mixpanel-action="click"
            data-mixpanel-evt="Button Click"
            data-mixpanel-action_name="close popup"
            data-mixpanel-modal-id={modals[0].id}
            style={{ zIndex: 1 }}
          />
        </>
      )}
    </Background>
  );
}
