import axios, { AxiosResponse } from 'axios';
import jscookie from 'js-cookie';
import { captureException } from '@sentry/nextjs';
import { requestRefreshOptions } from '@lib/auth.type';

export class EnhancedError extends Error {
  constructor(message: string, code?: any, extra?: any) {
    super(message);
    this.extra = extra;
    if (code) {
      this.code = code;
      this.extra.code = code;
    }
  }
  code: string | number;
  extra: any;
}

export function serializeAuthToken(accessToken: string, refreshToken: string): string {
  return `${accessToken}//${refreshToken}`;
}

export function deserializeAuthToken(authToken: string): { accessToken: string; refreshToken: string } {
  const [accessToken, refreshToken] = (authToken || '//').split('//');
  return {
    accessToken: accessToken || '',
    refreshToken: refreshToken || '',
  };
}


let refreshTokenRequesting = false;
let lastAccessToken = '';
let lastRefreshToken = '';

export function requestRefreshToken(tokenStorageHook, languageCode: string, options?: requestRefreshOptions): Promise<AxiosResponse<any, any>> {
  const { authToken, removeAuthToken } = tokenStorageHook;
  const { accessToken, refreshToken } = deserializeAuthToken(authToken);
  if (typeof window !== 'undefined' && jscookie) {
    lastAccessToken = jscookie.get('lastAccessToken') || lastAccessToken;
    lastRefreshToken = jscookie.get('lastRefreshToken') || lastRefreshToken;
  }
  const refreshPayload = {
    accessToken,
    refreshToken,
    languageCode,
    url: process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/refresh',
  };

  if (!options?.forceRefresh) {
    if (refreshTokenRequesting) {
      return Promise.reject(new EnhancedError('Duplicated request refresh token.', 'duplicatedRequest', refreshPayload));
    }
    if (lastAccessToken === accessToken || lastRefreshToken === refreshToken) {
      return Promise.reject(new EnhancedError('Attempt to request refresh token with same token.', 'sameToken', refreshPayload));
    }
  }

  jscookie.set('lastAccessToken', accessToken);
  jscookie.set('lastRefreshToken', refreshToken);
  refreshTokenRequesting = true;

  const stacker = new Error('ErrorTrigger >> \n');

  return axios
    .post(process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/refresh', JSON.stringify({ access_token: accessToken, refresh_token: refreshToken, language: languageCode }), {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    }).then((res)=> {
      refreshTokenRequesting = false;
      return res;
    }).catch(error => {
      refreshTokenRequesting = false;
      error.stack += '\n' + stacker.stack;
      error.extra = {
        ...refreshPayload,
        stack: error.stack + '\n' + stacker.stack,
      };
      removeAuthToken();
      throw error;
    });
}


export function requestRefresh(tokenStorageHook, languageCode: string, options?: requestRefreshOptions, callback?: (accessToken: string) => void) {
  const { setAuthToken } = tokenStorageHook;

  return requestRefreshToken(tokenStorageHook, languageCode, options).then(function (response) {
      setAuthToken(serializeAuthToken(response.data.access_token, response.data.refresh_token));
      callback && callback(serializeAuthToken(response.data.access_token, response.data.refresh_token));
    })
    .catch(function (error: EnhancedError) {
      error.extra = Object.assign({}, error.extra, { source: 'auth.ts' });
      captureException(error, {
        extra: error.extra,
      });
      if (error.code !== 'sameToken' && error.code !== 'duplicatedRequest') {
        location.href = '/users/login'; // [TODO] : can use nextjs router outside component?
      }
      throw error; // error를 던져줘서, apollo onError 에서 같은 쿼리를 두번 보내지 않도록 함.
    });
}
