import styled from '@emotion/styled';

export const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubLayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px 72px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
