import React from 'react';
import dynamic from 'next/dynamic';
import UnsupportedBrowser from '@components/_new_components/Common/layout/UnsupportedBrowser';
// import BandBanner from '@components/_new_components/layout/TopController/BandBanner/BandBanner';
const BandBanner = dynamic(() => import('@components/_new_components/Common/layout/TopController/BandBanner/BandBanner'), { ssr: false });

export default function TopController() {
  return (
    <>
      <UnsupportedBrowser />
      <BandBanner />
    </>
  );
}
