import React, { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import Header from '@components/_new_components/Common/layout/Header/Header';
import Footer from '@components/_new_components/Common/layout/Footer/Footer';
import GlobalStyle from '@styles/GlobalStyle';
import { ContentsWrapper } from '@components/_new_components/Common/layout/Layout.styles';
import Category from '@components/_new_components/Common/layout/Category/Category';
import Contents from '@components/_new_components/Common/layout/Contents/Contents';
import BottomController from '@components/_new_components/Common/layout/BottomController/BottomController';
import { LayoutContainer } from '@components/layout/Layout/Layout.styles';
import TopController from '@components/_new_components/Common/layout/TopController/TopController';
import { Portal } from '@components/_new_components/Common/Portal/Portal';

type Props = {
  options?: {
    isHideFooter?: boolean;
    isHideHeader?: boolean;
    isHideSideCategory?: boolean;
    isRemoveContentsPadding?: boolean;
    isFullWidthContents?: boolean;
    contentsMaxWidth?: number;
    hideMobileBottomTab?: boolean;
  };
  children: ReactNode;
};

export default function Layout({ options, children }: Props) {
  const { i18n } = useTranslation();

  return (
    <LayoutContainer>
      <GlobalStyle lang={i18n.language} />
      <TopController />
      {options?.isHideHeader ? null : <Header />}
      <ContentsWrapper isHideSideCategory={options?.isHideSideCategory ?? true} isFullWidthContents={options?.isFullWidthContents ?? true}>
        {!(options?.isHideSideCategory ?? true) && <Category isSideCategory={true} />}
        <Contents isRemoveContentsPadding={options?.isRemoveContentsPadding} contentsMaxWidth={options?.contentsMaxWidth}>
          {children}
        </Contents>
      </ContentsWrapper>
      {!options?.isHideFooter && <Footer />}
      <Portal container={'#bottomController'}>
        <BottomController hideMobileBottomTab={options?.hideMobileBottomTab} />
      </Portal>
    </LayoutContainer>
  );
}
