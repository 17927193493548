import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { getImageSize } from '@lib';
import CustomScrollDiv from '@components/ScrollWrapper';
import useResponsiveDevice from '@hooks/useResponsiveDevice';

type Props = {
  src: string;
  borderRadius: 'all' | 'top';
};

const Styled = {
  ImageWrapper: styled.div<{ isMobile: boolean }>`
    margin-bottom: -1px;
    background-color: #fff;
    width: 100%;
  `,
};

export default function ModalImage({ src, borderRadius }: Props) {
  const [imageHeight, setImageHeight] = useState<number>(0);
  const { isMobile } = useResponsiveDevice();

  const setModalImageHeight = async () => {
    const { width, height } = await getImageSize(`https://storage.acon3d.com/${src}`).catch(
      // getImageSize 를 한번은 재시도합니다.
      () =>
        getImageSize(`https://storage.acon3d.com/${src}`).catch((e) => {
          console.error(e);
          return { width: 0, height: 0 };
        }),
    );

    setImageHeight(height / (width / 420));
  };

  useEffect(() => {
    setModalImageHeight();
  }, [src]);

  const borderRadiusStyle: React.CSSProperties = {
    borderTopLeftRadius: `${isMobile ? 12 : 8}px`,
    borderTopRightRadius: `${isMobile ? 12 : 8}px`,
    ...(borderRadius === 'all' && {
      borderBottomLeftRadius: `${isMobile ? 12 : 8}px`,
      borderBottomRightRadius: `${isMobile ? 12 : 8}px`,
    }),
  };

  return (
    <Styled.ImageWrapper isMobile={isMobile} style={borderRadiusStyle}>
      {imageHeight > 420 && (
        <CustomScrollDiv
          trigger={imageHeight}
          style={{
            ...borderRadiusStyle,
            height: imageHeight > 420 ? 420 : imageHeight,
          }}
        >
          <img className={'w-full'} src={`https://storage.acon3d.com/${src}`} alt="" />
        </CustomScrollDiv>
      )}
      {imageHeight <= 420 && <img style={borderRadiusStyle} className={'w-full'} src={`https://storage.acon3d.com/${src}`} alt="" />}
    </Styled.ImageWrapper>
  );
}
