import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import dayjs from 'dayjs';
import { convert } from '@components/_new_components/Pages/main/banner/utils';
import {
  IBannerDetail,
  IBannerUpdateInput,
  IBannerCreateInput,
  QueryParam,
  AnyObject,
  IBannerListQuery,
  TLanguageCode,
  TTarget,
  BannerType,
} from './banner.types';

const MARKETING_URL = process.env.MARKETING_URL || process.env.NEXT_PUBLIC_MARKETING_URL;

const baseURL = String(MARKETING_URL);
export const instance = axios.create({
  baseURL: baseURL.endsWith('/') ? baseURL.slice(0, -1) : baseURL,
});

export const list = (page: number, limit: number, filter: IBannerListQuery) => instance.get('/v1/admin/banners', { params: { page, limit, ...filter } }).then((res)=> res.data);
export const listSorted = (filter: IBannerListQuery) => instance.get('/v1/admin/banners', { params: filter }).then((res)=> res.data);

export const detail = <T=string>(id: number | QueryParam): Promise<IBannerDetail<T>> => instance.get<IBannerDetail<string>>(`/v1/admin/banners/${id}`).then((res)=> ({
  ...res.data,
  property: JSON.parse(res.data.property),
}));

export const create = <T=AnyObject>(data: IBannerCreateInput<T>) => instance.post('/v1/admin/banners', convert(data));

export const update = <T=AnyObject> (id: number | QueryParam, data: IBannerUpdateInput<T>) => instance.put(`/v1/admin/banners/${id}`, convert(data));

export const deactivate = (id: number | QueryParam) => instance.put(`/v1/admin/banners/${id}`, { until: dayjs().toISOString() });
// {{marketing_server}}/v1/admin/banners/reorder
export const reorder = (bannerList: Array<number>) => instance.patch('/v1/admin/banners/reorder', { bannerList });

export const getFileUploadUrl = () => instance.post<string>('/v1/admin/banners/presigned-image-url');


export const uploadFile = async (url: string, key: string, file: File) => {
  await axios.put(url, file, { headers: { 'Content-Type': file.type } });
  return [key, { url: `${url.split('?')[0]}`, name: file.name }];
};

export const bulkUploadFile = (files: Array<{ key: string; file: File }>) =>
  Promise.all(
    files.map((file) => getFileUploadUrl()
      .then(({ data: url }) => uploadFile(url, file.key, file.file))
    )
  ).then((entries) => ({
    // @ts-ignore
    ...Object.fromEntries(entries.map(([key, value]) => [key, value.url])),
    // @ts-ignore
    fileNames: Object.fromEntries(entries.map(([key, value]) => [key, value.name])),
  }));


// client
export const targetBanner = (target: TTarget, languageCode: TLanguageCode, asset_id?: number) => instance.get<Record<string, Array<IBannerDetail<string>>>>('/v1/acon3d/banners', {
  params: {
    target,
    languageCode,
    asset_id,
  },
}).then((res)=> res.data).then(originData => {
  const data = cloneDeep(originData);
  const keys = Object.keys(data);
  for (const key of keys) {
    data[key] = data[key].map(item => {
      item.property = JSON.parse(item.property);
      return item;
    });
  }
  return data as Record<string, Array<IBannerDetail>>;
});
export const typeBanner = <T=IBannerDetail>(type: BannerType, languageCode: TLanguageCode) => instance.get<Record<string, T[]>>('/v1/acon3d/banners', {
  params: {
    type,
    languageCode,
  },
}).then((res)=> res.data).then(originData => cloneDeep(originData[type]).map(item => {
  item.property = JSON.parse(item.property);
  return item;
}) as Array<T>);


