import React from 'react';

export default function HeaderSearchIconMobile() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60242 10.1714C4.60242 7.17772 7.02929 4.75086 10.023 4.75086C13.0167 4.75086 15.4435 7.17772 15.4435 10.1714C15.4435 13.1651 13.0167 15.592 10.023 15.592C7.02929 15.592 4.60242 13.1651 4.60242 10.1714ZM10.023 2.88086C5.99651 2.88086 2.73242 6.14495 2.73242 10.1714C2.73242 14.1979 5.99651 17.462 10.023 17.462C11.6998 17.462 13.2444 16.8959 14.4761 15.9444L17.6731 19.1414C18.0383 19.5066 18.6303 19.5066 18.9954 19.1414C19.3606 18.7763 19.3606 18.1843 18.9954 17.8191L15.7981 14.6218C16.7483 13.3905 17.3135 11.847 17.3135 10.1714C17.3135 6.14495 14.0494 2.88086 10.023 2.88086Z"
        fill="#313135"
      />
    </svg>
  );
}
