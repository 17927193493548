import React from 'react';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { cookiesExpires, cookiesKey } from 'src/config';
import { SettingsContextProps, ThemeMode } from '@components/settings/type';
import { getFirstPath } from '@lib';
import * as mixpanel from '@lib/mixpanel';
import { campaignParams } from '@lib/mixpanel';
import { theme } from '../routes/paths';

const initialState: SettingsContextProps = {
  themeMode: undefined,
  isReady: false,
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
  themeMode?: ThemeMode;
};

function SettingsProvider({ children, themeMode }: SettingsProviderProps) {
  const [settings] = useSettingCookies({ ...initialState, themeMode });


  return (
    <SettingsContext.Provider
      value={{
        ...settings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };

// ----------------------------------------------------------------------

function useSettingCookies(defaultSettings: SettingsContextProps): [SettingsContextProps, Dispatch<SetStateAction<SettingsContextProps>>] {
  const [settings, setSettings] = useState<SettingsContextProps>(defaultSettings);

  const handleSettingChange = () => {
    if (settings.themeMode === 'game' || settings.themeMode === 'toon') {
      theme.mode = settings.themeMode;
      Cookies.set(cookiesKey.themeMode, settings.themeMode, { expires: cookiesExpires });
    }

    if (settings.themeMode) {
      const utmLastTouchParams = campaignParams();
      mixpanel.setSuperProperty({
        'Mall (industry)': settings.themeMode,
        ...utmLastTouchParams,
      });
    }
  };

  useEffect(() => {
    handleSettingChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, settings.themeMode]);

  return [settings, setSettings];
}
