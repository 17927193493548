import React, { ChangeEvent, useState } from 'react';
import { useSearchKeywordStore } from '../../stores/searchKeyword/useSearchKeyword.store';

export default function useSearchInput() {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useSearchKeywordStore((state) => [state.searchKeyword, state.setSearchKeyword]);
  const [isFocus, setIsFocus] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleSearchInputClear = () => {
    setKeyword('');
  };

  const handleInputFocus = () => {
    setIsFocus(true);
  };

  const handleInputBlur = () => {
    setIsFocus(false);
    inputRef.current.blur();
  };

  return {
    inputRef,
    keyword,
    isFocus,
    handleSearchInputChange,
    handleSearchInputClear,
    handleInputFocus,
    handleInputBlur,
  };
}
