import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CommonContext, UserInfo, UserInfoStatus } from '../provider';

export interface Properties {
  [key: string]: string;
}

declare global {
  interface Window {
    gtag: any; //
  }
}

export const useGA4 = () => {
  const { userInfo, userInfoStatus } = useContext<{ userInfo: UserInfo; userInfoStatus: UserInfoStatus }>(CommonContext);

  const [userProperties, setUserProperties] = useState<{ [key: string]: string }>({});

  // config user_id
  const setUserId = (userId: string) => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!window.gtag) {
      return;
    }
    window.gtag('config', process.env.NEXT_PUBLIC_GA4_ID, {
      user_id: userId,
    });
  };

  function addUserProperties(properties: Properties) {
    setUserProperties((prev) => ({ ...prev, ...properties }));
  }

  // set user properties
  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo.userId);
      addUserProperties({
        mem_no: userInfo.memNo,
        user_info_status: userInfoStatus,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    // set user properties for GA4
    if (typeof window === 'undefined') {
      return;
    }
    if (!window.gtag) {
      return;
    }
    window.gtag('set', { user_properties: userProperties });
  }, [userProperties]);

  return { setUserId, addUserProperties };
};

export const useGA4PageView = () => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // if debug mode, set debug mode
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setDebugMode();
    }
  }, []);
};

export function logEvent(
  eventName: string,
  params: {
    [key: string]: any;
  },
  isConvertEvent: boolean = true,
) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!checkGA4()) {
    return;
  }

  const gaEventName: string = isConvertEvent ? convertEventName(eventName) : eventName;
  const gaParams: { [key: string]: any } = isConvertEvent ? convertEventParams(params) : params;

  window.gtag('event', gaEventName, gaParams);
}

export const pageview = (url) => {
  if (!checkGA4()) {
    return;
  }
  window.gtag('config', process.env.NEXT_PUBLIC_GA4_ID, {
    page_path: url,
  });
};

export const setDebugMode = () => {
  if (!checkGA4()) {
    return;
  }
  window.gtag('config', process.env.NEXT_PUBLIC_GA4_ID, {
    debug_mode: true,
  });
};

export const checkGA4 = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.gtag;
};

/** convert event name for ga4 as our data convention */
function convertEventName(eventName: string) {
  let gaEventName: string = eventName.replace(/\s/g, '_').toLowerCase();

  // if gaEventName is not start with e_, add e_ prefix
  if (!gaEventName.startsWith('e_')) {
    gaEventName = 'e_' + gaEventName;
  }

  return gaEventName;
}

/** convert event params for ga4 as our data convention */
function convertEventParams(params: { [key: string]: any }) {
  const gaParams: { [key: string]: any } = {};
  Object.keys(params).forEach((key) => {
    // change to snake case
    let gaKey = key.replace(/\s/g, '_').toLowerCase();
    // if value is boolean, add prefix is_
    if (typeof params[key] === 'boolean') {
      // only if value doesn't start with is_ or has_ prefix, add is_ prefix
      if (!gaKey.startsWith('is_') && !gaKey.startsWith('has_')) {
        gaKey = 'is_' + gaKey;
      }
    }
    // if value is number, add postfix _num
    if (typeof params[key] === 'number') {
      // only if value doesn't end with _num postfix, add _num postfix
      if (!gaKey.endsWith('_num')) {
        gaKey += '_num';
      }
    }

    gaParams[gaKey] = params[key];
  });
  return gaParams;
}
