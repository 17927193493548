import { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useCookies } from 'react-cookie';
import { useUserInfo } from '@hooks';
import { GA_ACON_USER_ID_COOKIE_KEY } from '@components/_new_components/Common/layout/Header/Header.constants';
import { useHeaderContainer, useRequestHideHeader } from 'src/stores/header/useHeaderStore';
import Gnb from './Gnb/Gnb';
import SubGnb from './SubGnb/SubGnb';
import { HeaderContainer } from './Header.styles';

const hashUserId = async (message: string): Promise<string> => {
  try {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
  } catch {
    return message;
  }
};

export default function Header() {
  const [_, setCookie, removeCookie] = useCookies();
  const { userInfo } = useUserInfo();
  const isKakaoScriptLoad = typeof window !== 'undefined' && Boolean(window.Kakao);

  useEffect(() => {
    const jsKey = '5b58fea3856a0c411926e0340a61626d';

    // SDK는 한 번만 초기화해야 한다.
    // 중복되는 초기화를 막기 위해 isInitialized()로 SDK 초기화 여부를 판단한다.
    if (isKakaoScriptLoad && !window.Kakao?.isInitialized()) {
      // JavaScript key를 인자로 주고 SDK 초기화
      window.Kakao.init(jsKey);
    }
  }, [isKakaoScriptLoad]);

  useEffect(() => {
    if (userInfo?.userId) {
      datadogRum.setUser({
        id: userInfo.userId,
      });
      // GTM 에서 사용하는 유저 식별 정보 쿠키 설정
      hashUserId(userInfo.userId).then((hash) => {
        setCookie(GA_ACON_USER_ID_COOKIE_KEY, hash, { path: '/' });
      });
    } else {
      // GTM 에서 사용하는 유저 식별 정보 쿠키 삭제 (유저 정보가 없으면 로그인 상태가 아닌 걸로 간주)
      removeCookie(GA_ACON_USER_ID_COOKIE_KEY, { path: '/' });
    }
  }, [userInfo?.userId]);

  // Header의 element를 다른 컴포넌트에서 사용할 수 있도록 등록합니다.
  const { setHeaderContainer } = useHeaderContainer();
  const headerContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (headerContainerRef.current) {
      setHeaderContainer(headerContainerRef.current);
      return () => {
        setHeaderContainer(null);
      };
    }
  }, [setHeaderContainer]);

  const { hideHeaderRequesterIds } = useRequestHideHeader();

  return (
    <HeaderContainer isHidden={hideHeaderRequesterIds.size > 0} ref={headerContainerRef}>
      <Gnb />
      <SubGnb />
    </HeaderContainer>
  );
}
