import styled from '@emotion/styled';
import { FlexRow, FlexRowAlignCenter } from '@components/wrapper';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';
import IndustryButtonIcon from '../../../../../../src/assets/icons/gnb/industryButton/IndustryButtonIcon';

export const GnbContainer = styled(FlexRow)`
  padding: 20px 48px 14px 40px;
  justify-content: space-between;
  min-height: 80px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding: 18px 40px 6px 40px;
    min-height: 52px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 16px 24px 4px 24px;
    min-height: 48px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 15px 16px 3px 16px;
    min-height: 44px;
  }
`;

export const MypageMenuWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const GnbIconWrapper = styled(FlexRowAlignCenter)`
  gap: 16px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 12px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 10px;
  }
`;

export const CashBonusLabel = styled.div`
  width: 80px;
  height: 32px;
  background-image: url('/icons/ic_blackfriday_tooltip_large.svg');

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    width: 70px;
    background-image: url('/icons/ic_blackfriday_tooltip_small.svg');
  }
`;

export const CashIndicatorWrapper = styled(FlexRowAlignCenter)``;

export const ScrapIconWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: none;
  }
`;

export const SearchIconWrapper = styled.div`
  display: none;
  & svg {
    display: none;
  }
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: block;
    & svg:first-of-type {
      display: block;
    }
    & svg:last-of-type {
      display: none;
    }
  }
  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: block;
    & svg:first-of-type {
      display: none;
    }
    & svg:last-of-type {
      display: block;
    }
  }
`;

export const UserHomeIconWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }
`;

export const AconLogoSkeleton = styled(Skeleton)`
  width: 102px;
  height: 32px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: 89px;
    height: 28px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    width: 83px;
    height: 26px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 70px;
    height: 22px;
  }
`;

export const IndustryToggleButton = styled(IndustryButtonIcon)`
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding-left: 2px;  
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding-left: 3px;
  }
`;
