import React from 'react';

export default function GlobalIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1834_35231)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 17.3C13.584 17.3 17.3 13.584 17.3 9C17.3 4.41604 13.584 0.7 9 0.7C4.41604 0.7 0.7 4.41604 0.7 9C0.7 13.584 4.41604 17.3 9 17.3ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7544 15.0362C12.5541 13.5272 13.07 11.3943 13.07 9C13.07 6.6057 12.5541 4.47279 11.7544 2.96384C10.9382 1.42377 9.93055 0.7 9 0.7C8.06945 0.7 7.06185 1.42377 6.24561 2.96384C5.44587 4.47279 4.93001 6.6057 4.93001 9C4.93001 11.3943 5.44587 13.5272 6.24561 15.0362C7.06185 16.5762 8.06945 17.3 9 17.3C9.93055 17.3 10.9382 16.5762 11.7544 15.0362ZM9 18C11.6344 18 13.77 13.9706 13.77 9C13.77 4.02944 11.6344 0 9 0C6.3656 0 4.23001 4.02944 4.23001 9C4.23001 13.9706 6.3656 18 9 18Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4.77561C11.4009 4.77561 13.5613 4.19863 15.1226 3.2803L15.4774 3.88367C13.792 4.87504 11.5049 5.47561 9 5.47561C6.49509 5.47561 4.20802 4.87504 2.52255 3.88366L2.87745 3.2803C4.43874 4.19863 6.5991 4.77561 9 4.77561Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1226 14.7154C13.5613 13.797 11.4009 13.22 9 13.22C6.5991 13.22 4.43874 13.797 2.87745 14.7154L2.52255 14.112C4.20802 13.1206 6.49509 12.52 9 12.52C11.5049 12.52 13.792 13.1206 15.4774 14.112L15.1226 14.7154Z"
          fill="black"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.55 9.35H0.45V8.65H17.55V9.35Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.65 17.55L8.65 0.45L9.35 0.45L9.35 17.55H8.65Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_1834_35231">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
