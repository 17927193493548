import React from 'react';
import { IconProps } from '../type';
import * as Styled from '../styled';

type Props = IconProps & {
  customStroke?: number;
};

export default function CloseIcon({ className, size, color = '#333' }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.1698 9.99769L14.7485 6.42538C15.0748 6.09886 15.0748 5.56946 14.7485 5.24294C14.4221 4.91642 13.893 4.91642 13.5667 5.24294L9.99634 8.82358L6.42601 5.24294C6.09967 4.91642 5.57057 4.91642 5.24422 5.24294C4.91788 5.56946 4.91788 6.09886 5.24422 6.42538L8.82288 9.99769L5.24422 13.57C5.08667 13.7264 4.99805 13.9392 4.99805 14.1612C4.99805 14.3833 5.08667 14.5961 5.24422 14.7524C5.40049 14.9101 5.61321 14.9988 5.83512 14.9988C6.05703 14.9988 6.26974 14.9101 6.42601 14.7524L9.99634 11.1718L13.5667 14.7524C13.7229 14.9101 13.9357 14.9988 14.1576 14.9988C14.3795 14.9988 14.5922 14.9101 14.7485 14.7524C14.906 14.5961 14.9946 14.3833 14.9946 14.1612C14.9946 13.9392 14.906 13.7264 14.7485 13.57L11.1698 9.99769Z"
          fill={color}
        />
      </svg>
    </Styled.IconWrapper>
  );
}
