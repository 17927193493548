/* eslint-disable eqeqeq */
import { i18n } from 'next-i18next';
import { godomallInstance } from '@api/godomall';
import { EXTENSION_CODE, LANGUAGE_CODE, LanguageCode } from '../constants';
import { Language_Code } from '../generated/graphql';

export { initializeApollo, useApollo } from './apolloClient';

/** TODO 임시 메소드입니다. 추후 db 및 고도몰 상에 언어코드를 일괄 변경이후에 제거할 수 있는 메소드입니다. */
export const convertLangCodeForDB = (lang: string): string => {
  if (lang === 'ja') {
    return 'jp';
  }
  if (lang === 'zh') {
    return 'cn';
  }

  return lang;
};

export const convertLanguage = (lang: string): Language_Code => lang.toUpperCase() as Language_Code;

export function numberWithCommas(num: number): string {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

interface downloadCouponMessages {
  loginRequired?: string;
  couponDuplicated?: string;
  success?: string;
}

interface downloadCouponOptions {
  messages?: downloadCouponMessages;
  onSuccess?: (...args: any[]) => any;
}

// 2022 블랙프라이데이에서는 alert 대신 popup modal을 띄워준다.
export const getMessageOfCouponDownload = async (couponNo) => {
  const params = new URLSearchParams();
  params.append('mode', 'couponDown');
  params.append('couponNo', couponNo);

  // 쿠폰 발급 체크 및 발급 API
  const { data } = await godomallInstance.post('mypage/coupon_ps.php', params);

  return data.message;
};

export const downloadCoupon = async (couponNo, options: downloadCouponOptions = {}) => {
  const { messages = {}, onSuccess } = options;
  let returnMessage = await getMessageOfCouponDownload(couponNo);

  // validation 메세지 및 발급 메시지 설정
  if (returnMessage === '로그인 하셔야 합니다.') {
    returnMessage = messages.loginRequired || i18n.t('event:cometDeal.coupon.validation.login');
    document.location.href = '/users/login?guestOrder=1&returnUrl=/' + encodeURIComponent(location.href.split('/').slice(3).join('/'));
  } else if (returnMessage === '이미 발급 받은 쿠폰 입니다.') {
    returnMessage = messages.couponDuplicated || i18n.t('event:cometDeal.coupon.validation.already');
  } else if (returnMessage === '쿠폰이 발급되었습니다.') {
    returnMessage = messages.success || i18n.t('event:cometDeal.coupon.issued');
  }

  alert(returnMessage);

  return onSuccess && onSuccess();
};

export * from './form';
export * from './twitter';

export const parseExtensionCode = (ext: string): EXTENSION_CODE => {
  return Object.values(EXTENSION_CODE).find((code) => code === ext) || EXTENSION_CODE.ETC;
};

/**
 * 페이지 url을 조립해주는 메소드입니다.
 * @param origin origin
 * @param lang 언어
 * @param path 경로
 * @param isEncode 인코딩 여부
 * @returns
 */
export const getPageUrl = (origin: string, lang: string, path: string, isEncode: boolean): string => {
  const pageUrl = `${origin}/${lang}/${path}`;
  return isEncode ? encodeURIComponent(pageUrl) : pageUrl;
};

export const getPopup = (options) => {
  if (!options.width) {
    options.width = 500;
  }
  if (!options.height) {
    options.height = 415;
  }
  const status = [];

  for (const key in options) {
    const item = options[key];

    if (!['url', 'target'].includes(key)) {
      status.push(key + '=' + item);
    }
  }
  const win = window.open(options.url, options.target, status.join(','));
  return win;
};

export const convertNumber = (str): string => {
  return str.replace(/[^0-9]/gi, '');
};

export const isBusinessNumber = (businessNumber: string) => {
  const numberMap = businessNumber
    .replace(/-/gi, '')
    .split('')
    .map(function (d) {
      return parseInt(d, 10);
    });

  if (numberMap.length === 10) {
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach(function (d, i) {
      chk += d * numberMap[i];
    });

    chk += parseInt(((keyArr[8] * numberMap[8]) / 10).toString(), 10);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }

  return false;
};

export const getFirstPath = (str: string) => {
  if (str === '/' || str === '') {
    return '';
  }

  //eslint-disable-next-line
  const firstPath = (str?.match(/^\/[^\/]+/) || [])[0]?.replace('/', '');
  return (firstPath?.split('?') || [])[0];
};

export const getEmails = (language: LanguageCode) => {
  switch (language) {
    case LANGUAGE_CODE.KO:
      return ['naver.com', 'gmail.com', 'kakao.com', 'nate.com', 'daum.net', 'icloud.com'];
    case LANGUAGE_CODE.EN:
      return ['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com'];
    case LANGUAGE_CODE.JP:
      return ['gmail.com', 'yahoo.com', 'yahoo.co.jp', 'hotmail.com', 'icloud.com'];
    case LANGUAGE_CODE.CN:
      return ['qq.com', 'gmail.com', '163.com', 'hotmail.com', 'icloud.com'];
  }
};

export function groupBy<T, K extends keyof T>(array: T[], key: K) {
  const map = new Map<T[K], T[]>();
  array.forEach((item) => {
    const itemKey = item[key];
    if (!map.has(itemKey)) {
      map.set(
        itemKey,
        array.filter((i) => i[key] === item[key]),
      );
    }
  });
  return map;
}

export function zeroPad(value: number | string, count: number) {
  const newValue = value.toString();

  if (newValue.length > count - 1) {
    return newValue;
  }

  return newValue.padStart(count, '0');
}

export function getStorageUrl(path: string) {
  return `https://storage.acon3d.com/${path}`;
}

export function domParser(str: string): HTMLElement {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body;
}

export function getImageSize(imageUrl: string): Promise<{ width: number; height: number }> {
  let getImageSizeError = new Error('getImageSizeError');
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      getImageSizeError = null;
      resolve({ width: img.width, height: img.height });
    };
    // eslint-disable-next-line prefer-promise-reject-errors
    img.onerror = (event, source, lineno, colno, error) => {
      getImageSizeError.cause = error;
      reject(getImageSizeError);
    };
    img.src = imageUrl;
  });
}

export function newLineStringToEmpty(str: string) {
  return str.replace(/(\\r\\n|\\n|\\r)/gm, ' ').replace(/\\/g, '');
}

export function getExtensionName(extension: string) {
  return extension.split('.').pop();
}
