import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ISnackBar } from 'src/stores/layout/useLayout.type';

export const SnackbarCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;
export const SnackbarMessageText = styled.div`
  padding: 8px 0;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const SnackbarContainer = styled.div<{ type?: ISnackBar['options']['type'] }>`
  background-color: #313135;
  width: auto;
  padding: 6px 16px;
  z-index: 103;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 100%;
  }
  ${(props) => {
    switch (props.type) {
      case 'success':
        return css``;
      case 'error':
        return css``;
      case 'warning':
        return css``;
      case 'info':
        return css`
          gap: 6px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.94);
          box-shadow:
            0px 10px 13px -6px rgba(0, 0, 0, 0.12),
            0px 20px 31px 3px rgba(0, 0, 0, 0.08),
            0px 8px 38px 7px rgba(0, 0, 0, 0.04);
          ${SnackbarMessageText} {
            color: #4e4eff;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          }
        `;
      default:
        return css``;
    }
  }}
`;
