import styled from '@emotion/styled';

export const BottomNavigationsContainer = styled.div`
  width: 100%;
  box-shadow: 0px -0.35px 0px 0px #ccc;
  display: none;
  background-color: white;
  min-height: 56px;

  @supports (content: env(safe-area-inset-bottom)) {
    min-height: calc(56px + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }

  @supports (content: constant(safe-area-inset-bottom)) {
    min-height: calc(56px + constant(safe-area-inset-bottom));
    padding-bottom: constant(safe-area-inset-bottom);
  }

  & > a {
    width: 25%;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: flex;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-height: 48px;
  }
`;

export const NavigationText = styled.div`
  color: #000000;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
`;

export const NavigationItem = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 9px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1px;

  ${(props) =>
    props.isActive &&
    `
    
    & > ${NavigationText} {
      color: #000000;
      font-weight: 700;
    }
  `}

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 5px 0;
  }
`;
