import React from 'react';
import { LanguageCodeEnum } from '../../../../generated/graphql';
import { Industry } from '../../../../constants/industry.constants';

export const IndustryIcons = {
  [LanguageCodeEnum.Ko]: {
    [Industry.GAME]: (
      <>
        <svg width="60" height="24" viewBox="0 0 60 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={'first-industry-icon'}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55 2.5H5C3.89543 2.5 3 3.39543 3 4.5V19.5C3 20.6046 3.89543 21.5 5 21.5H55C56.1046 21.5 57 20.6046 57 19.5V4.5C57 3.39543 56.1046 2.5 55 2.5ZM5 0.5C2.79086 0.5 1 2.29086 1 4.5V19.5C1 21.7091 2.79086 23.5 5 23.5H55C57.2091 23.5 59 21.7091 59 19.5V4.5C59 2.29086 57.2091 0.5 55 0.5H5Z"
            fill="#313135"
          />
          <path d="M29.4203 6.03906H27.6437V17.7434H29.4203V6.03906Z" fill="#313135" />
          <path d="M51.6244 6.03906H49.7208V17.7434H51.6244V6.03906Z" fill="#313135" />
          <path d="M17.9437 6.05176H16.0783V8.99707H14.3263V10.5197H16.0783V14.5949H17.9437V6.05176Z" fill="#313135" />
          <path d="M27.0208 6.2041H25.2697V10.4062H24.0509V11.9289H25.2697V17.2229H27.0208V6.2041Z" fill="#313135" />
          <path
            d="M23.0091 6.43262H21.2453V7.95605H19.4806V9.46602H21.2453V9.59327C21.2389 11.3542 20.7196 13.1964 19.0107 14.1801L18.9861 14.1943L20.0576 15.6054L20.0766 15.5946C21.0863 15.0176 21.7439 14.0719 22.1473 12.947C22.5512 13.9704 23.2086 14.8149 24.2043 15.3159L24.2231 15.3253L25.2431 13.9523L25.2177 13.9386C23.5535 13.0371 23.0218 11.3283 23.0218 9.59336V9.46602H24.7357V7.95605H23.0091V6.43262Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.4701 6.7627H31.5919V12.9064H34.9054V14.9004H30.5001V16.4357H41.2015V14.9004H36.7581V12.9064H40.0589V6.7627H38.1808V8.47656H33.4701V6.7627ZM33.4701 11.4092V9.96113H38.1808V11.4092H33.4701Z"
            fill="#313135"
          />
          <path
            d="M14.8079 6.78809H8.43568V8.29805L10.6701 8.29838C10.6637 9.72988 9.92048 11.2298 7.94561 11.8292L7.91406 11.8388L8.88159 13.3554L8.89993 13.3496C10.2401 12.9217 11.1342 12.0529 11.6544 10.958C12.1619 11.9454 13.0173 12.7316 14.2802 13.1338L14.2989 13.1397L15.2145 11.6486L15.1841 11.6389C13.3037 11.0394 12.5863 9.61514 12.5863 8.31113V8.29805H14.8079V6.78809Z"
            fill="#313135"
          />
          <path
            d="M48.1205 7.24512H42.3956V8.71699H46.2015C46.0691 9.95803 45.6947 11.0047 44.9891 11.914C44.2782 12.8303 43.2297 13.6089 41.7498 14.3061L41.7238 14.3184L42.7036 15.8078L42.7232 15.7986C44.847 14.8035 46.1955 13.5721 47.0135 12.1377C47.8312 10.7036 48.1173 9.06903 48.1204 7.27016L48.1205 7.24512Z"
            fill="#313135"
          />
          <path d="M11.9642 13.8086H10.0861V17.5529H18.2103V16.043H11.9642V13.8086Z" fill="#313135" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="22" viewBox="0 0 51 22" fill="none" className={'second-industry-icon'}>
          <path d="M23.5742 5.94141H25.0951V15.8627H23.5742V5.94141Z" fill="#313135" />
          <path d="M42.2549 5.94141H43.8832V15.8627H42.2549V5.94141Z" fill="#313135" />
          <path d="M13.7881 5.95215H15.3842V13.1987H13.7881V9.75043H12.3056V8.44434H13.7881V5.95215Z" fill="#313135" />
          <path d="M21.5654 6.08105H23.0648V15.4223H21.5654V10.9428H20.5341V9.63672H21.5654V6.08105Z" fill="#313135" />
          <path
            d="M18.1601 6.27441H19.6703V7.56348H21.1313V8.85883H19.6811V8.95773C19.6811 10.4242 20.1304 11.8661 21.5345 12.6267L21.5649 12.6432L20.6914 13.8191L20.6688 13.8078C19.831 13.3862 19.2757 12.6782 18.9327 11.8204C18.5902 12.7639 18.0346 13.5576 17.1845 14.0434L17.1616 14.0564L16.2443 12.8481L16.2738 12.8311C17.7158 12.0011 18.1548 10.4463 18.1601 8.95763V8.85883H16.667V7.56348H18.1601V6.27441Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.915 6.55371H28.5219V8.00391H32.4902V6.55371H34.0971V11.77H31.3041V13.4395H35.0639V14.7563H25.9912V13.4395H29.7187V11.77H26.915V6.55371ZM28.5219 9.27777V10.4854H32.4902V9.27777H28.5219Z"
            fill="#313135"
          />
          <path
            d="M7.32126 6.5752H12.7309V7.87055H10.851V7.87277C10.851 8.97264 11.4559 10.1742 13.043 10.6801L13.0795 10.6918L12.2952 11.969L12.2728 11.9619C11.2086 11.6231 10.4854 10.9624 10.054 10.1323C9.61167 11.0532 8.85555 11.7838 7.72561 12.1445L7.70362 12.1515L6.875 10.8526L6.91286 10.8411C8.57573 10.3364 9.20356 9.07588 9.21183 7.87055H7.32126V6.5752Z"
            fill="#313135"
          />
          <path
            d="M36.0566 6.96191H40.9184L40.9184 6.99197C40.9157 8.51497 40.6735 9.89972 39.9805 11.115C39.2873 12.3306 38.1447 13.3737 36.3464 14.2163L36.3229 14.2274L35.4839 12.9522L35.5152 12.9374C36.7667 12.3479 37.6525 11.6898 38.253 10.9159C38.8469 10.1504 39.1631 9.26955 39.2759 8.22504H36.0566V6.96191Z"
            fill="#313135"
          />
          <path d="M8.71774 12.5156H10.3246V14.4062H15.6098V15.7016H8.71774V12.5156Z" fill="#313135" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.5C1 2.29086 2.79086 0.5 5 0.5H46C48.2091 0.5 50 2.29086 50 4.5V17.5C50 19.7091 48.2091 21.5 46 21.5H5C2.79086 21.5 1 19.7091 1 17.5V4.5ZM5 2.33H46C47.1985 2.33 48.17 3.30154 48.17 4.5V17.5C48.17 18.6985 47.1985 19.67 46 19.67H5C3.80154 19.67 2.83 18.6985 2.83 17.5V4.5C2.83 3.30154 3.80154 2.33 5 2.33Z"
            fill="#313135"
          />
        </svg>
      </>
    ),
    [Industry.ALL]: (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" fill="none" className={'first-industry-icon'}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33 2.5H5C3.89543 2.5 3 3.39543 3 4.5V19.5C3 20.6046 3.89543 21.5 5 21.5H33C34.1046 21.5 35 20.6046 35 19.5V4.5C35 3.39543 34.1046 2.5 33 2.5ZM5 0.5C2.79086 0.5 1 2.29086 1 4.5V19.5C1 21.7091 2.79086 23.5 5 23.5H33C35.2091 23.5 37 21.7091 37 19.5V4.5C37 2.29086 35.2091 0.5 33 0.5H5Z"
            fill="#313135"
          />
          <path d="M18.0596 6.03906H16.2957V17.7434H18.0596V6.03906Z" fill="#313135" />
          <path d="M28.9522 6.05176H27.0741V12.6652H28.9522V6.05176Z" fill="#313135" />
          <path
            d="M15.5713 6.29297H13.8202V10.4443H12.6905C12.9328 9.51103 13.0323 8.50362 13.0323 7.42246V7.39746H8.37387V8.90742H11.1768C11.0608 10.0033 10.7638 10.9394 10.2141 11.7695C9.65969 12.6066 8.84721 13.3374 7.7006 14.0152L7.67578 14.0299L8.73553 15.3513L8.7541 15.3406C10.4087 14.3897 11.4772 13.261 12.1341 11.9416H13.8202V17.2482H15.5713V6.29297Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5161 6.55957C20.6714 6.55957 19.2601 7.729 19.2664 9.41562C19.2601 11.0451 20.6714 12.2463 22.5161 12.2463C24.3545 12.2463 25.7657 11.045 25.7657 9.41562C25.7657 7.72908 24.3544 6.55957 22.5161 6.55957ZM21.0938 9.41562C21.0938 8.99669 21.242 8.6787 21.492 8.46585C21.7426 8.25255 22.0984 8.14256 22.5162 8.1457C22.9213 8.14256 23.2687 8.25246 23.5137 8.46567C23.7582 8.67846 23.9034 8.99683 23.9002 9.41582C23.9034 9.81222 23.7585 10.124 23.5137 10.3362C23.2686 10.5488 22.9206 10.6633 22.5159 10.6602C22.0982 10.6633 21.7427 10.5487 21.492 10.336C21.2418 10.1237 21.0938 9.81197 21.0938 9.41562Z"
            fill="#313135"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M28.9522 13.1104H21.0311V17.6164H28.9522V13.1104ZM22.8711 16.1064V14.5949H27.1122V16.1064H22.8711Z" fill="#313135" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="22" viewBox="0 0 32 22" fill="none" className={'second-industry-icon'}>
          <path d="M13.9994 5.94141H15.5096V15.8627H13.9994V5.94141Z" fill="#313135" />
          <path d="M23.1195 5.95215H24.7264V11.5659H23.1195V5.95215Z" fill="#313135" />
          <path
            d="M11.9047 6.15625H13.4042V15.4438H11.9047V10.9536H10.4923C9.93541 12.0696 9.03053 13.0243 7.63129 13.8285L7.60901 13.8413L6.70117 12.7093L6.73095 12.6917C7.70034 12.1186 8.3867 11.5011 8.85487 10.7942C9.31744 10.0957 9.56823 9.30814 9.66701 8.38617H7.2963V7.09082H11.2557V7.12082C11.2557 8.0319 11.1723 8.88142 10.9691 9.66895H11.9047V6.15625Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5131 8.80734C16.5077 7.37412 17.7072 6.38184 19.2716 6.38184C20.8308 6.38184 22.0301 7.37421 22.0301 8.80734C22.0301 10.1921 20.8308 11.2114 19.2716 11.2114C17.7071 11.2114 16.5077 10.1922 16.5131 8.80734ZM20.1099 8.01021C19.9047 7.83157 19.613 7.739 19.2718 7.74164C18.9198 7.73899 18.6208 7.83167 18.4109 8.01042C18.2015 8.18864 18.077 8.45511 18.077 8.80734C18.077 9.14033 18.2012 9.40159 18.4108 9.57939C18.621 9.75765 18.9196 9.85421 19.2714 9.85156C19.6122 9.85421 19.9045 9.75776 20.11 9.5796C20.315 9.40188 20.4366 9.14063 20.434 8.80758C20.4366 8.45527 20.3146 8.18835 20.1099 8.01021Z"
            fill="#313135"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M18.0063 11.9248H24.7264V15.7553H18.0063V11.9248ZM19.5809 13.1987V14.46H23.1518V13.1987H19.5809Z" fill="#313135" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.5C1 2.29086 2.79086 0.5 5 0.5H27C29.2091 0.5 31 2.29086 31 4.5V17.5C31 19.7091 29.2091 21.5 27 21.5H5C2.79086 21.5 1 19.7091 1 17.5V4.5ZM5 2.33H27C28.1985 2.33 29.17 3.30154 29.17 4.5V17.5C29.17 18.6985 28.1985 19.67 27 19.67H5C3.80154 19.67 2.83 18.6985 2.83 17.5V4.5C2.83 3.30154 3.80154 2.33 5 2.33Z"
            fill="#313135"
          />
        </svg>
      </>
    ),
  },
  [LanguageCodeEnum.En]: {
    [Industry.GAME]: (
      <>
        <svg width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={'first-industry-icon'}>
          <path d="M26.8867 6.74805V17H33.4287V15.2441H29.0107V6.74805H26.8867Z" fill="#313135" />
          <path d="M18.9707 17V6.74805H21.0947V15.2441H25.5127V17H18.9707Z" fill="#313135" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.98242 17H10.2764L11.0481 14.6353H14.7581L15.5298 17H17.8096L14.2695 6.74805H11.5366L7.98242 17ZM14.22 12.9785H11.5862L12.8677 9.05615H12.9385L14.22 12.9785Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.5C1 2.29086 2.79086 0.5 5 0.5H36C38.2091 0.5 40 2.29086 40 4.5V19.5C40 21.7091 38.2091 23.5 36 23.5H5C2.79086 23.5 1 21.7091 1 19.5V4.5ZM5 2.5H36C37.1046 2.5 38 3.39543 38 4.5V19.5C38 20.6046 37.1046 21.5 36 21.5H5C3.89543 21.5 3 20.6046 3 19.5V4.5C3 3.39543 3.89543 2.5 5 2.5Z"
            fill="#313135"
          />
        </svg>
        <svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={'second-industry-icon'}>
          <path d="M23.3901 6.41211V15.25H29.0298V13.7363H25.2212V6.41211H23.3901Z" fill="#313135" />
          <path d="M16.5664 15.25V6.41211H18.3975V13.7363H22.2061V15.25H16.5664Z" fill="#313135" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.09375 15.25H9.07129L9.73657 13.2114H12.9348L13.6001 15.25H15.5654L12.5137 6.41211H10.1577L7.09375 15.25ZM12.4709 11.7832H10.2004L11.3052 8.40186H11.3662L12.4709 11.7832Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.5C1 2.29086 2.79086 0.5 5 0.5H31C33.2091 0.5 35 2.29086 35 4.5V17.5C35 19.7091 33.2091 21.5 31 21.5H5C2.79086 21.5 1 19.7091 1 17.5V4.5ZM5 2.33H31C32.1985 2.33 33.17 3.30154 33.17 4.5V17.5C33.17 18.6985 32.1985 19.67 31 19.67H5C3.80154 19.67 2.83 18.6985 2.83 17.5V4.5C2.83 3.30154 3.80154 2.33 5 2.33Z"
            fill="#313135"
          />
        </svg>
      </>
    ),
    [Industry.ALL]: (
      <>
        <svg width="57" height="24" viewBox="0 0 57 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={'first-industry-icon'}>
          <path d="M41.668 16.999V6.74707H48.5498V8.50293H43.792V10.9951H48.1958V12.751H43.792V15.2432H48.564V16.999H41.668Z" fill="#313135" />
          <path
            d="M31.501 6.74707H28.8672V16.999H30.9346V10.2588H31.0195L33.6958 16.9565H35.1118L37.7881 10.2871H37.873V16.999H39.9404V6.74707H37.3066L34.4604 13.6997H34.3472L31.501 6.74707Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1729 16.999H17.8789L21.4331 6.74707H24.166L27.7061 16.999H25.4263L24.6545 14.6343H20.9446L20.1729 16.999ZM21.4827 12.9775H24.1165L22.835 9.05518H22.7642L21.4827 12.9775Z"
            fill="#313135"
          />
          <path
            d="M12.7954 8.48877C13.9141 8.49585 14.6858 9.0835 14.9761 10.0464H17.1284C16.8452 8.02856 15.0681 6.60547 12.7529 6.60547C10.0483 6.60547 7.9668 8.55957 7.9668 11.8872C7.9668 15.1299 9.9209 17.1406 12.7954 17.1406C15.3796 17.1406 17.2275 15.498 17.2275 12.8076V11.5332H12.9512V13.1333H15.1743C15.146 14.4219 14.2751 15.2502 12.8096 15.2573C11.1741 15.2502 10.1333 14.0254 10.1333 11.8589C10.1333 9.70654 11.2095 8.49585 12.7954 8.48877Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 0.5C2.79086 0.5 1 2.29086 1 4.5V19.5C1 21.7091 2.79086 23.5 5 23.5H52C54.2091 23.5 56 21.7091 56 19.5V4.5C56 2.29086 54.2091 0.5 52 0.5H5ZM52 2.5H5C3.89543 2.5 3 3.39543 3 4.5V19.5C3 20.6046 3.89543 21.5 5 21.5H52C53.1046 21.5 54 20.6046 54 19.5V4.5C54 3.39543 53.1046 2.5 52 2.5Z"
            fill="#313135"
          />
        </svg>
        <svg width="50" height="22" viewBox="0 0 50 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={'second-industry-icon'}>
          <path d="M36.127 15.249V6.41113H42.0596V7.9248H37.958V10.0732H41.7544V11.5869H37.958V13.7354H42.0718V15.249H36.127Z" fill="#313135" />
          <path
            d="M27.3623 6.41113H25.0918V15.249H26.874V9.43848H26.9473L29.2544 15.2124H30.4751L32.7822 9.46289H32.8555V15.249H34.6377V6.41113H32.3672L29.9136 12.4048H29.8159L27.3623 6.41113Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5967 15.249H15.6191L18.6831 6.41113H21.0391L24.0908 15.249H22.1255L21.4602 13.2104H18.262L17.5967 15.249ZM18.7258 11.7822H20.9963L19.8916 8.40088H19.8306L18.7258 11.7822Z"
            fill="#313135"
          />
          <path
            d="M11.2368 7.9126C12.2012 7.9187 12.8665 8.42529 13.1167 9.25537H14.9722C14.728 7.51587 13.196 6.28906 11.2002 6.28906C8.86865 6.28906 7.07422 7.97363 7.07422 10.8423C7.07422 13.6377 8.75879 15.3711 11.2368 15.3711C13.4646 15.3711 15.0576 13.9551 15.0576 11.6357V10.5371H11.3711V11.9165H13.2876C13.2632 13.0273 12.5125 13.7415 11.249 13.7476C9.83911 13.7415 8.94189 12.6855 8.94189 10.8179C8.94189 8.9624 9.86963 7.9187 11.2368 7.9126Z"
            fill="#313135"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 0.5C2.79086 0.5 1 2.29086 1 4.5V17.5C1 19.7091 2.79086 21.5 5 21.5H45C47.2091 21.5 49 19.7091 49 17.5V4.5C49 2.29086 47.2091 0.5 45 0.5H5ZM45 2.33H5C3.80154 2.33 2.83 3.30154 2.83 4.5V17.5C2.83 18.6985 3.80154 19.67 5 19.67H45C46.1985 19.67 47.17 18.6985 47.17 17.5V4.5C47.17 3.30154 46.1985 2.33 45 2.33Z"
            fill="#313135"
          />
        </svg>
      </>
    ),
  },
};
