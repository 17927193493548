import React from 'react';

export default function AconGrayLogo() {
  return (
    <svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66667 0H12.3333L16 16L11 16L10.6667 14H5.33333L5 16H0L3.66667 0ZM6.88889 4.66667H9.11111L10.0556 10.3333H5.94444L6.88889 4.66667Z"
        fill="#6D6D70"
      />
      <path
        d="M24 0C19.5817 0 16 3.58171 16 7.99998C16 12.4182 19.5817 16 24 16H28L28 10.6667L24.2997 11.1713C22.378 11.4333 20.6667 9.93952 20.6667 8C20.6667 6.06048 22.378 4.56669 24.2997 4.82875L28 5.33333L28 0H24Z"
        fill="#6D6D70"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0C32.7658 0 29.3333 3.43248 29.3333 7.66667V8.33333C29.3333 12.5675 32.7658 16 37 16C41.2342 16 44.6667 12.5675 44.6667 8.33333V7.66667C44.6667 3.43248 41.2342 0 37 0ZM37 4.66667C35.3431 4.66667 34 6.00981 34 7.66667V8.33333C34 9.99019 35.3431 11.3333 37 11.3333C38.6569 11.3333 40 9.99019 40 8.33333V7.66667C40 6.00981 38.6569 4.66667 37 4.66667Z"
        fill="#6D6D70"
      />
      <path d="M51 16H46V0H50.3333L55.5385 8.61539L55 0H60V16H55.6667L50.4615 7.38462L51 16Z" fill="#6D6D70" />
    </svg>
  );
}
