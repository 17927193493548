import styled from '@emotion/styled';

export const SearchContainer = styled.div<{ isMobile?: boolean }>`
  border: #e8e8e8 1.5px solid;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  width: 320px;
  align-items: center;
  height: 46px;
  position: relative;

  ${(props) => !props.isMobile && props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }

  ${(props) =>
    props.isMobile &&
    `
      width: 100%;
  `}
`;

export const SearchInput = styled.input`
  border: none;
  height: 20px;
  flex: 1;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  &::placeholder {
    color: #88888a;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
`;

export const SearchDropDown = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  top: calc(100% + 1px);
  z-index: 100;

  padding: 24px 16px 28px 16px;
  box-shadow: -40px 40px 80px -8px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 8px;
`;

export const SearchIconBox = styled.span`
  cursor: pointer;
`;

export const PopularSearchRollingWrapper = styled.div`
  position: relative;
  padding: 5px 0;
`;

export const PopularSearchRollingDropdown = styled(SearchDropDown)`
  top: 0;
  min-width: 320px;
`;
