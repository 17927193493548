import { Typography } from 'carpenstreet-designsystem';
import styled from '@emotion/styled';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';
import { withAttrs } from '@util/componentAdapter';

export const SubGnbContainer = styled.div`
  padding: 0 44px 0 44px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding: 0 42px 0 42px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 0 26px 0 26px;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 0 18px 0 18px;
  }
`;

export const MenuList = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  /* width: fit-content; */
  width: 100%;
  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 24px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 16px;
  }
`;

export const MenuItemText = styled(Typography)`
  white-space: nowrap;
`;

export const DividerText = styled(MenuItemText)`
  font-weight: 500;
`;

export const MenuItem = styled.div<{ isActive?: boolean; isHighlight?: boolean; gap?: string }>`
  padding: 8px 0 22px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${(props) => (props?.gap ? props.gap : '4px')};

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
  }

  &:hover {
    &::after {
      background-color: ${(props) => (props?.isHighlight ? '#4E4EFF' : '#222222')};
    }
  }
  &.no-hover {
    &:hover {
      &::after {
        background-color: transparent;
      }
    }
  }

  ${(props) =>
    props?.isActive &&
    `
    & > ${MenuItemText} {
        font-weight: ${props?.isHighlight ? 800 : 700};
    }
    
    &::after {
      background-color: ${props?.isHighlight ? '#4E4EFF' : '#222222'};
    }
  `}

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding: 10px 0 18px 0;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 8px 0 14px 0;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 10px 0 14px 0;
  }
`;

export const MenuSkeleton = styled(
  withAttrs(Skeleton, {
    width: '55px',
    height: '24px',
  }),
)``;

export const NewBadgeText = styled.div`
  color: white;
  font-size: 11px;
  line-height: 11px;
  font-weight: 800;
`;
