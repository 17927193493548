import React from 'react';

export default function SearchIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4765 11.8908C9.49566 12.5892 8.2958 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29586 12.5892 9.49577 11.8907 10.4766L14.5 13.0858C14.8905 13.4763 14.8905 14.1095 14.5 14.5C14.1095 14.8905 13.4763 14.8905 13.0858 14.5L10.4765 11.8908ZM11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7Z"
        fill="#313135"
      />
    </svg>
  );
}
