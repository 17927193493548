import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BottomNavigationsContainer, NavigationItem, NavigationText } from '@components/_new_components/Common/layout/BottomController/BottomNavigations/BottomNavigations.styles';
import { useUserInfo } from '@hooks';
import { buttonClickLog } from '@lib/mixpanel/events';
import HomeIcon from '../../../../../../src/assets/icons/bottomNavigation/HomeIcon';
import SearchIcon from '../../../../../../src/assets/icons/bottomNavigation/SearchIcon';
import CategoryIcon from '../../../../../../src/assets/icons/bottomNavigation/CategoryIcon';
import ProfileIcon from '../../../../../../src/assets/icons/bottomNavigation/ProfileIcon';
import { UserInfoStatusEnum } from '../../../../../../src/provider';

type navigationNames = 'home' | 'search' | 'category' | 'profile' | null;

function BottomNavigations(_, ref) {
  const { t } = useTranslation(['adult']);
  const { push, pathname } = useRouter();
  const { status } = useUserInfo();

  const bottomNavigationEventLog = (value: 'home' | 'search' | 'category' | 'profile') => {
    return () => {
      buttonClickLog({
        actionName: 'mobile bottom navi',
        value: value,
      });
    };
  };

  const handleNavigationClick = (name: navigationNames, path: string) => {
    if (pathname === name) return;
    push(path);
  };

  const handleProfileTabClick = (e) => {
    e.preventDefault();
    bottomNavigationEventLog('profile')();

    if (status === UserInfoStatusEnum.unauthenticated) {
      window.alert(t('message:pleaseLogin'));
      push({
        pathname: '/users/login',
        query: { returnUrl: '/mypage' },
      });
      return false;
    }

    push('/mypage');
  };

  return (
    <BottomNavigationsContainer ref={ref}>
      <Link href={'/'} onClick={bottomNavigationEventLog('home')}>
        <NavigationItem isActive={pathname === '/'}>
          <HomeIcon isActive={pathname === '/'} />
          <NavigationText>{t('menu:bottomTab.home')}</NavigationText>
        </NavigationItem>
      </Link>

      <Link href={'/tabs/search'} onClick={bottomNavigationEventLog('search')}>
        <NavigationItem isActive={['/tabs/search', '/search'].includes(pathname)}>
          <SearchIcon isActive={['/tabs/search', '/search'].includes(pathname)} />
          <NavigationText>{t('menu:bottomTab.search')}</NavigationText>
        </NavigationItem>
      </Link>

      <Link href={'/tabs/category'} onClick={bottomNavigationEventLog('category')}>
        <NavigationItem
          isActive={['/tabs/category', '/category/[id]'].includes(pathname)}
          onClick={() => {
            handleNavigationClick('category', '#category');
          }}
        >
          <CategoryIcon isActive={['/tabs/category', '/category/[id]'].includes(pathname)} />
          <NavigationText>{t('menu:bottomTab.category')}</NavigationText>
        </NavigationItem>
      </Link>

      <Link href={'/mypage'}>
        <NavigationItem isActive={pathname === '/mypage'} onClick={handleProfileTabClick}>
          <ProfileIcon isActive={pathname === '/mypage'} />
          <NavigationText>{t('menu:bottomTab.profile')}</NavigationText>
        </NavigationItem>
      </Link>
    </BottomNavigationsContainer>
  );
}

export default React.forwardRef(BottomNavigations);
