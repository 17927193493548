import styled from '@emotion/styled';

export const HeaderContainer = styled.div<{ isHidden: boolean }>`
  background-color: white;
  width: 100%;
  position: relative;

  ${(props) => props.theme.breakpoints.up('dt_1440')} {
    // 페이지 맨 위에 붙어있도록 sticky를 적용합니다.
    position: sticky;
    top: 0;
    // 홈 메인 배너가 슬라이딩을 위해 최대 z-index를 5로 설정했으므로, 그보다 높은 z-index를 적용합니다.
    z-index: 6;

    ${(props) =>
      props.isHidden
        ? `
    opacity: 0;
    transform: translateY(-100%);
    transition:
      opacity 0.3s,
      transform 0.3s 0.3s;    
`
        : `    
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 0.3s,
      transform 0s;
`}
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px #cccccc solid;
    transform: scaleY(0.35);
    position: absolute;
    bottom: 0;
  }
`;
