import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { ADD_CART_GOODS } from '@api';
import mixpanel from '@lib/mixpanel';
import { facebookEvent } from '@lib/facebook';
import { AddCartType } from '@lib/mixpanel/type';
import { event as naverEvent, eventType as naverEventType } from '@lib/na';
import { getCurrencyUnit } from '@lib/currencyUnit';
import { CommonContext, UserInfoStatusEnum } from '../provider';

// 장바구니에 대한 custom hook 입니다.
export const useCart = (goodsNo: number, initialValue, withoutAuth = false) => {
  const [isCart, setIsCart] = useState(initialValue);
  const { carts, cartInfo, onAddCart, cartsOnSession, setCartsOnSession, locale } = useContext(CommonContext);

  useEffect(() => {
    if (carts || cartsOnSession) {
      setIsCart(carts.some((cart) => cart.godoGoodsNo === goodsNo) || cartsOnSession.some((godoGoodsNo) => godoGoodsNo === goodsNo));
    }
  }, [carts, cartsOnSession]);

  // 장바구니 담기 액션
  const [addCartGoods] = useMutation(ADD_CART_GOODS);

  if (withoutAuth) {
    const addCartOnSession = () => {
      setIsCart(true);
      setCartsOnSession(Array.from(new Set([...cartsOnSession, goodsNo])));

      // [todo]: Product Nos를 제외한 상품 번호 프로퍼티는 제거해야 함.
      mixpanel.event(
        'Add Cart',
        {
          goodsNos: [goodsNo],
          'Product Nos': [goodsNo],
          status: UserInfoStatusEnum.unauthenticated,
        },
        false,
      );
    };
    return [isCart, setIsCart, addCartOnSession];
  }

  // 장바구니 담기 이벤트
  const addCart = async (type: AddCartType, title?: string) => {
    // 즉시 변화를 주기위해 먼저 true 지정
    setIsCart(true);
    onAddCart(type, goodsNo);

    // 고도몰에 장바구니 추가 요청
    await addCartGoods({
      variables: {
        goodsNo: goodsNo.toString(),
      },
    });
    cartInfo.refetch();
    facebookEvent('AddToCart', {
      content_name: title || 'UnableToGetProductTitle',
      currency: getCurrencyUnit(locale).name,
      status: true,
      value: goodsNo.toString(),
    });
    naverEvent(naverEventType.ADD_CART, JSON.stringify({ content_name: title, goodsNo }));
  };

  return [isCart, setIsCart, addCart];
};
