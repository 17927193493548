import styled from '@emotion/styled';

export const BandBannerContainer = styled.div<{ isHide: boolean }>`
  flex-direction: column;
  display: none;
  width: 100%;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: flex;
    ${(props) => (props.isHide ? 'display: none;' : 'display: flex;')}
  }

  ${(props) => props.theme.breakpoints.down('mb_360')} {
    gap: 16px;
  }
`;

export const BandBannerItem = styled.div<{ color: string }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  box-shadow:
    0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  ${(props) => `
        background-color: ${props.color ? props.color : '#313135'};
    `}
`;

export const BandBannerText = styled.div<{ color: string }>`
  display: flex;
  margin: 12px 0;
  justify-content: center;
  width: calc(100% - 84px);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  word-break: break-all;

  ${(props) => `color: ${props.color || 'black'};`}
`;
