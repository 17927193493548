import styled from '@emotion/styled';
import { IconWrapper } from '../../styled';

export const IndustryButtonIconWrapper = styled(IconWrapper)`
  cursor: pointer;

  & > svg.second-industry-icon {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    & > svg.first-industry-icon {
      display: none;
    }

    & > svg.second-industry-icon {
      display: block;
    }
  }
`;
