import React from 'react';
import Snackbar from '@components/_new_components/Common/layout/BottomController/Snackbar/Snackbar';
import { useDisplaySnackbarOnReload, useSnackbar } from '../../../../../../src/stores/layout/useLayout.store';
import { ISnackBar } from '../../../../../../src/stores/layout/useLayout.type';
import { SnackbarCenter } from './Snackbar.styles';

export default function SnackbarList() {
  const { snackbar, removeSnackbar } = useSnackbar();
  const { moveMessageToStore } = useDisplaySnackbarOnReload();

  const handleSnackbarClose = (message: ISnackBar['message']) => {
    return () => removeSnackbar(message);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') moveMessageToStore();
  }, []);

  if (snackbar.length === 0) return <React.Fragment />;

  return (
    <SnackbarCenter>
      {snackbar.map((data) => (
        <Snackbar key={data.message} snackbarData={data} onClose={handleSnackbarClose(data.message)} />
      ))}
    </SnackbarCenter>
  );
}
