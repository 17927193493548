import React from 'react';
import { Typography } from 'carpenstreet-designsystem';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import {
  Divider,
  SearchAutoCompleteContainer,
  SearchAutoCompleteSection,
  SearchAutoCompleteSectionItem,
  SearchAutoCompleteSectionTitle,
} from '@components/_new_components/Common/Search/SearchAutoComplete.styles';
import { SearchAutoCompletePropsType } from '@components/_new_components/Common/Search/SearchAutoComplete.types';
import { autoCompleteQueryDataKeys } from '../../../../src/types/search/Search.types';

export default function SearchAutoComplete({ data, keyword, activeItem, onClickAutoCompleteItem, keywordsLimit = 8 }: SearchAutoCompletePropsType) {
  const { t } = useTranslation();

  const escapeKeyword = (keyword: string) => {
    // eslint-disable-next-line
    const specialCharacters = /[+\-=&|><!(){}\[\]^"~*?:\\/]/g;
    return keyword.replace(specialCharacters, '\\$&');
  };

  const splitKeyword = (value: string, keyword: string) => {
    if (!keyword) return [value];

    // keyword를 기준으로 value를 나누어 배열로 반환
    // ex) value: "카펜카펜스트리트", keyword: "카펜" = ["카펜", '카펜', '스트리트']
    return value.split(new RegExp(`(${escapeKeyword(keyword)})`, 'g'));
  };

  const checkActiveItem = (key: autoCompleteQueryDataKeys, value) => {
    if (!activeItem || activeItem.type !== key) return;
    if (key === 'brand') return value.code === activeItem.value;
    if (key === 'keywords') return value === activeItem.value;
  };

  const getSplitKeywordByType = (key: autoCompleteQueryDataKeys, value) => {
    if (key === 'brand') return splitKeyword(value.name, keyword);
    return splitKeyword(value, keyword);
  };

  const getLinkByType = (key: autoCompleteQueryDataKeys, value) => {
    if (key === 'brand') return `/brand/${value.code}`;
    return `/search?keyword=${value}`;
  };

  const getSliceValue = (key: autoCompleteQueryDataKeys, value) => {
    if (key === 'keywords') value.slice(0, keywordsLimit);
    return value;
  };

  const handleAutoCompleteItemClick = (key: autoCompleteQueryDataKeys, value) => () => {
    onClickAutoCompleteItem(key, value);
  };

  return (
    <SearchAutoCompleteContainer>
      {data.map(([key, value], idx) => (
        <React.Fragment key={key}>
          <SearchAutoCompleteSection>
            <SearchAutoCompleteSectionTitle>
              <Typography variant={'typography/label/large/medium'} color={'color/gray/300'}>
                {t(`menu:search.autoComplete.${key}`)}
              </Typography>
            </SearchAutoCompleteSectionTitle>

            {getSliceValue(key, value).map((item, childIdx) => {
              return (
                <Link href={getLinkByType(key, item)} key={childIdx} onClick={handleAutoCompleteItemClick(key, item)}>
                  <SearchAutoCompleteSectionItem isActive={checkActiveItem(key, item)}>
                    {getSplitKeywordByType(key, item).map((value, idx) => {
                      const isHighlight = value === keyword;
                      return (
                        <Typography
                          key={`${value}/${isHighlight}/${idx}`}
                          variant={'typography/body/small/regular'}
                          color={isHighlight ? 'color/primary/600' : 'color/gray/900'}
                          display={'inline'}
                        >
                          {value}
                        </Typography>
                      );
                    })}
                  </SearchAutoCompleteSectionItem>
                </Link>
              );
            })}
          </SearchAutoCompleteSection>

          {data.length - 1 > idx && <Divider />}
        </React.Fragment>
      ))}
    </SearchAutoCompleteContainer>
  );
}
