import styled from '@emotion/styled';
import { FlexRowAlignCenter } from '@components/wrapper';
import { CustomText } from '@components/ui/Text';

interface IStyleProps {
  fixed?: boolean;
  show?: boolean;
}

export const Wrapper = styled(FlexRowAlignCenter)<IStyleProps>`
  width: 100%;
  display: none;
  justify-content: space-between;
  position: relative;
  background: #fdfad8;
  padding: 10px 16px;

  ${(props) => props?.show && 'display: flex;'}

  ${(props) => props.theme.breakpoints.down('st_600')} {
    flex-direction: column;
    padding: 0;
  }
`;

export const MessageAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 100%;
    padding: 12px 16px;
    justify-content: space-between;
    gap: 40px;
  }
`;

export const MessageText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.71px;
  flex: 1;
  word-break: keep-all;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.2px;
  }
`;

export const ChromeButton = styled.div`
  display: flex;
  padding: 5px 7px 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 3px;
  background: #313135;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 9px 10px;
  }
`;

export const ChromeButtonText = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: white;
  line-height: 11.93px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 12px;
    line-height: 14.32px;
  }
`;

export const NotShowAgainButton = styled(CustomText)`
  text-decoration-line: underline;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: none;
  }
`;

export const MobileNotShowAgainButton = styled.div`
  padding: 7px 0;
  background-color: white;
  cursor: pointer;
  width: 100%;
  display: none;
  justify-content: center;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: flex;
  }

  & > ${NotShowAgainButton} {
    text-decoration-line: unset;
    display: block;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px #cccccc solid;
    transform: scaleY(0.35);
    position: absolute;
    bottom: 23px;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px #cccccc solid;
    transform: scaleY(0.35);
    position: absolute;
    bottom: 0;
  }
`;
