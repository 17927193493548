import React, { forwardRef } from 'react';
import { FirstLevelTitleIconBox, FirstLevelTitleIconBoxOverflow } from '@components/_new_components/Common/layout/Category/Category.styles';
import Category3DIcon from '../../../../../src/assets/icons/category/Category3DIcon';
import Category3DHoverIcon from '../../../../../src/assets/icons/category/Category3DHoverIcon';
import Category2DHoverIcon from '../../../../../src/assets/icons/category/Category2DHoverIcon';
import Category2DIcon from '../../../../../src/assets/icons/category/Category2DIcon';
import CategoryBrushIcon from '../../../../../src/assets/icons/category/CategoryBrushIcon';
import CategoryBrushHoverIcon from '../../../../../src/assets/icons/category/CategoryBrushHoverIcon';
import CategorySoundIcon from '../../../../../src/assets/icons/category/CategorySoundIcon';
import CategorySoundHoverIcon from '../../../../../src/assets/icons/category/CategorySoundHoverIcon';
import CategoryToolIcon from '../../../../../src/assets/icons/category/CategoryToolIcon';
import CategoryToolHoverIcon from '../../../../../src/assets/icons/category/CategoryToolHoverIcon';
import CategoryClassIcon from '../../../../../src/assets/icons/category/CategoryClassIcon';
import CategoryClassHoverIcon from '../../../../../src/assets/icons/category/CategoryClassHoverIcon';

interface Props {
  firstLevelCategoryCode: '334' | '335' | '336' | '337' | '338' | '339';
}

function CategoryIcon({ firstLevelCategoryCode }: Props, ref) {
  return (
    <FirstLevelTitleIconBoxOverflow>
      <FirstLevelTitleIconBox categoryCode={firstLevelCategoryCode} ref={ref}>
        {firstLevelCategoryCode === '334' && (
          <>
            <Category3DIcon />
            <Category3DHoverIcon />
          </>
        )}

        {firstLevelCategoryCode === '335' && (
          <>
            <Category2DIcon />
            <Category2DHoverIcon />
          </>
        )}

        {firstLevelCategoryCode === '336' && (
          <>
            <CategoryBrushIcon />
            <CategoryBrushHoverIcon />
          </>
        )}

        {firstLevelCategoryCode === '337' && (
          <>
            <CategorySoundIcon />
            <CategorySoundHoverIcon />
          </>
        )}

        {firstLevelCategoryCode === '338' && (
          <>
            <CategoryToolIcon />
            <CategoryToolHoverIcon />
          </>
        )}

        {firstLevelCategoryCode === '339' && (
          <>
            <CategoryClassIcon />
            <CategoryClassHoverIcon />
          </>
        )}
      </FirstLevelTitleIconBox>
    </FirstLevelTitleIconBoxOverflow>
  );
}

export default forwardRef(CategoryIcon);
