import styled from '@emotion/styled';
import { FlexRowAlignCenter } from '@components/wrapper';
import { NoBorderButton } from '@components/ui/Button';
import { GlobalSelect } from '../Footer.styles';

export const Wrapper = styled(FlexRowAlignCenter)`
  width: 100%;
  height: 64px;
  position: relative;
  padding-left: 22px;
  padding-right: 22px;
  bottom: 0;
  background-color: white;
  box-shadow: 0px -0.2px 10px rgba(0, 0, 0, 0.25);
  z-index: 999;
`;

export const Container = styled(FlexRowAlignCenter)`
  max-width: 280px;
  width: calc(100% - 40px);
  justify-content: space-between;
  margin: 0 auto;
`;

export const Select = styled(GlobalSelect)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-left: 8px;
`;

export const Button = styled(NoBorderButton)`
  background: #4285f4;
  border: 1px solid #3089ed;
  border-radius: 2px;
  margin-left: 8px;
  min-width: 44px;

  &:hover {
    opacity: 0.8;
  }
`;
