import { create } from 'zustand';

interface IHeaderContainerStore {
  headerContainer: HTMLDivElement | null;
  setHeaderContainer: (elem: HTMLDivElement | null) => void;
}

interface IRequestHideHeaderStore {
  hideHeaderRequesterIds: Set<string>;
  requestHideHeader: (id: string) => void;
  requestUnhideHeader: (id: string) => void;
}

const headerContainerStore = create<IHeaderContainerStore>((set) => ({
  headerContainer: null,
  setHeaderContainer: (elem) => set({ headerContainer: elem }),
}));

const requestHideHeaderStore = create<IRequestHideHeaderStore>((set) => ({
  hideHeaderRequesterIds: new Set<string>(),
  requestHideHeader: (id) => {
    set((state) => {
      const newIds = new Set(state.hideHeaderRequesterIds);
      newIds.add(id);
      return { hideHeaderRequesterIds: newIds };
    });
  },
  requestUnhideHeader: (id) => {
    set((state) => {
      const newIds = new Set(state.hideHeaderRequesterIds);
      newIds.delete(id);
      return { hideHeaderRequesterIds: newIds };
    });
  },
}));

/**
 * 특정 컴포넌트가 GNB를 숨겨야 할 때 사용할 수 있습니다.
 * 이 hook의 headerContainer로 숨겨야 할 헤더의 Element 정보를 읽을 수 있습니다.
 * 특정 컴포너트에서 숨김 처리할 경우 `requestHideHeader`를 호출하고, 다시 보이게 할 경우 `requestUnhideHeader`를 호출합니다.
 */
export function useRequestHideHeader() {
  const { headerContainer } = headerContainerStore();
  const { hideHeaderRequesterIds, requestHideHeader, requestUnhideHeader } = requestHideHeaderStore();

  return {
    headerContainer,
    hideHeaderRequesterIds,
    requestHideHeader,
    requestUnhideHeader,
  };
}

/**
 * `components/_new_components/Common/layout/Header/Header.tsx`에서만 사용할 목적으로 제작된 hook입니다.
 * 이 훅의 `setHeaderContainer` 기능을 이용하여 해당 컴포넌트에서 자신을 전역 환경에서 다른 컴포넌트가 사용할 수 있도록 등록할 수 있습니다.
 */
export const useHeaderContainer = headerContainerStore;
