import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { CustomText } from '@components/ui/Text';

const CountBadge = styled.div<{ isLarge: boolean; badgeColor?: string }>`
  position: absolute;
  background-color: ${(props) => props.badgeColor || props.theme.colors.gnbCount};
  min-width: 16px;
  height: 16px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -6px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-width: 14px;
    height: 14px;
    top: -4px;
    right: -4px;
  }
`;

const CardIconWrapper = styled.div`
  & > div.small {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    & > div.large {
      display: none;
    }

    & > div.small {
      display: block;
    }
  }
`;

export default function GnbCart({ className = '', count = null, isLogin = true }) {
  const theme = useTheme();

  return (
    <CardIconWrapper className={`relative ${className}`}>
      <div className={'large'}>
        <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6564 6.0452H21L19 13.5H9.5L6.94786 3H3" stroke={theme.colors.icon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="10.4739" cy="19" r="2" fill={theme.colors.icon} />
          <circle cx="17.4739" cy="19" r="2" fill={theme.colors.icon} />
        </svg>
      </div>

      <div className={'small'}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6851 5.54013H19.25L17.4167 12.3737H8.70833L6.41667 3.20703H2.75" stroke="#313135" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="9.60286" cy="17.4154" r="1.83333" fill="#313135" />
          <circle cx="16.0189" cy="17.4154" r="1.83333" fill="#313135" />
        </svg>
      </div>

      {count > 0 && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CountBadge isLarge={count >= 99} badgeColor={!isLogin ? '#4E4EFF' : undefined}>
          <CustomText size={10} weight={500} color={theme.colors.gnbCountText}>
            {count >= 99 ? '99+' : count}
          </CustomText>
        </CountBadge>
      )}
    </CardIconWrapper>
  );
}
