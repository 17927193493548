import { useTheme } from '@mui/material/styles';
import { createBreakpoint } from 'react-use';
import { newBreakpoints } from '@components/settings/ThemeMui';

export interface IUseResponsiveDevice {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export default function useResponsiveDevice(): IUseResponsiveDevice {
  const theme = useTheme();
  const sizes = theme.breakpoints.values;
  const useBreakPoint = createBreakpoint({
    largeDesktop: sizes.lg,
    desktop: sizes.md,
    tablet: sizes.sm,
    mobile: sizes.xs,
  });

  const breakPoint = useBreakPoint();
  const isDesktop = breakPoint === 'largeDesktop' || breakPoint === 'desktop';
  const isTablet = breakPoint === 'tablet';
  const isMobile = !isDesktop && !isTablet;

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}

interface INewUseResponsiveDevice {
  isDesktop: boolean;
  isLargeTablet: boolean;
  isSmallTablet: boolean;
  isMobile: boolean;
  currentBreakpoint: keyof typeof newBreakpoints;
}

export function useNewResponsiveDevice(): INewUseResponsiveDevice {
  const breakpointItems = newBreakpoints;
  const useBreakPoint = createBreakpoint({
    ...breakpointItems,
  });

  const currentBreakpoint = useBreakPoint() as keyof typeof newBreakpoints;
  const breakpointKeys = Object.keys(breakpointItems);
  const desktopBreakpoints = breakpointKeys.filter((item) => item.includes('dt'));
  const largeTabletBreakpoints = breakpointKeys.filter((item) => item.includes('lt'));
  const smallTabletBreakpoints = breakpointKeys.filter((item) => item.includes('st'));
  const mobileBreakpoints = breakpointKeys.filter((item) => item.includes('mb'));

  return {
    isDesktop: desktopBreakpoints.includes(currentBreakpoint),
    isLargeTablet: largeTabletBreakpoints.includes(currentBreakpoint),
    isSmallTablet: smallTabletBreakpoints.includes(currentBreakpoint),
    isMobile: mobileBreakpoints.includes(currentBreakpoint),
    currentBreakpoint: currentBreakpoint,
  };
}
