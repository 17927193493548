import { QueryCache, QueryClient, useQuery, UseQueryOptions } from 'react-query';

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      window.alert(error);
    },
  }),
});

type TQueryKeyable = string | number | Symbol;

/**
 * axios 함수를 useQuery 훅 형태로 반환하는 함수입니다
 * SSR을 염두에 두고 만들어졌으며, react-query로 GET method 이용 시에는 해당 함수를 사용하도록 합니다
 * @param queryKey 쿼리의 이름; *.constants.ts에 상수로 정의하고 import하여 사용하도록 합니다
 * @param queryFn axios 함수
 */
export function getUseQuery<ParamsType, Return>(queryKey: TQueryKeyable | TQueryKeyable[], queryFn: (paramsObj: ParamsType) => Promise<Return>) {
  /**
   * 반환되는 훅 형태의 함수
   * @param paramsObj axios 함수에 넘겨지는 변수; object 형태로 넘겨주도록 합니다 (타입 정의도 object 형태로 하도록 합니다)
   * @param options react-query의 useQuery options
   */
  function useThisQuery(paramsObj?: ParamsType, options?: UseQueryOptions<Return>) {
    return useQuery<Return>(useThisQuery.getKeys(paramsObj), () => useThisQuery.fetcher(paramsObj), options);
  }

  /**
   * SSR시에 이용되는 메서드; queryKey를 반환합니다
   * @param paramsObj axios 함수에 넘겨지는 변수
   */
  useThisQuery.getKeys = (paramsObj?: ParamsType) => {
    return paramsObj ? [queryKey, paramsObj] : [queryKey];
  };
  /**
   * SSR시에 이용되는 메서드; axios 함수를 반환합니다
   */
  useThisQuery.fetcher = queryFn;

  return useThisQuery;
}
