import React from 'react';

export default function CategoryBrushIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2567_78109)">
        <path
          d="M15.4996 17.5016H16.8C17.0761 17.5016 17.3 17.7254 17.3 18.0016V21.1365C17.3 21.3745 17.3782 21.6078 17.5206 21.7985V21.7985C17.9636 22.3919 18.8606 22.391 19.2932 21.7899V21.7899C19.4274 21.6034 19.4996 21.3795 19.4996 21.1498V19.2531C19.4996 19.1151 19.6115 19.0031 19.7496 19.0031V19.0031C19.8877 19.0031 19.9996 19.1151 19.9996 19.2531V19.5625C19.9996 19.9827 20.2452 20.3652 20.6264 20.5417V20.5417C20.9133 20.6744 21.2453 20.6743 21.5316 20.5404V20.5404C21.9087 20.3642 22.1496 19.9856 22.1496 19.5694V3.35156C22.1496 2.79928 21.7019 2.35156 21.1496 2.35156H5.84961C5.29732 2.35156 4.84961 2.79928 4.84961 3.35156V11.5016"
          stroke="black"
          strokeWidth="1.7"
        />
        <path
          d="M5.98828 10.4414L4.3373 12.0924C4.3373 12.0924 4.3373 12.0924 4.3373 12.0924C4.3373 12.0924 3.19556 13.2341 4.3373 15.137C4.98112 16.2101 5.43681 16.8597 5.45629 17.3954C5.47783 17.9879 4.85598 18.3548 4.37351 18.6995L2.79688 19.8256C2.55564 19.9979 2.3151 20.182 2.16558 20.438C1.88469 20.9189 1.73235 21.666 2.4344 22.368C3.13645 23.0701 3.88347 22.9177 4.36439 22.6368C4.62038 22.4873 4.80447 22.2468 4.97678 22.0055L6.10295 20.4289C6.44757 19.9464 6.81453 19.3246 7.40704 19.3461C7.94274 19.3656 8.59235 19.8213 9.66539 20.4651C11.5683 21.6068 12.71 20.4651 12.71 20.4651C12.71 20.4651 12.71 20.4651 12.71 20.4651L14.361 18.8141L5.98828 10.4414Z"
          stroke="black"
          strokeWidth="1.7"
        />
        <path
          d="M6.23942 10.1864L9.66462 6.76116C10.085 6.34078 10.7666 6.34078 11.1869 6.76116L18.0373 13.6116C18.4577 14.0319 18.4577 14.7135 18.0373 15.1339L14.6121 18.5591"
          stroke="black"
          strokeWidth="1.7"
        />
        <path
          d="M11.9191 11.6796C11.5871 12.0116 11.5871 12.5498 11.9191 12.8817C12.251 13.2137 12.7892 13.2137 13.1211 12.8817L11.9191 11.6796ZM14.0122 9.58646L11.9191 11.6796L13.1211 12.8817L15.2143 10.7885L14.0122 9.58646Z"
          fill="black"
        />
        <path
          d="M9.82726 9.58589C9.49532 9.91784 9.49532 10.456 9.82726 10.788C10.1592 11.1199 10.6974 11.1199 11.0293 10.788L9.82726 9.58589ZM11.9204 7.49271L9.82726 9.58589L11.0293 10.788L13.1225 8.69479L11.9204 7.49271Z"
          fill="black"
        />
        <path
          d="M14.0128 13.7695C13.6809 14.1014 13.6809 14.6396 14.0128 14.9716C14.3448 15.3035 14.8829 15.3035 15.2149 14.9716L14.0128 13.7695ZM16.106 11.6763L14.0128 13.7695L15.2149 14.9716L17.3081 12.8784L16.106 11.6763Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2567_78109">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
