import { useEffect, useRef } from 'react';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1, 33: 1, 34: 1, 35: 1, 36: 1 };

export const preventDefault = (e: any) => {
  const newEvent = e || window.event;
  if (newEvent.preventDefault) newEvent.preventDefault();
  newEvent.returnValue = false;
};

export const stopPropagation = (event: any) => {
  event.stopPropagation();
};

export const preventDefaultForScrollKeys = (e: any) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};

interface IUseDisableScrollProps<Dependency> {
  isLocked?: boolean;
  dependencyArray?: Array<Dependency>;
}

export const useDisableScroll = <ScrollableRef extends HTMLElement, Dependency = []>(props?: IUseDisableScrollProps<Dependency>) => {
  const isLocked = props?.isLocked || false;
  const dependencyArray = props?.dependencyArray || [];
  const scrollableRef = useRef<ScrollableRef | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (isLocked) return;
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    const checkScrollableElement = (target: EventTarget): boolean => {
      const element = scrollableRef.current;
      if (!element) return false;
      // 스크롤이 존재하는 영역만 스크롤 가능하도록 허용
      if (element.clientHeight === element.scrollHeight) return false;
      return element.contains(target as Node);
    };

    const handleWheelEvent = (e: WheelEvent) => {
      if (checkScrollableElement(e.target)) return;
      preventDefault(e);
    };

    const handleTouchMoveEvent = (e: TouchEvent) => {
      if (checkScrollableElement(e.target)) return;
      preventDefault(e);
    };

    const handleKeyDownEvent = (e: KeyboardEvent) => {
      if (checkScrollableElement(e.target)) return;
      preventDefaultForScrollKeys(e);
    };

    document.body.addEventListener(wheelEvent, handleWheelEvent, { passive: false }); // modern desktop
    document.body.addEventListener('touchmove', handleTouchMoveEvent, { passive: false }); // tabs
    document.body.addEventListener('keydown', handleKeyDownEvent, false);
    return () => {
      document.body.removeEventListener(wheelEvent, handleWheelEvent, false); // modern desktop
      document.body.removeEventListener('touchmove', handleTouchMoveEvent, false); // tabs
      document.body.removeEventListener('keydown', handleKeyDownEvent, false);
    };
  }, [isLocked, ...dependencyArray]);

  return {
    scrollableRef,
  };
};

export default useDisableScroll;
