import { createContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useQuery as useReactQuery } from 'react-query';
import jwt_decode from 'jwt-decode';
import { useInterval } from 'react-use';
import { captureException } from '@sentry/nextjs';
import { ADD_CART_GOODS, GET_NOTIFICATION } from '@api';
import { deserializeAuthToken, EnhancedError, requestRefreshToken, serializeAuthToken } from '@lib/auth';
import { useGetCartsQuery, useGetClipsQuery, useOrderProductCardsQuery } from 'src/generated/graphql';
import * as mixpanel from '@lib/mixpanel';
import { useCartOnSession } from '@hooks/cartOnSession';
import { communityApi } from '@api/community/community.api';
import { godomallInstance } from '@api/godomall';
import { notiflyController } from '@util/notifly';
import { NotiflyEventHandlers } from 'src/types/notifly.types';
import { useNotiflyStore } from 'src/stores/notifly/useNotiflyStore';
import { AddCartType } from '@lib/mixpanel/type';
import { addCartLog } from '@lib/mixpanel/events';

type Clips = {
  id: number;
  godoGoodsNo: number;
};

type Carts = {
  godoGoodsNo: number;
};

export type Notifications = {
  type: string;
  message: string;
};

export type UserInfo = {
  memNo: string;
  userId: string;
  snsType?: string | null;
  isAdult?: boolean;
};
type AccessTokenPayload = {
  username: string;
  sub: string;
  iat: number;
  exp: number;
  snsType?: string | null;
  is_adult?: boolean;
};

export const UserInfoStatusEnum = {
  loading: 'loading',
  unauthenticated: 'unauthenticated',
  authenticated: 'authenticated',
} as const;
export type UserInfoStatus = (typeof UserInfoStatusEnum)[keyof typeof UserInfoStatusEnum];

export const CommonContext = createContext(null);
export const CommonProvider = ({ children, tokenStorageHook, locale }) => {
  const { isNotiflySDKReady } = useNotiflyStore();
  const [clips, setClips] = useState<Clips[]>([]);
  const [carts, setCarts] = useState<Carts[]>([]);
  const [cartsOnSession, setCartsOnSession, cartsOnSessionLoaded, setCartsOnSessionLoaded] = useCartOnSession();
  const [notification, setNotification] = useState<Notifications[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userInfoStatus, setUserInfoStatus] = useState<UserInfoStatus>(UserInfoStatusEnum.loading);
  const [orderSuccess, setOrderSuccess] = useState<Boolean>(false);
  const { authToken, setAuthToken, removeAuthToken } = tokenStorageHook;

  const [addCartGoods] = useMutation(ADD_CART_GOODS);

  const updateUserInfoFromToken = () => {
    const { accessToken } = deserializeAuthToken(authToken);

    const decodeJWT = (token) => {
      try {
        return jwt_decode(token) as AccessTokenPayload;
      } catch (e) {
        return {} as AccessTokenPayload;
      }
    };
    const payload = decodeJWT(accessToken);

    if (payload?.username) {
      // 2시간을 기준으로 갱신
      if (new Date(payload.exp * 1000) < new Date(new Date().getTime() + 1000)) {
        // access token expired
        setUserInfoStatus(UserInfoStatusEnum.loading);
        requestRefreshToken(tokenStorageHook, locale)
          .then(function (response) {
            setAuthToken(serializeAuthToken(response.data.access_token, response.data.refresh_token));
            setUserInfoStatus(UserInfoStatusEnum.authenticated);
          })
          .catch(function (error: EnhancedError) {
            error.extra = Object.assign({}, error.extra, { source: 'provider' });
            captureException(error, {
              extra: error.extra,
            });
            location.href = '/';
            removeAuthToken();
            setUserInfoStatus(UserInfoStatusEnum.unauthenticated);
          });
      } else {
        if (userInfo?.memNo !== payload.sub) {
          const userInfoFromPayload = {
            userId: payload.username,
            memNo: `${payload.sub}`,
            snsType: payload?.snsType,
            isAdult: payload?.is_adult,
          };
          setUserInfo(userInfoFromPayload);
        }

        setUserInfoStatus(UserInfoStatusEnum.authenticated);
      }
    } else {
      // 비회원 (로그인 하지 않은) 상태
      setUserInfoStatus(UserInfoStatusEnum.unauthenticated);
    }
  };

  const updateUserInfo = (authTokenParam: string): UserInfo => {
    const { accessToken } = deserializeAuthToken(authTokenParam);
    const payload = jwt_decode(accessToken) as AccessTokenPayload;

    const userInfoFromPayload = {
      userId: payload.username,
      memNo: `${payload.sub}`,
      snsType: payload?.snsType,
      isAdult: payload?.is_adult,
    };
    setUserInfo(userInfoFromPayload);
    return userInfoFromPayload;
  };

  // 스크랩 정보 가져오기
  const {
    data: clipsData,
    loading: clipsLoading,
    refetch: clipsRefetch,
  } = useGetClipsQuery({
    variables: {
      language: locale,
    },
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  // 장바구니 정보 가져오기
  const {
    data: cartsData,
    loading: cartsLoading,
    refetch: cartsRefetch,
  } = useGetCartsQuery({
    variables: {
      lang: locale,
    },
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  const {
    data: orderProductCardsForCarts,
    previousData: prevOrderProductCardsForCarts,
    loading: orderProductCardsForCartsLoading,
    refetch: orderProductCardsForCartsRefetch,
  } = useOrderProductCardsQuery({
    variables: {
      languageCode: locale,
      productNos: Array.from(new Set([...(cartsData ? cartsData.carts.map((x) => x.goodsNo) : []), ...cartsOnSession])).sort(),
    },
    ssr: false,
    skip: !cartsData?.carts || cartsData.carts.length === 0 || userInfoStatus !== UserInfoStatusEnum.authenticated,
  });

  // 알림 정보 가져오기
  const {
    data: notificationData,
    loading: notiLoading,
    refetch: notiRefetch,
  } = useQuery(GET_NOTIFICATION, {
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  // 캐시 정보 가져오기
  const { data: summaryInfo, refetch: summaryInfoRefetch } = useReactQuery(
    ['GET_USER_SUMMARY', userInfo?.memNo, userInfo?.userId],
    () =>
      godomallInstance
        .get('api/MyPage/GetUserSummary.php', {
          params: {
            memNo: userInfo?.memNo,
            userId: userInfo?.userId,
          },
        })
        .then((res) => res?.data)
        .catch(() => ({})),
    {
      enabled: Boolean(userInfo?.memNo) && Boolean(userInfo?.userId),
    },
  );

  const cashData = Number(summaryInfo?.deposit || 0);

  // 스크랩 추가 함수
  const handleAddClip = (id: number, godoGoodsNo: number) => {
    const isOverlap = clips.some((x) => x.godoGoodsNo === godoGoodsNo);
    if (!isOverlap) {
      setClips([...clips, { id: id, godoGoodsNo: godoGoodsNo }]);
    }
  };

  // 스크랩 제거 함수
  const handleRemoveClip = (godoGoodsNo: number | number[]) => {
    const goodsNos = typeof godoGoodsNo === 'object' ? godoGoodsNo : [godoGoodsNo];
    if (!goodsNos || goodsNos.length === 0) {
      return;
    }
    setClips([...clips.filter((x) => !goodsNos.includes(x.godoGoodsNo))]);
  };

  // 장바구니 추가 함수
  const handleAddCart = async (type: AddCartType, godoGoodsNo: number | number[]) => {
    const goodsNos = typeof godoGoodsNo === 'object' ? godoGoodsNo : [godoGoodsNo];
    if (goodsNos && goodsNos.length > 0) {
      const cartsGoodsNos = carts.map((x) => x.godoGoodsNo);
      setCarts((carts_arg) => [
        ...carts_arg,
        ...goodsNos
          .filter((goodsNo) => !cartsGoodsNos.includes(goodsNo))
          .map((goodsNo) => {
            return { godoGoodsNo: goodsNo };
          }),
      ]);

      addCartLog({
        type,
        goodsNos,
        'Product Nos': goodsNos,
        status: userInfoStatus,
      });
    }
  };

  // 장바구니 제거 함수
  const handleRemoveCart = (godoGoodsNos: number[]) => {
    if (!godoGoodsNos || godoGoodsNos.length === 0) {
      return;
    }
    setCarts([...carts.filter((x) => !godoGoodsNos.includes(x.godoGoodsNo))]);
  };

  // 알림 정보 추가 함수
  const handleAddNotification = (type: string, message?: string) => {
    const isOverlap = notification.some((x) => x.type === type);
    if (!isOverlap) {
      setNotification([...notification, { type: type, message: message }]);
    }
  };

  // 알림 정보 제거 함수
  const handleRemoveNotification = (types: string[]) => {
    if (!types && types.length === 0) {
      return;
    }
    setNotification([...notification.filter((x) => !types.includes(x.type))]);
  };

  useEffect(() => {
    updateUserInfoFromToken();
    if (typeof authToken === 'string') {
      communityApi.configure({ headers: { acon_access_token: authToken.split('//')[0] } });
    }
  }, [authToken]);

  // 30분마다 주기적으로 인증 상태를 확인해서, 만료되었으면 토큰을 갱신
  useInterval(
    () => {
      if (authToken) {
        updateUserInfoFromToken();
      }
    },
    30 * 60 * 1000,
  );
  // 로그인한 회원이 바뀌면 장바구니랑 스크랩 정보 갱신
  useEffect(() => {
    if (userInfoStatus === UserInfoStatusEnum.authenticated) {
      clipsRefetch();
      notiRefetch();
      summaryInfoRefetch();
      if (cartsOnSession.length) {
        let copiedCartsOnSession = [...cartsOnSession];
        Promise.all(
          cartsOnSession.map((goodsNo) =>
            addCartGoods({
              variables: {
                goodsNo: goodsNo.toString(),
              },
              // eslint-disable-next-line no-loop-func
            })
              .then(() => goodsNo)
              .catch(() => null),
          ),
        ).then((result) => {
          setCartsOnSession(copiedCartsOnSession.filter((id) => !result.includes(id)));
          setCartsOnSessionLoaded(true);
          cartsRefetch();
        });
      } else {
        setCartsOnSessionLoaded(true);
        cartsRefetch();
      }
    }
  }, [userInfoStatus, userInfo]);

  // user가 로그인 및 로그아웃 시 notifly 세션 정보를 업데이트해준다.
  useEffect(() => {
    if (typeof window === 'undefined' || !isNotiflySDKReady) return;
    if (userInfoStatus === UserInfoStatusEnum.authenticated) {
      if (userInfo?.memNo) {
        // 리로드를 하더라도 세션 정보값을 조회하면 값이 온다. 문제는 없지만 혹시 값이 없을때를 대비해야할 수도 있음.
        // notiflyController(NotiflyEventHandlers.getUserId, {}).then((res) => console.log(res));
      }
    } else if (userInfoStatus === UserInfoStatusEnum.unauthenticated) {
      notiflyController(NotiflyEventHandlers.userSessionEnd);
    }
  }, [userInfoStatus, userInfo, isNotiflySDKReady]);

  // 스크랩 정보 세팅
  useEffect(() => {
    if (!clipsLoading && clipsData) {
      setClips((clipsData?.clips || []).map((clips: Clips) => ({ id: clips.id, godoGoodsNo: Number(clips.godoGoodsNo) })));
    }
  }, [clipsLoading, clipsData]);

  // 장바구니 개수 정보 세팅
  useEffect(() => {
    if (!cartsLoading && cartsData && !orderProductCardsForCartsLoading && orderProductCardsForCarts) {
      const cartProductIds = (orderProductCardsForCarts?.productCards || []).filter((product) => product && product.onDisplay).map(({ id }) => id);
      setCarts((c) =>
        Array.from(new Set([...c.map((x) => x.godoGoodsNo), ...(cartsData?.carts || []).map((cart) => cart.goodsNo), ...(cartsOnSession || [])]))
          .filter((godoGoodsNo) => cartProductIds.includes(godoGoodsNo))
          .map((godoGoodsNo) => ({ godoGoodsNo })),
      );
    }
  }, [cartsLoading, cartsData, orderProductCardsForCartsLoading, orderProductCardsForCarts]);

  // 알림 정보 세팅
  useEffect(() => {
    if (!notiLoading && notificationData) {
      setNotification(
        (notificationData?.aconNotifications || []).map((noti: Notifications) => ({
          type: noti.type,
          message: noti.message,
        })),
      );
    }
  }, [notiLoading, notificationData]);

  return (
    <CommonContext.Provider
      value={{
        locale,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        userInfoStatus: userInfoStatus,
        setUserInfoStatus: setUserInfoStatus,
        removeAuthToken: removeAuthToken,
        setAuthToken: setAuthToken,
        clips: clips,
        onAddClip: handleAddClip,
        onRemoveClip: handleRemoveClip,
        carts: carts,
        setCarts,
        onAddCart: handleAddCart,
        onRemoveCart: handleRemoveCart,
        cartInfo: {
          data: cartsData,
          loading: cartsLoading,
          refetch: cartsRefetch,
        },
        orderProductCardsInfo: {
          data: orderProductCardsForCarts,
          previousData: prevOrderProductCardsForCarts,
          loading: orderProductCardsForCartsLoading,
          refetch: orderProductCardsForCartsRefetch,
        },
        cartsOnSession,
        setCartsOnSession,
        cartsOnSessionLoaded,
        notification: notification,
        onAddNotification: handleAddNotification,
        onRemoveNotification: handleRemoveNotification,
        notiLoading: notiLoading,
        cashData,
        orderSuccess,
        setOrderSuccess,
        updateUserInfo,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
