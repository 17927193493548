import React from 'react';
import { useCookies } from 'react-cookie';
import { useQuery as useReactQuery } from 'react-query';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { BandBannerContainer, BandBannerItem, BandBannerText } from '@components/_new_components/Common/layout/BottomController/BandBanner/BandBanner.styles';
import { BAND_BANNER_COOKIE_KEY } from '@components/_new_components/Common/layout/TopController/BandBanner/BandBanner.constants';
import { stripParagraphTagParser } from '@util/transform';
import { CloseMobile } from '@components/_new_components/Common/layout/TopController/BandBanner/close';
import { typeBanner } from '@api/banner/banner';
import { IBandDetail, TLanguageCode } from '@api/banner/banner.types';
import { filterNow } from '@components/_new_components/Pages/main/banner/utils';

const Link = ({ href, children, ...props }) =>
  href ? (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  ) : (
    <span {...props}>{children}</span>
  );

export default function BandBanner() {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies();

  const { data } = useReactQuery(['typeBanner', 'band', i18n.language], () => typeBanner<IBandDetail>('band', i18n.language as TLanguageCode), { staleTime: 1000 * 30 });

  const bannerCookie = cookies[BAND_BANNER_COOKIE_KEY] || {};
  const bandBannerData = filterNow<IBandDetail>(data || []).filter((e) => {
    const hideUntil = bannerCookie[e.id]?.hideUntil;
    return !hideUntil || hideUntil < Date.now();
  });

  const genRemoveCallbackByBannerId = (id) => {
    // 밴드 배너 항목을 삭제하는 이벤트 처리기 메소드입니다.
    return (e) => {
      e.preventDefault();
      // 쿠키 만료일자
      const expiry = new Date();
      // 쿠키 만료일자를 오늘일자 YYYY-MM-DDT23:59:59 로 세팅
      expiry.setHours(23, 59, 59, 999);

      bannerCookie[id] = { hideUntil: expiry };
      setCookie(BAND_BANNER_COOKIE_KEY, bannerCookie, { path: '/' });
    };
  };

  const convertColor = (color: string) => {
    if (color && color[0] !== '#') return `#${color}`;
    return color;
  };

  if (bandBannerData.length === 0) return <React.Fragment />;

  return (
    <BandBannerContainer isHide={!data?.length}>
      {bandBannerData.map((banner, idx) => (
        <Link key={idx} href={banner.property.linkHref || '#'} target={banner.property.linkHref} rel="noreferrer" className="top__banner">
          <BandBannerItem color={convertColor(banner.property.bannerBgColor)}>
            <BandBannerText color={convertColor(banner.property.bannerTextColor)} className={banner.property.fontStyle?.join(' ')}>
              <div>{stripParagraphTagParser(banner.property.bannerContents)}</div>
            </BandBannerText>
            <CloseMobile
              buttonColor={convertColor(banner.property.bannerCloseButtonColor)}
              onClick={(e) => {
                genRemoveCallbackByBannerId(banner.id)(e);
              }}
            />
          </BandBannerItem>
        </Link>
      ))}
    </BandBannerContainer>
  );
}
