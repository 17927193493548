export const PREV_CATEGORY_CODES = [
  '329019', // 앵콜런 기획전 페이지
  '329021', // 올라운더 기획전 페이지
  '329022', // 클래스 크리에이터 기획전 페이지
  '097170', // 일문몰 기획전 페이지
];

export const PREV_CATEGORY_CODES_STARTWITH = ['329', '271', '344', '332', '332002', '346', '347', '349', '349001'];

export const ADULT_SEARCH_KEYWORD = [
  { keyword: '성기', lang: 'ko' },
  { keyword: '19금', lang: 'ko' },
  { keyword: '19+', lang: 'ko' },
  { keyword: '음경', lang: 'ko' },
  { keyword: '콘돔', lang: 'ko' },
  { keyword: '질', lang: 'ko' },
  { keyword: '유두', lang: 'ko' },
  { keyword: '젖꼭지', lang: 'ko' },
  { keyword: '니플', lang: 'ko' },
  { keyword: '오르가즘', lang: 'ko' },
  { keyword: '성인용품', lang: 'ko' },
  { keyword: '성인 용품', lang: 'ko' },
  { keyword: '성인물', lang: 'ko' },
  { keyword: '자위기구', lang: 'ko' },
  { keyword: '딜도', lang: 'ko' },
  { keyword: '체위', lang: 'ko' },
  { keyword: '섹스', lang: 'ko' },
  { keyword: '야스', lang: 'ko' },
  { keyword: '개구볼', lang: 'ko' },
  { keyword: '바이브레이터', lang: 'ko' },
  { keyword: '오나홀', lang: 'ko' },
  { keyword: '스팽킹', lang: 'ko' },
  { keyword: '애널', lang: 'ko' },
  { keyword: '애널플러그', lang: 'ko' },
  { keyword: '정조대', lang: 'ko' },
  { keyword: '신음', lang: 'ko' },
  { keyword: 'R-rated', lang: 'en' },
  { keyword: 'Penis', lang: 'en' },
  { keyword: 'penis', lang: 'en' },
  { keyword: 'Dick', lang: 'en' },
  { keyword: 'dick', lang: 'en' },
  { keyword: 'Adult Material', lang: 'en' },
  { keyword: 'AdultMaterial', lang: 'en' },
  { keyword: 'Adult Products', lang: 'en' },
  { keyword: 'AdultProducts', lang: 'en' },
  { keyword: 'Adult Only', lang: 'en' },
  { keyword: 'AdultOnly', lang: 'en' },
  { keyword: 'adult material', lang: 'en' },
  { keyword: 'adultmaterial', lang: 'en' },
  { keyword: 'adult products', lang: 'en' },
  { keyword: 'adultproducts', lang: 'en' },
  { keyword: 'adult only', lang: 'en' },
  { keyword: 'adultonly', lang: 'en' },
  { keyword: 'Sex', lang: 'en' },
  { keyword: 'sex', lang: 'en' },
  { keyword: 'Condom', lang: 'en' },
  { keyword: 'condom', lang: 'en' },
  { keyword: 'Vagina', lang: 'en' },
  { keyword: 'vagina', lang: 'en' },
  { keyword: 'Annal', lang: 'en' },
  { keyword: 'annal', lang: 'en' },
  { keyword: 'Dildo', lang: 'en' },
  { keyword: 'dildo', lang: 'en' },
  { keyword: 'Spanking', lang: 'en' },
  { keyword: 'spanking', lang: 'en' },
  { keyword: 'Onahole', lang: 'en' },
  { keyword: 'onahole', lang: 'en' },
  { keyword: 'Vibrator', lang: 'en' },
  { keyword: 'vibrator', lang: 'en' },
  { keyword: 'Gag Ball', lang: 'en' },
  { keyword: 'gag ball', lang: 'en' },
  { keyword: 'SexToy', lang: 'en' },
  { keyword: 'Sex Toy', lang: 'en' },
  { keyword: 'Orgasmic', lang: 'en' },
  { keyword: 'Orgasm', lang: 'en' },
  { keyword: 'orgasmic', lang: 'en' },
  { keyword: 'orgasm', lang: 'en' },
  { keyword: 'Nipples', lang: 'en' },
  { keyword: 'nipples', lang: 'en' },
  { keyword: 'Genitals', lang: 'en' },
  { keyword: 'genitals', lang: 'en' },
  { keyword: 'Perineum', lang: 'en' },
  { keyword: 'perineum', lang: 'en' },
  { keyword: 'ジル', lang: 'ja' },
  { keyword: '乳頭', lang: 'ja' },
  { keyword: 'アダルトグッズ', lang: 'ja' },
  { keyword: '19金', lang: 'ja' },
  { keyword: '陰茎', lang: 'ja' },
  { keyword: 'ペニス', lang: 'ja' },
  { keyword: '性器', lang: 'ja' },
  { keyword: 'まんこ', lang: 'ja' },
  { keyword: 'マンコ', lang: 'ja' },
  { keyword: 'ちんちん', lang: 'ja' },
  { keyword: 'チンポ', lang: 'ja' },
  { keyword: 'チンコ', lang: 'ja' },
  { keyword: 'コンドーム', lang: 'ja' },
  { keyword: '質', lang: 'ja' },
  { keyword: 'アダルト', lang: 'ja' },
  { keyword: 'オルガズム', lang: 'ja' },
  { keyword: '音経', lang: 'ja' },
  { keyword: 'セックス', lang: 'ja' },
  { keyword: 'ディルド', lang: 'ja' },
  { keyword: 'オナニー用品', lang: 'ja' },
  { keyword: 'オナニー', lang: 'ja' },
  { keyword: '開口ボール', lang: 'ja' },
  { keyword: '拘束口', lang: 'ja' },
  { keyword: 'バイブレータ', lang: 'ja' },
  { keyword: '性交', lang: 'ja' },
  { keyword: '性交体位', lang: 'ja' },
  { keyword: 'オナホール', lang: 'ja' },
  { keyword: 'スパンキン', lang: 'ja' },
  { keyword: 'アナル', lang: 'ja' },
];
