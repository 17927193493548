import React from 'react';
import styled from '@emotion/styled';

const GnbScrapIconWrapper = styled.div`
  position: relative;

  & > .small {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    & > .large {
      display: none;
    }

    & > .small {
      display: block;
    }
  }
`;

export default function GnbScrap({ className = '' }) {
  return (
    <GnbScrapIconWrapper className={`${className}`}>
      <div className={'large'}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3031_82089)">
            <path d="M6 4H18V20L12 16.3333L6 20V4Z" stroke="#313135" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_3031_82089">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className={'small'}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3159_109962)">
            <path d="M5.5 3.66602H16.5V18.3327L11 14.9716L5.5 18.3327V3.66602Z" stroke="#313135" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_3159_109962">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </GnbScrapIconWrapper>
  );
}
