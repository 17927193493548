import { useCookies } from 'react-cookie';
import { create } from 'zustand';
import { Industry } from '../../constants/industry.constants';
import { IndustryStoreType } from './useIndustryStore.type';

const useIndustryStore = create<IndustryStoreType>()((set) => ({
  isReadyInitialized: false,
  setIsReadyInitialized: (isReady: boolean) => set(() => ({ isReadyInitialized: isReady })),
  industry: Industry.ALL,
  setIndustry: (value: Industry) => set(() => ({ industry: value })),
}));

export const useIndustry = () => {
  const [cookie, setCookie, removeCookie] = useCookies();
  const [isReadyInitialized, setIsReadyInitialized, industry, setIndustry] = useIndustryStore((state) => [
    state.isReadyInitialized,
    state.setIsReadyInitialized,
    state.industry,
    state.setIndustry,
  ]);

  const initializeIndustry = () => {
    const industryCookie = cookie.industry;
    if (industryCookie) setIndustry(industryCookie);
    setIsReadyInitialized(true);
  };

  const changeIndustryCookie = (value: Industry) => {
    if (value !== Industry.ALL) setCookie('industry', value, { domain: '.acon3d.com', path: '/' });
    if (value === Industry.ALL) removeCookie('industry', { domain: '.acon3d.com', path: '/' });
  };

  const changeIndustry = (value: Industry) => {
    if (value === industry) return;
    changeIndustryCookie(value);
    setIndustry(value);
  };

  const reloadPageOnIndustryChange = (value: Industry) => {
    changeIndustryCookie(value);
    location.href = value === Industry.ALL ? '/' : `/?industry=${value}`;
  };

  return {
    isReadyInitialized,
    industry,
    changeIndustry,
    reloadPageOnIndustryChange,
    initializeIndustry,
  };
};
