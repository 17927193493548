import React, { ComponentPropsWithRef } from 'react';
import { CustomText } from '@components/ui/Text';
import * as Styled from './styled';

type Props = ComponentPropsWithRef<typeof Styled.DarkGrayBorderButton> & {children: React.ReactNode};

export const LightButton: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Styled.DarkGrayBorderButton type="button" {...rest}>
      <CustomText weight={500}>{children}</CustomText>
    </Styled.DarkGrayBorderButton>
  );
};
