import React from 'react';
import { TIndustryButtonIconProps } from './IndustryButtonIcon.type';
import { IndustryIcons } from './IndustryButtonIcon.constants';
import { IndustryButtonIconWrapper } from './IndustryButtonIcon.styles';

export default function IndustryButtonIcon({ className, language, industry, ...rest }: TIndustryButtonIconProps) {
    return (
        <IndustryButtonIconWrapper className={className} {...rest}>
            {IndustryIcons[language][industry]}
        </IndustryButtonIconWrapper>
    );
}
