import { IMiddlewareRewritePath } from '../types/common.types';

/**
 * ALL을 제외한 나머지 industry value는 Pages 폴더 하위에 생성한 폴더명과 동일하게 작성해주세요.
 * middleware에서 아래 industry를 읽어서 쿠키 설정 및 rewrite를 진행합니다.
 * ex) Industry.GAME = pages/game/~, Industry.ADD = pages/add/~
 */
export enum Industry {
    ALL = 'all',
    GAME = 'game',
}

// 전체보기 제외한 industry 배열
export const industryValues = Object.values(Industry).filter(industry => industry !== Industry.ALL);

/**
 * industry: 쿠키에 저장 및 pages/{industry}로 rewrite를 진행할 industry
 * requestPath: 요청 경로
 * rewritePath: requestPath를 rewrite할 경로
 */
export const MIDDLEWARE_INDUSTRY_REWRITE_PATH: IMiddlewareRewritePath[] = [
    {
        industry: Industry.GAME,
        requestPath: '/',
        rewritePath: '/',
    },
];
