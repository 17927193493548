import apiBase from '@api/apiBase';
import { LanguageCodeEnum } from '../../generated/graphql';
import {
  IArticleVisitProps,
  IGetArticlesProps,
  IGetArticlesReturn,
  IGetCommentsProps,
  IGetCommentsReturn,
  IGetNoticeCommentsProps,
  IGetNoticeProps,
  IGetNoticeReturn,
  IGetNoticesReturn,
  IGetPostProps,
  IGetPostReturn,
  IGetPostTagsProps,
  IGetPostTagsReturn,
  IGetTemporaryArticlesProps,
  ILikeArticleProps,
  ILikeNoticeProps,
  IModifyReplyProps,
  INoticeVisitProps,
  INoticeWriteCommentProps,
  IPostData,
  IPublishId,
  IRemoveArticleProps,
  IRemoveReplyProps,
  IReplyLikeProps,
  IReportArticleProps,
  IReportCommentProps,
  ISearchArticlesProps,
  IWriteCommentProps,
  IWriteCommentReturn,
} from './community.type';

const COMMUNITY_SERVER_URL = process.env.COMMUNITY_URL || process.env.NEXT_PUBLIC_COMMUNITY_URL;
const COMMUNITY_API_KEY = process.env.COMMUNITY_API_KEY || process.env.NEXT_PUBLIC_COMMUNITY_API_KEY;
export const BOARD_ID = 1;
export const ARTICLES_PAGE_SIZE = 30;
export const BEST_ARTICLES_PAGE_SIZE = 10;
export const SEARCH_ARTICLES_PAGE_SIZE = ARTICLES_PAGE_SIZE + BEST_ARTICLES_PAGE_SIZE;
export const COMMENT_PAGE_SIZE = 30;

const AUTHORIZATION_TOKEN = `Bearer ${COMMUNITY_API_KEY}`;

export const communityApi = apiBase({
  baseURL: COMMUNITY_SERVER_URL,
  headers: {
    Authorization: AUTHORIZATION_TOKEN,
  },
});

/**
 * 커뮤니티 게시글 상세 내용을 가져오는 API 입니다.
 */
export const getPost = async ({ postId }: IGetPostProps): Promise<IGetPostReturn> => {
  return await communityApi.get(`/api/articles/${postId}`).then(({ data }) => data);
};

/**
 * 특정 공지사항 상세내용 가져오기
 * @param noticeId 공지사항 Id
 */
export const getNotice = async ({ noticeId }: IGetNoticeProps): Promise<IGetNoticeReturn> => {
  return await communityApi.get(`/api/notices/${noticeId}`).then(({ data }) => data);
};

/**
 * 커뮤니티 게시글의 댓글 목록을 가져오는 API 입니다.
 * @param postId 게시글의 id
 * @param page 댓글 페이지네이션 페이지
 */
export const getComments = async ({ postId, page }: IGetCommentsProps): Promise<IGetCommentsReturn> => {
  return await communityApi.get('/api/article-replies', {
    params: {
      sort: 'id:DESC',
      locale: LanguageCodeEnum.Ko,
      'pagination[page]': page,
      'pagination[pageSize]': COMMENT_PAGE_SIZE,
      'filters[article][id][$eq]': postId,
      'filters[$and][0][is_block][$eq]': false,
    },
  });
};
/**
 * 특정 공지사항 댓글목록 가져오기
 * @param noticeId 공지사항 id
 * @param page 댓글 페이지네이션 페이지
 */
export const getNoticeComments = async ({ noticeId, page }: IGetNoticeCommentsProps): Promise<IGetCommentsReturn> => {
  return await communityApi.get(
    `/api/notice-replies?sort=id:DESC&filters[notice][id][$eq]=${noticeId}&locale=ko&pagination[page]=${page}&pagination[pageSize]=${COMMENT_PAGE_SIZE}&filters[$and][0][is_block][$eq]=false`,
  );
};

/**
 * 게시판 내 게시물 목록 가져오기
 * @param page 페이지네이션 페이지
 */
export const getArticles = async ({ page }: IGetArticlesProps): Promise<IGetArticlesReturn> => {
  return await communityApi.get(
    `/api/articles?sort=id:DESC&filters[board][id][$eq]=${BOARD_ID}&filters[$and][0][is_block][$eq]=false&locale=ko&pagination[page]=${page}&pagination[pageSize]=${30}&pagination[withCount]=true&populate[1]=article_replies`,
  );
};

/**
 * 커뮤니티 게시판 내 베스트 게시물 목록 가져오기
 */
export const getBestArticles = async (): Promise<IGetArticlesReturn> => {
  // 베스트 게시물의 경우 일주일 이내에 작성된 게시물만 상단에 노출
  const curr = new Date();
  curr.setDate(curr.getDate() - 7);
  curr.setHours(0, 0, 0, 0);
  const dateLimit = curr.toISOString();

  return await communityApi.get('/api/articles', {
    params: {
      sort: 'likes:desc,id:desc',
      locale: LanguageCodeEnum.Ko,
      'filters[board][id][$eq]': BOARD_ID,
      'filters[$and][0][likes][$gte]': 10,
      'filters[$and][0][is_block][$eq]': 'false',
      'pagination[page]': 1,
      'pagination[pageSize]': BEST_ARTICLES_PAGE_SIZE,
      'pagination[withCount]': true,
      'populate[1]': 'article_replies',
      'filters[$and][0][createdAt][$gt]': dateLimit,
    },
  });
};

/**
 * 공지사항 목록 가져오기
 */
export const getNotices = async (): Promise<IGetNoticesReturn> => {
  return await communityApi.get('/api/notices?locale=ko&populate[1]=notice_replies');
};

/**
 * 게시물 keyword로 검색하기
 * @param keyword 검색 Keyword
 * @param page 페이지네이션 페이지
 * @param isTag 태그 검색인지 아닌지
 */
export const searchArticles = async ({ keyword, page, isTag }: ISearchArticlesProps): Promise<IGetArticlesReturn> => {
  return await communityApi.get(
    `/api/articles?sort=id:DESC&locale=ko&pagination[page]=${page}&pagination[pageSize]=${SEARCH_ARTICLES_PAGE_SIZE}&pagination[withCount]=true&${
      isTag ? 'filters[$and][0][tags][tag][$contains]=' : '_q='
    }${keyword}&populate[1]=article_replies`,
  );
};

/**
 * 작성중인 임시저장 게시물 가져오기
 *
 * @param memNo 멤버넘버
 * @param locale 언어
 * @param boardId boardId 게시판 id
 */
export const getTemporaryArticles = async ({ memNo, locale = LanguageCodeEnum.Ko }: IGetTemporaryArticlesProps): Promise<IGetArticlesReturn> => {
  return await communityApi.get('/api/articles', {
    params: {
      sort: 'id:DESC',
      'filters[$and][0][acon_member_no][$eq]': memNo,
      locale: locale,
      'pagination[page]': 1,
      'pagination[pageSize]': 5,
      'pagination[withCount]': true,
      publicationState: 'preview',
      'populate[1]': 'article_replies',
      'filters[$and][0][publishedAt][$null]': true,
    },
  });
};

/**
 * 게시물의 태그 가져오는 API 입니다.
 */
export const getPostTags = async ({ postId }: IGetPostTagsProps): Promise<IGetPostTagsReturn[]> => {
  return await communityApi.get(`/api/tags?filters[articles][id][$contains]=${postId}`).then(({ data }) => data);
};

/**
 * 게시물 신고 API 입니다.
 */
export const reportArticle = async ({ article, reason = '' }: IReportArticleProps) => {
  await communityApi.mutation({
    operation: 'post',
    url: '/api/article-reports',
    data: {
      article: article,
      reason: reason,
    },
  });
};

/**
 * 게시물 게시 API
 */
export const publishPost = async ({ postId }: IPublishId): Promise<any> => {
  return await communityApi.mutation({
    operation: 'patch',
    url: `/api/articles/${postId}/publish`,
  });
};

/**
 * 게시물 댓글 신고 API 입니다.
 */
export const reportComment = async ({ articleReply, reason = '' }: IReportCommentProps) => {
  await communityApi.mutation({
    operation: 'post',
    url: '/api/article-reply-reports',
    data: {
      article_reply: articleReply,
      reason: reason,
    },
  });
};
/**
 * 공지사항 댓글 신고 api
 * @param articleReply 댓글 id
 * @param reason 신고 사유
 */
export const noticeReportComment = async ({ articleReply, reason = '' }: IReportCommentProps) => {
  await communityApi.mutation({
    operation: 'post',
    url: '/api/notice-reply-reports',
    data: {
      notice_reply: articleReply,
      reason: reason,
    },
  });
};

/**
 * 게시물 댓글 작성 API 입니다.
 */
export const writeComment = async ({ postId, contents, nickname }: IWriteCommentProps): Promise<IWriteCommentReturn> => {
  return await communityApi
    .mutation({
      operation: 'post',
      url: '/api/article-replies',
      data: {
        article: postId,
        contents: contents,
        nickname: nickname,
      },
    })
    .then(({ data }) => data);
};

/**
 * 공지사항 댓글 작성 API
 * @param noticeId 공지사항 id
 * @param contents 댓글 내용
 * @param nickname 댓글 작성자 닉네임
 */
export const noticeWriteComment = async ({ noticeId, contents, nickname }: INoticeWriteCommentProps): Promise<IWriteCommentReturn> => {
  return await communityApi
    .mutation({
      operation: 'post',
      url: '/api/notice-replies',
      data: {
        notice: noticeId,
        contents: contents,
        nickname: nickname,
      },
    })
    .then(({ data }) => data);
};

/**
 * 게시물에 좋아요 api 입니다.
 * @param postId 게시물 id
 */
export const likeArticle = async ({ postId }: ILikeArticleProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/articles/${postId}/like` });
};
/**
 * 게시물 좋아요 취소 api 입니다.
 */
export const unlikeArticle = async ({ postId }: ILikeArticleProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/articles/${postId}/unlike` });
};

/**
 * 공지사항 좋아요 api입니다.
 * @param noticeId 공지사항 id
 */
export const likeNotice = async ({ noticeId }: ILikeNoticeProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/notices/${noticeId}/like` });
};

/**
 * 공지사항 좋아요 취소 api입니다.
 * @param noticeId 공지사항 id
 */
export const unlikeNotice = async ({ noticeId }: ILikeNoticeProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/notices/${noticeId}/unlike` });
};

/**
 * 게시물 조회 수 증가 api 입니다.
 * @param postId 게시물 id
 */
export const articleVisit = async ({ postId }: IArticleVisitProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/articles/${postId}/visit` });
};

/**
 * 공지사항 조회 수 증가 api 입니다.
 * @param postId 공지사항 id
 */
export const noticeVisit = async ({ noticeId }: INoticeVisitProps) => {
  await communityApi.mutation({ operation: 'patch', url: `/api/notices/${noticeId}/visit` });
};

/**
 * 글 삭제 api 입니다.
 */
export const removeArticle = async ({ postId }: IRemoveArticleProps) => {
  await communityApi.mutation({ operation: 'delete', url: `/api/articles/${postId}` });
};

/**
 *
 * 미디어 업로드 api
 *
 * @param file
 */
export const uploadImage = async (file: File) => {
  const form = new FormData();
  form.append('files', file);
  return await communityApi.api.post('/api/upload', form);
};

/**
 * 글 쓰기
 *
 * @param payload
 */
export const savePost = async (payload: IPostData) => {
  return await communityApi.mutation({
    operation: 'post',
    url: '/api/articles',
    data: payload,
  });
};

/**
 * 게시물 댓글 좋아요 api 입니다.
 */
export const likeReply = async ({ replyId }: IReplyLikeProps) => {
  await communityApi.mutation({
    operation: 'patch',
    url: `/api/article-replies/${replyId}/like`,
  });
};

/**
 * 공지사항 댓글 좋아요 api
 * @param replyId 댓글 id
 */
export const noticeLikeReply = async ({ replyId }: IReplyLikeProps) => {
  await communityApi.mutation({
    operation: 'patch',
    url: `/api/notice-replies/${replyId}/like`,
  });
};

/**
 * 게시물 댓글 좋아요 취소 api 입니다.
 */
export const unlikeReply = async ({ replyId }: IReplyLikeProps) => {
  await communityApi.mutation({
    operation: 'patch',
    url: `/api/article-replies/${replyId}/unlike`,
  });
};

/**
 * 공지사항 댓글 좋아요 취소 api
 * @param replyId 댓글 id
 */
export const noticeUnlikeReply = async ({ replyId }: IReplyLikeProps) => {
  await communityApi.mutation({
    operation: 'patch',
    url: `/api/notice-replies/${replyId}/unlike`,
  });
};

/**
 * 댓글 삭제 api 입니다.
 */
export const removeReply = async ({ replyId }: IRemoveReplyProps) => {
  await communityApi.mutation({
    operation: 'delete',
    url: `/api/article-replies/${replyId}`,
  });
};
/**
 * 공지사항 댓글 삭제 api
 * @param replyId 댓글 id
 */
export const noticeRemoveReply = async ({ replyId }: IRemoveReplyProps) => {
  await communityApi.mutation({
    operation: 'delete',
    url: `/api/notice-replies/${replyId}`,
  });
};
/**
 * 게시물 댓글 수정 api 입니다.
 */
export const modifyReply = async ({ replyId, contents, nickname }: IModifyReplyProps) => {
  await communityApi.mutation({
    operation: 'put',
    url: `/api/article-replies/${replyId}`,
    data: {
      contents: contents,
      nickname: nickname,
    },
  });
};

/**
 * 공지사항 댓글 수정 API
 * @param replyId 댓글 id
 * @param contents 댓글 내용
 * @param nickname 작성자 닉네임
 */
export const noticeModifyReply = async ({ replyId, contents, nickname }: IModifyReplyProps) => {
  await communityApi.mutation({
    operation: 'put',
    url: `/api/notice-replies/${replyId}`,
    data: {
      contents: contents,
      nickname: nickname,
    },
  });
};

/**
 * 글 수정
 *
 * @param payload
 * @param postId
 */
export const modifyPost = async (payload: IPostData, postId: number) => {
  return await communityApi.mutation({
    operation: 'put',
    url: `/api/articles/${postId}`,
    data: payload,
  });
};
