import styled from '@emotion/styled';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnAlignCenter = styled(FlexColumn)`
  align-items: center;
`;

export const FlexRow = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props?.gap &&
    `
    gap: ${props.gap}px;
  `}
`;

export const FlexRowAlignBottom = styled(FlexRow)`
  align-items: flex-end;
`;

export const FlexRowAlignCenter = styled(FlexRow)`
  align-items: center;
`;

export const FlexWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const FlexCenterOneItem = styled(FlexRowAlignCenter)`
  justify-content: center;
`;

export const HoverItem = styled.span`
  cursor: pointer;
  transition: opacity 0.1s;
  display: inherit;
  align-items: inherit;
  color: #313135;
  &:last-child {
    margin-right: 20px;
  }
  @media screen and (max-width: 768px) {
    color: #88888a;
  }
  & a {
    color: inherit;
  }
  &:hover > * > * {
    @media screen and (min-width: 769px) {
      opacity: 0.4;
    }
    @media screen and (max-width: 768px) {
      color: #313135;
    }
  }
`;
