import styled from '@emotion/styled';

export const ScrollTopContainer = styled.button<{ showTopButton: boolean; showDeviceSizes: ('mobile' | 'tablet' | 'desktop')[] }>`
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
  border: 0.5px solid #ffffff;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  transition: bottom 0.5s ease-in-out;
  will-change: bottom;
  display: none;
  margin: 0 14px 14px 0;

  & .mobile-icon {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    margin: 0 8px 8px 0;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    margin: 0 4px 4px 0;
    width: 42px;
    height: 42px;

    & .desktop-icon {
      display: none;
    }

    & .mobile-icon {
      display: block;
    }
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    margin: 0;
  }

  ${(props) =>
    props.showTopButton &&
    `
    ${
      props.showDeviceSizes.includes('desktop') &&
      `
      ${props.theme.breakpoints.up('dt_1440')} {
        display: flex;
      }
    `
    }
  
    ${
      props.showDeviceSizes.includes('tablet') &&
      `
      ${props.theme.breakpoints.between('st_600', 'dt_1440')} {
        display: flex;
      }
    `
    }
  
    ${
      props.showDeviceSizes.includes('mobile') &&
      `
      ${props.theme.breakpoints.down('st_600')} {
        display: flex;
      }
    `
    }
  `};
`;
