export const EVENT_NAME = {
  PRODUCT_CLICK: 'Product Click',
  SHARE_VIEW: 'Share View',
  PRODUCT_HISTORY: 'Product History',
  USE_FILTER: 'Use Filter',
  SORT_ORDER: 'Sort Order',
  SCRAP: 'Scrap',
  ADD_CART_FROM_SCRAP: 'Add Cart From Scrap',
  BUY_CART: 'Buy Cart',
  CHECKOUT: 'Checkout',
  DIRECT_BUY: 'Direct Buy',
  GNB_CLICK: 'GNB Click',
  BUTTON_CLICK: 'Button Click',
  TAG_CLICK: 'Tag Click',
  ADD_CART: 'Add Cart',
  ADULT_PRODUCT_TOGGLE: 'Adult Product Toggle',
  PRODUCT_DETAIL_LANDING: 'Product Detail Landing',
};

export const AddCartTypes = {
  SCRAP_EDIT: 'scrap - edit',
  SCRAP_CARD: 'scrap - card',
  PRODUCT_DETAIL: 'product detail',
} as const;

export const MIX_PANEL_METHOD_MESSAGE = {
  brand: 'Search Suggestion on Brands',
  keywords: 'Search Suggestion on Related Searches',
  popular: 'Current Popular Searches',
};
