import React from 'react';

interface Props {
  isActive: boolean;
}

export default function HomeIcon({ isActive }: Props) {
  return (
    <>
      {isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M4 12H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M4 18H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
      )}

      {!isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M4 12H20" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M4 18H20" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
      )}
    </>
  );
}
