interface INewAddIconProps {
  size?: number;
  color?: string;
}

export const NewAddIcon = ({ size = 24, color = 'black' }: INewAddIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.14286 6.14286V0.857144C6.14286 0.383757 6.52661 0 7 0C7.47339 0 7.85714 0.383756 7.85714 0.857143V6.14286H13.1429C13.6162 6.14286 14 6.52661 14 7C14 7.47339 13.6162 7.85714 13.1429 7.85714H7.85714V13.1429C7.85714 13.6162 7.47339 14 7 14C6.52661 14 6.14286 13.6162 6.14286 13.1429V7.85714H0.857144C0.383757 7.85714 0 7.47339 0 7C0 6.52661 0.383756 6.14286 0.857143 6.14286H6.14286Z"
        fill={color}
      />
    </svg>
  );
};
