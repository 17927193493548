import React from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'react-use';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import { useSearchAutoCompleteOutputType } from '@hooks/search/useSearchAutoComplete.types';
import { searchAutoComplete } from '@api/artemis/artemis';
import { getLastTimeOfDay, getMidnightTodayDate } from '@hooks/search/useSearches.utils';
import { useSearchKeywordStore } from '../../stores/searchKeyword/useSearchKeyword.store';
import { autoCompleteQueryDataKeys, entriesAutoCompleteQueryOutputType } from '../../types/search/Search.types';
import { LanguageCodeEnum } from '../../generated/graphql';

const getConvertDataValueByType = (key: autoCompleteQueryDataKeys, item) => {
  if (key === 'brand') return item.code;
  if (key === 'keywords') return item;
};

export default function useSearchAutoComplete(): useSearchAutoCompleteOutputType {
  const { i18n } = useTranslation([]);

  const [activeItemIndex, setActiveItemIndex] = React.useState<number>(-1);
  const [keyword, setKeyword] = useSearchKeywordStore((state) => [state.searchKeyword, state.setSearchKeyword]);
  const [debounceKeyword, setDebounceKeyword] = React.useState<string>(keyword);

  const untilDateRef = React.useRef<Date>(getLastTimeOfDay());
  const sinceDateRef = React.useRef<Date>(dayjs(getMidnightTodayDate()).subtract(3, 'month').toDate());

  const { data: autoCompleteQueryData, isFetching } = useQuery(
    ['search', 'autoComplete', debounceKeyword],
    () =>
      searchAutoComplete({
        language: i18n.language as LanguageCodeEnum,
        keyword: debounceKeyword,
        since: sinceDateRef.current.toISOString(),
        until: untilDateRef.current.toISOString(),
      }),
    {
      staleTime: 1000 * 60,
      keepPreviousData: true,
      enabled: Boolean(debounceKeyword),
      retry: false,
      placeholderData: {
        data: {
          brand: [],
          keywords: [],
        },
      },
    },
  );

  useDebounce(
    () => {
      if (!isFetching) setDebounceKeyword(keyword);
    },
    500,
    [keyword, isFetching],
  );

  const autoCompleteData = Object.entries(autoCompleteQueryData?.data || {}).filter(([_, value]) => value.length > 0) as entriesAutoCompleteQueryOutputType;
  const convertData = autoCompleteData.map(([key, value]) => value.map((item) => ({ type: key, value: getConvertDataValueByType(key, item) }))).flat(1);

  const totalCount = convertData.length;
  const activeItem = activeItemIndex > -1 ? convertData[activeItemIndex] : null;

  const increaseActiveItemIndex = () => {
    setActiveItemIndex((prev) => (prev + 1 === totalCount ? 0 : prev + 1));
  };

  const decreaseActiveItemIndex = () => {
    setActiveItemIndex((prev) => (prev - 1 < 0 ? totalCount - 1 : prev - 1));
  };

  const changeSearchKeyword = () => {
    if (activeItem.type === 'keywords') setKeyword(activeItem.value);
  };

  const handleAutoCompleteItemClick = (type: autoCompleteQueryDataKeys, keyword: string) => {
    if (type === 'keywords') setKeyword(keyword);
  };

  React.useEffect(() => {
    setActiveItemIndex(-1);
  }, [keyword]);

  return {
    data: autoCompleteData,
    debounceKeyword: debounceKeyword,
    isEmptyData: totalCount === 0,
    activeItem,
    increaseActiveItemIndex,
    decreaseActiveItemIndex,
    changeSearchKeyword,
    handleAutoCompleteItemClick,
  };
}
