import React, { ReactNode } from 'react';
import { Head } from '@components/layout/Head';
import { LoadingScreen } from '@components/common/LoadingScreen';

type Props = {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogTwitterTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImageOriginal?: boolean;
  ogImageUrl?: string;
  ogTwitterCardSize?: 'summary' | 'summary_large_image' | 'app' | 'player';
  className?: string;
  children?: ReactNode;
  onClick?: (...args: any) => void;
  isLoading?: boolean;
  style?: React.CSSProperties;
};

const Page: React.FC<Props> = ({
  children,
  title = '',
  description = '',
  ogTitle = '',
  ogTwitterTitle = '',
  ogDescription = '',
  ogUrl = '',
  ogImageOriginal = false,
  ogImageUrl = '',
  ogTwitterCardSize = '',
  isLoading = false,
  ...other
}) => {
  return (
    <>
      <Head
        title={title}
        description={description}
        ogTitle={ogTitle}
        ogTwitterTitle={ogTwitterTitle}
        ogDescription={ogDescription}
        ogImageOriginal={ogImageOriginal}
        ogImageUrl={ogImageUrl}
        ogUrl={ogUrl}
        ogTwitterCardSize={ogTwitterCardSize}
      />
      <div {...other}>{children}</div>
      <LoadingScreen isDisplay={isLoading} />
    </>
  );
};

export default Page;
