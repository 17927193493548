import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { numberWithCommas } from '@lib';
import useExchange from '@hooks/useExchange';
import { CustomText } from '@components/ui/Text';
import { LanguageCode } from '../../../../../../src/constants';

const CashIndicatorConatiner = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  border: 1px solid #e7e7e8;
  height: 28px;

  &::before {
    content: '';
    display: flex;
    width: 14px;
    height: 14px;
    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8.51666 4.20004H5.48332L4.19999 9.80003H5.94999L6.06665 8.75004H7.93332L8.04999 9.80004L9.79999 9.80003L8.51666 4.20004ZM7.38888 5.83338H6.6111L6.28054 7.81671H7.71943L7.38888 5.83338Z" fill="%23FFA800"/></svg>');
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    height: 26px;
    padding: 0 7px;
  }
`;

export default function CashIndicator({ myCash }: { myCash: number }) {
  const { i18n } = useTranslation();
  const { ex, isReady } = useExchange(i18n.language as LanguageCode);

  return (
    <CashIndicatorConatiner>
      <CustomText size={14} lineHeight={'20px'} weight={700} color={'#000000'}>
        {isReady ? numberWithCommas(ex(myCash).display.amount) : 0}
      </CustomText>
    </CashIndicatorConatiner>
  );
}
