import styled from '@emotion/styled';

export const SearchAutoCompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SearchAutoCompleteSection = styled.div``;

export const SearchAutoCompleteSectionTitle = styled.div`
  padding: 10px 8px 6px 8px;
`;

export const SearchAutoCompleteSectionItem = styled.div<{ isActive: boolean }>`
  padding: 8px;

  ${(props) => props.isActive && 'background-color: #fafafa;'}

  &:hover {
    background-color: #fafafa;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.04);
`;
