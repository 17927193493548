import { getUtcDate } from '@util/time';

export const getUtcCurrentDate = () => {
  const utcDate = getUtcDate();
  utcDate.setUTCSeconds(0);
  utcDate.setUTCMilliseconds(0);
  return utcDate;
};

export const getLastTimeOfDay = () => {
  const currentDate = getUtcDate();
  currentDate.setUTCHours(23);
  currentDate.setMinutes(59);
  currentDate.setUTCSeconds(59);
  currentDate.setMilliseconds(999);
  return currentDate;
};

export const getMidnightTodayDate = (): Date => {
  const utcCurrentDate = getUtcCurrentDate();
  utcCurrentDate.setUTCMinutes(0);
  utcCurrentDate.setUTCHours(0);
  return utcCurrentDate;
};

export const getBaseDateTime = (): Date => {
  const utcCurrentDate = getUtcCurrentDate();
  utcCurrentDate.setUTCMinutes(0);
  return utcCurrentDate;
};

export const getPrevBaseDateTime = (): Date => {
  const utcBaseDateTime = getBaseDateTime();
  utcBaseDateTime.setUTCHours(utcBaseDateTime.getUTCHours() - 1);
  return utcBaseDateTime;
};
