import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { ILayoutStatus, ISnackBar, ISnackbarOptions, ScrollTopType } from './useLayout.type';

const initialScrollTopState = {
  showButton: true,
  showDeviceSizes: ['mobile', 'tablet'],
  showButtonThreshold: 0,
  offsetTop: 0,
} satisfies ScrollTopType;

export const useLayoutStore = createWithEqualityFn<ILayoutStatus>()(
  (set) => ({
    fixedButtonsBottom: null,
    setFixedButtonsBottom: (bottom: number) => set(() => ({ fixedButtonsBottom: bottom })),
    scrollTop: initialScrollTopState,
    setScrollTop: (scrollTop: ScrollTopType) =>
      set(() => ({
        scrollTop: {
          showButton: scrollTop.showButton,
          ...(scrollTop?.showDeviceSizes && { showDeviceSizes: scrollTop.showDeviceSizes }),
          ...(scrollTop?.showButtonThreshold && { showButtonThreshold: scrollTop.showButtonThreshold }),
          ...(scrollTop?.offsetTop && { offsetTop: scrollTop.offsetTop }),
          ...(scrollTop?.target && {
            target: {
              ref: scrollTop.target.ref,
              position: scrollTop.target?.position || 'top',
            },
          }),
        },
      })),
    resetScrollTop: () => set({ scrollTop: initialScrollTopState }),
    snackbar: [],
    setSnackbar: (snackbar: ISnackBar[] | ((snackbar: ISnackBar[]) => ISnackBar[])) => {
      if (typeof snackbar === 'function') {
        set((states) => ({ snackbar: snackbar(states.snackbar) }));
      } else {
        set({ snackbar: snackbar });
      }
    },
    isReadyBandBanner: false,
    setIsReadyBandBanner: (isReady: boolean) => set(() => ({ isReadyBandBanner: isReady })),
  }),
  shallow,
);

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useLayoutStore((state) => [state.snackbar, state.setSnackbar]);

  const checkOverlapMessage = (message: string, storeSnackbar: ISnackBar[]) => {
    return storeSnackbar.some((item) => item.message === message);
  };

  const makeSnackbar = (message: string, options?: ISnackbarOptions) => {
    return { message, options };
  };

  const pushSnackbar = (message: string, options?: ISnackbarOptions) => {
    setSnackbar((prev) => {
      if (checkOverlapMessage(message, prev)) return prev;
      return [...prev, makeSnackbar(message, options)];
    });
  };

  const unshiftSnackbar = (message: string, options?: ISnackbarOptions) => {
    setSnackbar((prev) => {
      if (checkOverlapMessage(message, prev)) return prev;
      return [makeSnackbar(message, options), ...prev];
    });
  };

  const removeSnackbar = (message: ISnackBar['message']) => {
    setSnackbar((prev) => prev.filter((item) => item.message !== message));
  };

  return { snackbar, pushSnackbar, unshiftSnackbar, removeSnackbar };
};

/**
 * 페이지 reload 시 session storage에 쌓아놓은 메세지를 표시하기 위해 구현된 함수입니다.
 * [todo] array 형태로 저장이 가능하도록 변경 필요
 */
export const useDisplaySnackbarOnReload = () => {
  const { pushSnackbar } = useSnackbar();

  const moveMessageToStore = () => {
    const snackbarMessageOnReload = sessionStorage.getItem('snackbarMessageOnReload');
    if (!snackbarMessageOnReload) return;
    pushSnackbar(snackbarMessageOnReload);
    sessionStorage.removeItem('snackbarMessageOnReload');
  };

  const setSnackbarMessageOnReload = (message: string) => {
    sessionStorage.setItem('snackbarMessageOnReload', message);
  };

  return {
    moveMessageToStore,
    setSnackbarMessageOnReload,
  };
};
