import { sign } from 'jsonwebtoken';

export function padZero(num) {
  return num.toString().padStart(2, '0');
}

export function getLocalTimezoneDate(offsetInMinutes) {
  const dateInTimezone = new Date(Date.now() + offsetInMinutes * 60000);
  const year = dateInTimezone.getUTCFullYear();
  const month = padZero(dateInTimezone.getUTCMonth() + 1);
  const date = padZero(dateInTimezone.getUTCDate());
  const hours = padZero(dateInTimezone.getUTCHours());
  const minutes = padZero(dateInTimezone.getUTCMinutes());
  return `${year}${month}${date}${hours}${minutes}`;
}

export function getKoreaDate() {
  const utcCurrentDate = new Date();
  const utc = utcCurrentDate.getTime() + utcCurrentDate.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
}

export function getKoreaDateOffsetInMinutes(offsetInMinutes) {
  const utcCurrentDate = new Date(Date.now() + offsetInMinutes * 60000);
  const utc = utcCurrentDate.getTime() + utcCurrentDate.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
}
export function getLastDateOfCurrentMonth(month = null) {
  const current = new Date();
  const year = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  return new Date(year, month ? month : currentMonth, 0).getDate();
}

export function getFirstDayOfCurrentMonth(month = null) {
  const current = new Date();
  const year = current.getFullYear();
  const currentMonth = current.getMonth();
  return new Date(year, month ? month : currentMonth, 1).getDay();
}


export const secondToObject = (secondArg: number | `${number}`) => {
  let intSecond = typeof secondArg === 'string' ? parseInt(secondArg) : secondArg;
  const sign = (intSecond < 0) ? '-' : '';
  intSecond = Math.abs(intSecond);
  const day = Math.floor(intSecond / 86400);
  const fullhour = Math.floor(intSecond / 3600);
  const hour = Math.floor((intSecond % 86400) / 3600);
  const minute = Math.floor((intSecond % 3600) / 60);
  const second = intSecond % 60;
  return { sign, day, fullhour, hour, minute, second };
};
export const secondToFormat = (secondArg: number | `${number}`, showType: 'date' | 'time' | 'datetime' | 'none', dayUnit = '일') => {
  const { day, fullhour, hour, minute, second } = secondToObject(secondArg);
  const timeArray = [];
  showType.endsWith('time') && timeArray.push(`${String(showType === 'datetime' ? hour : fullhour).padStart(2, '0')}`);
  showType.endsWith('time') && timeArray.push(`${String(minute).padStart(2, '0')}`);
  showType.endsWith('time') && timeArray.push(`${String(second).padStart(2, '0')}`);
  return (day && showType.startsWith('date') ? `${day}${dayUnit}${(showType.endsWith('time') ? ' ' : '')}` : '') + timeArray.join(':');
};

export const getUtcDate = () => {
  const date = new Date();
  const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  return new Date(now_utc);
};
