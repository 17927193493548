import { LanguageCodeEnum } from 'src/generated/graphql';

export enum NotiflyEventHandlers {
  getUserId = 'GET_USER_ID',
  userSessionStart = 'USER_SESSION_START',
  userSessionEnd = 'USER_SESSION_END',
  SetUserProperties = 'SET_USER_PROPERTIES',
  TrackEvent = 'TRACK_EVENT',
}

export enum NotiflyEventCode {
  Join = 'ev-join',
  Withdrawal = 'ev-drawal',
  Login = 'ev-login',
  CashCharge = 'ev-cash-charge',
  PvProduct = 'ev-pv-product',
  OrderCreation = 'ev-order',
  Checkout = 'ev-checkout',
  InquiryResponse = 'ev-inquiry',
  TrackEventTest = 'track-event-test',
}

interface ShoppingCartDiscount {
  isDiscountProductInCart: boolean;
  latestCartDiscountProductName: string;
}

interface WishlistDiscount {
  isDiscountProductInWishlist: boolean;
  latestWishlistDiscountProductName: string;
}

interface CashCharge {
  chargePrice: number;
}

interface ProductDetailView {
  isDiscounted: boolean;
  productName: string;
  productId: number;
  brandId: string;
  categoryId: string;
}

interface Order {
  productIdAll: string[];
  orderPrice: number;
}

interface Checkout {
  productIdAll: string[];
  orderPrice: number;
  realPrice: number;
}

export type DynamicEventCode = `ev-coupon-${'issuance' | 'use'}:${string}`;

interface IUserProperties {
  $phone_number: string; // 전화번호
  $email: string; // 이메일
  name?: string; // 이름
  nation?: LanguageCodeEnum; // 국가(ko, en, ja, zh)
  marketing_agreement_email?: string; // 마케팅수신동의여부(이메일)
  marketing_agreement_phone?: string; // 마케팅수신동의여부(휴대폰)
  occupation?: string; // 직종(관심사)
}

export interface INotiflyProperties {
  userId?: string;
  userProperties?: IUserProperties;
  eventCode?: NotiflyEventCode | DynamicEventCode;
  eventProperties?: ShoppingCartDiscount | WishlistDiscount | CashCharge | ProductDetailView | Order | Checkout;
}
