import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useCookies } from 'react-cookie';
import { CustomLink } from '@components/ui/Link';
import { CustomText } from '@components/ui/Text';
import { useNewResponsiveDevice } from '@hooks/useResponsiveDevice';
import { ChromeButton, ChromeButtonText, MessageAndButtonWrapper, MessageText, MobileNotShowAgainButton, NotShowAgainButton, Wrapper } from './styled';

/**
 * 크롬 호환성 안내 컴포넌트
 * 30일간 보지 않기 상태일 경우 렌더링 하지 않음
 * @returns React Component || null
 */
export default function Recommend({ show }: { show: boolean }) {
  const { t } = useTranslation(['unsupported']);
  const [cookie, setCookie] = useCookies();
  const skipOutdatedBrowserNotification = cookie?.skipOutdatedBrowserNotification;
  const [isMobile, setIsMobile] = useState(false);
  const { isMobile: isMobileOrigin } = useNewResponsiveDevice();

  const [isShow, setIsShow] = useState(false);

  /**
   * 띠배너 30일간 보지 않음
   */
  function dontShowLongTime() {
    // 한달뒤 날짜를 가져옴
    const date = new Date();
    date.setMonth(date.getMonth() + 1);

    // 한달 동안 유지되는 쿠키 생성
    setCookie('skipOutdatedBrowserNotification', 1, { path: '/', expires: date });
  }

  function onClickClose() {
    dontShowLongTime();
    setIsShow(false);
  }

  useEffect(() => {
    setIsMobile(isMobileOrigin);
  }, [isMobileOrigin]);
  useEffect(() => {
    if (!show || parseInt(skipOutdatedBrowserNotification || 0, 10) === 1) return;
    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 6000);
  }, [show, skipOutdatedBrowserNotification]);

  return (
    <Wrapper show={isShow}>
      <div />
      <MessageAndButtonWrapper>
        <MessageText>{t('unsupported:recommend')}</MessageText>

        <CustomLink href={'https://www.google.com/chrome/'} newWindow>
          <ChromeButton>
            <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? 15 : 11} height={isMobile ? 14 : 10} viewBox="0 0 11 10" fill="none" suppressHydrationWarning={false}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.850098 5.30002C0.850098 2.73431 2.93438 0.650024 5.5001 0.650024C8.06582 0.650024 10.1501 2.73431 10.1501 5.30002C10.1501 7.86574 8.06582 9.95002 5.5001 9.95002C2.93438 9.95002 0.850098 7.86574 0.850098 5.30002ZM1.96192 3.56291C1.70435 4.08675 1.5592 4.67589 1.5592 5.3C1.5592 7.29643 3.03845 8.94268 4.96221 9.20431L5.97966 7.44217C5.82267 7.47741 5.66188 7.49538 5.50017 7.49548C5.12076 7.49498 4.74766 7.39616 4.41781 7.20866C4.09074 7.02273 3.81698 6.7559 3.62274 6.43382C3.61415 6.42248 3.60625 6.41064 3.59908 6.39836L1.96192 3.56291ZM5.5001 3.81365C5.10589 3.81365 4.72783 3.97025 4.44908 4.249C4.17033 4.52774 4.01373 4.90581 4.01373 5.30001C4.01373 5.69422 4.17033 6.07229 4.44908 6.35103C4.72783 6.62978 5.10589 6.78638 5.5001 6.78638C5.89431 6.78638 6.27237 6.62978 6.55112 6.35103C6.82986 6.07229 6.98646 5.69422 6.98646 5.30001C6.98646 4.90581 6.82986 4.52774 6.55112 4.249C6.27237 3.97025 5.89431 3.81365 5.5001 3.81365ZM7.11354 3.81365C7.25226 3.96399 7.36974 4.13347 7.46212 4.31746C7.61519 4.62234 7.69511 4.95869 7.69553 5.29983C7.69466 5.66895 7.60073 6.03237 7.42251 6.35549C7.41645 6.3701 7.40942 6.38428 7.40145 6.39796L5.76516 9.23218C7.82014 9.09616 9.44102 7.38982 9.44102 5.30001C9.44102 4.77357 9.33772 4.27214 9.1511 3.81365H7.11354ZM2.38745 2.88156L3.40667 4.64693C3.52345 4.27279 3.73911 3.93422 4.03415 3.66883C4.42837 3.3142 4.93756 3.11429 5.46761 3.10589L8.77318 3.10459C8.06616 2.05139 6.86531 1.35913 5.50005 1.35913C4.23351 1.35913 3.10826 1.9547 2.38745 2.88156Z"
                fill="white"
              />
            </svg>
            <ChromeButtonText>{t('unsupported:openChrome')}</ChromeButtonText>
          </ChromeButton>
        </CustomLink>
      </MessageAndButtonWrapper>

      {/** 30일간 표시하지 않기 */}
      <NotShowAgainButton size={10} color={'#88888A'} onClick={onClickClose}>
        {t('unsupported:dontShowLongTime')}
      </NotShowAgainButton>

      <MobileNotShowAgainButton onClick={onClickClose}>
        <NotShowAgainButton size={10} color={'#88888A'}>
          {t('unsupported:dontShowLongTime')}
        </NotShowAgainButton>
      </MobileNotShowAgainButton>
    </Wrapper>
  );
}
