import React from 'react';

interface Props {
  isActive: boolean;
}

export default function HomeIcon({ isActive }: Props) {
  return (
    <>
      {isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2805_86165)">
            <circle cx="12" cy="7.5" r="3" fill="black" stroke="black" strokeWidth="1.2" />
            <path d="M12 13.5C7.58172 13.5 4 16.1863 4 19.5H20C20 16.1863 16.4183 13.5 12 13.5Z" fill="black" stroke="black" strokeWidth="1.2" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2805_86165">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}

      {!isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2805_86134)">
            <circle cx="12" cy="7.5" r="3" stroke="#313135" strokeWidth="1.2" />
            <path d="M12 13.5C7.58172 13.5 4 16.1863 4 19.5H20C20 16.1863 16.4183 13.5 12 13.5Z" stroke="#313135" strokeWidth="1.2" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2805_86134">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
}
