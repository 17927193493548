import dayjs from 'dayjs';
import { IBannerCreateInput, IBannerDetail } from '@api/banner/banner.types';

export const convert = (data: Partial<IBannerCreateInput<unknown>>) => ({
  ...data,
  property: JSON.stringify(data.property),
});

export const filterNow = <T=IBannerDetail>(list: Array<IBannerDetail>): Array<T> => {
  const now = dayjs();
  return list.filter(item => {
    return dayjs(item.since).isBefore(now) && dayjs(item.until).isAfter(now);
  }) as Array<T>;
};

export const secondToFormat = (second: number | `${number}`, showType: 'date' | 'time' | 'datetime' | 'none', days = '일') => {
  let intSecond = typeof second === 'string' ? parseInt(second) : second;
  const d = (intSecond < 0) ? '-' : '';
  intSecond = Math.abs(intSecond);
  const day = Math.floor(intSecond / 86400);
  const hour = Math.floor((intSecond % 86400) / 3600);
  const fullhour = Math.floor(intSecond / 3600);
  const min = Math.floor((intSecond % 3600) / 60);
  const sec = intSecond % 60;
  const timeArray = [];
  showType.endsWith('time') && timeArray.push(`${String(showType === 'datetime' ? hour : fullhour).padStart(2, '0')}`);
  showType.endsWith('time') && timeArray.push(`${String(min).padStart(2, '0')}`);
  showType.endsWith('time') && timeArray.push(`${String(sec).padStart(2, '0')}`);
  return d + (showType.startsWith('date') ? `${day}${days}${(showType.endsWith('time') ? ' ' : '')}` : '') + timeArray.join(':');
};

export const setTime = (date: Date|string, select: 'start' | 'end') => {
  const newDate = new Date(date);
  try {
    if (select === 'start') {
      newDate.setHours(0, 0, 0, 0);
    } else {
      newDate.setHours(23, 59, 59, 999);
    }
  } catch (e) {
    /* error */
  }
  return newDate.toISOString();
};

export const hexToRgba = (hex: string, alpha?: number) => {
  try {
    const bigint = parseInt(hex, 16);
    const attr = 'rgb' + (alpha ? 'a' : '');
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${attr}(${r}, ${g}, ${b}` + (alpha ? `, ${alpha}%)` : ')');
  } catch (e) {
    return hex;
  }
};
