import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { AblurIcon, NewIcon, Typography, WindowIcon, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Box } from '@mui/material';
import Link from 'next/link';
import { Fragment } from 'react';
import { DividerText, MenuItem, MenuItemText, MenuList, MenuSkeleton, SubGnbContainer } from '@components/_new_components/Common/layout/Header/SubGnb/SubGnb.styles';
import { CustomLink } from '@components/ui/Link';
import { GNBClickLog } from '@lib/mixpanel/events';
import { SUBGNB_TAB_NAME_MAP } from '@components/_new_components/Common/layout/Header/Header.constants';
import { MENU_LIST_BY_INDUSTRY } from '@components/_new_components/Common/layout/Header/SubGnb/SubGnb.constants';
import { TActivePath } from '@components/_new_components/Common/layout/Header/SubGnb/SubGnb.types';
import { LANGUAGE_CODE } from '../../../../../../src/constants';
import { useIndustry } from '../../../../../../src/stores/industry/useIndustryStore';
import { Industry } from '../../../../../../src/constants/industry.constants';
import NewBadgeIcon from '../../../../../../src/assets/icons/gnb/NewBadgeIcon';

export default function SubGnb() {
  const { pathname, query } = useRouter();
  const { t, i18n } = useTranslation(['menu']);
  const { isReadyInitialized, industry } = useIndustry();
  const theme = useDesignSystemTheme();

  const menuTypographyResponsiveVariant = {
    black: {
      smallTablet: 'typography/body/medium/black',
      mobile: 'typography/body/small/black',
    },
    medium: {
      smallTablet: 'typography/body/medium/medium',
      mobile: 'typography/body/small/medium',
    },
  };

  const filterGnbMenuList = !isReadyInitialized
    ? []
    : MENU_LIST_BY_INDUSTRY[industry].filter((item) => {
        if (i18n.language === LANGUAGE_CODE.CN && ['adult', 'funding'].includes(item.key)) return false;
        if (i18n.language !== LANGUAGE_CODE.KO && item.key === 'community') return false;
        if (i18n.language !== LANGUAGE_CODE.KO && industry === Industry.GAME && item.key === 'divider') return false;
        return true;
      });

  const handleMenuClick = (classname: string, href: string) => {
    GNBClickLog({ name: SUBGNB_TAB_NAME_MAP[classname], destination_url: `https://www.acon3d.com/${i18n.language}/${href}` });
  };

  const checkActivePathQueryString = (keys: TActivePath['query']) => {
    const queryStringKeys = Object.keys(keys);
    if (queryStringKeys.length === 0) return false;
    return queryStringKeys.every((key) => query[key] && query[key] === keys[key]);
  };

  const checkActivePath = (activePath: TActivePath) => {
    if (!activePath) return false;
    if (pathname === activePath.pathname) {
      if (!activePath.query) return true;
      return checkActivePathQueryString(activePath.query);
    }
    return false;
  };

  return (
    <SubGnbContainer>
      <MenuList>
        {!isReadyInitialized &&
          new Array(5).fill(null).map((_, idx) => (
            <MenuItem key={idx}>
              <MenuSkeleton />
            </MenuItem>
          ))}

        {isReadyInitialized &&
          filterGnbMenuList.map((menu, idx) => {
            const isActive = checkActivePath(menu.activePath);
            const isHighlight = menu.key === 'aconOnly';

            if (menu.key === 'divider') {
              return (
                <MenuItem key={idx}>
                  <DividerText variant={'typography/body/medium/medium'} color={'color/gray/800'} sx={{ typography: menuTypographyResponsiveVariant.medium }}>
                    |
                  </DividerText>
                </MenuItem>
              );
            }
            if (menu.key === 'ablur') {
              if (i18n.language !== LANGUAGE_CODE.KO) return <></>;
              return (
                <Fragment key={menu.key}>
                  <Box sx={{ [theme.breakpoints.up('smallTablet')]: { display: 'none' } }}>
                    <MenuItem>
                      <DividerText variant={'typography/body/medium/medium'} color={'color/gray/800'} sx={{ typography: menuTypographyResponsiveVariant.medium }}>
                        |
                      </DividerText>
                    </MenuItem>
                  </Box>
                  <Link target="_blank" href={menu.href} style={{ marginLeft: 'auto' }}>
                    <MenuItem className="no-hover" gap="2px">
                      <AblurIcon />
                      <Typography variant={'typography/body/medium/medium'} whiteSpace="pre" color={'color/gray/800'} sx={{ typography: menuTypographyResponsiveVariant.medium }}>
                        에이블러
                      </Typography>
                      <NewIcon />
                    </MenuItem>
                  </Link>
                </Fragment>
              );
            }

            return (
              <CustomLink
                key={menu.key}
                href={menu.href}
                onClick={() => {
                  handleMenuClick(menu.tagging, menu.href);
                }}
              >
                <MenuItem className={menu.tagging} isActive={isActive} isHighlight={isHighlight}>
                  <MenuItemText
                    variant={isHighlight ? 'typography/body/medium/black' : 'typography/body/medium/medium'}
                    color={isHighlight ? 'color/primary/600' : isActive ? 'color/black' : 'color/gray/800'}
                    sx={{ typography: isHighlight ? menuTypographyResponsiveVariant.black : menuTypographyResponsiveVariant.medium }}
                  >
                    {t(`menu:subgnb.${menu.key}`)}
                  </MenuItemText>

                  {/* {isHighlight && (
                    <Box>
                      <Box sx={{ [theme.breakpoints.down('smallTablet')]: { display: 'none' } }}>
                        <NewBadgeIcon width={16} height={16} />
                      </Box>
                      <Box sx={{ display: 'none', [theme.breakpoints.down('smallTablet')]: { display: 'block' } }}>
                        <NewBadgeIcon width={14} height={14} />
                      </Box>
                    </Box>
                  )} */}
                </MenuItem>
              </CustomLink>
            );
          })}
      </MenuList>
    </SubGnbContainer>
  );
}
