import styled from '@emotion/styled';

export const CloseButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 15px;
  color: white;
  display: flex;
  height: 100%;
  align-items: center;
`;
