// 네이버 애널리틱스 광고 분석 스크립트 이벤트 전송용

declare global {
  interface Window {
    wcs: any;
  }
}

enum NaverAnalyticsType {
  PURCHASE = '1',
  COMPLATE_REGISTRATION = '2',
  ADD_CART = '3',
}

export const eventType = NaverAnalyticsType;

export const event = (type: NaverAnalyticsType, value: string): void => {
  if (typeof window === 'undefined') {
    return;
  }
  const _nasa: any = {};
  if (window.wcs) {
    _nasa.cnv = window.wcs.cnv(type.toString(), value);
  }
};
