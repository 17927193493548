import { EVENT_NAME } from '@lib/mixpanel/events.constants';
import { discountAmount } from '@lib/price';
import { pageHistoryToPageName } from '@util/pageHistoryToPageName';
import {
  IJoinEvent,
  IProductClickEvent,
  IShareViewEvent,
  ISortOrderEvent,
  IFilterEvent,
  IScrapEvent,
  IBuyCartLog,
  ICheckoutSuccessLog,
  IDirectBuyLog,
  IGNBClickLog,
  IButtonClickLog,
  IProductListLog,
  IClickBannerLog,
  IClickTagLog,
  AddCartLogProps,
} from './type';
import mixpanel from '.';

export const productClickEventLog = (property: IProductClickEvent) => {
  if (!property.pageName) {
    return;
  }

  mixpanel.event(EVENT_NAME.PRODUCT_CLICK, {
    'Page Name': property.pageName,
    /** https://www.figma.com/design/3MS0lghpcrGqXlRbXXNARx/%EB%AF%B9%EC%8A%A4%ED%8C%A8%EB%84%90-%ED%9A%A8%EC%9C%A8%ED%99%94?node-id=1-10215&m=dev */
    'Touch Area': property.actionName,
    'Product Name': property.productName,
    'Product No': property.productNo,
    ...(property?.groupTitle ? { 'Group Title': property?.groupTitle } : {}),
    ...(property?.groupSno ? { 'Group Sno': property?.groupSno } : {}),
    ...(property?.keyword ? { Keyword: property?.keyword } : {}),
    ...(property?.discountRate !== undefined ? { 'Discount Rate': property.discountRate } : {}),
    Index: `order_${property.order}`,
  });

  return true;
};

export const shareViewEventLog = (property: IShareViewEvent) => {
  if (!property.url) {
    return;
  }
  mixpanel.event(EVENT_NAME.SHARE_VIEW, property);
};

export const productHistoryEventLog = (returnUrl: string) => {
  const url = new URL(window.location.origin + returnUrl);
  const mixpanelProperties: IJoinEvent = {};
  const urlParams = url?.search ? new URLSearchParams(url.search) : null;
  const splitUrl = (url.pathname?.split('/') || []).filter(Boolean);

  ['category', 'brand', 'product'].forEach((item) => {
    if (splitUrl.length === 2 && splitUrl[0] === item) {
      const valueKeyName = item === 'product' ? 'productNo' : 'categoryCode';
      mixpanelProperties.type = item;
      mixpanelProperties[valueKeyName] = splitUrl[1];
    }
  });

  if (splitUrl[0] === 'search' && urlParams?.get('keyword')) {
    mixpanelProperties.type = 'search';
    mixpanelProperties.keyword = urlParams.get('keyword');
  }

  if (Object.keys(mixpanelProperties).length > 0) {
    mixpanel.event(EVENT_NAME.PRODUCT_HISTORY, mixpanelProperties);
  }
};

export const filterEventLog = (props: IFilterEvent) => {
  props.minPrice = props.minPrice === 0 ? null : props.minPrice;
  props.maxPrice = props.maxPrice === 0 ? null : props.maxPrice;
  mixpanel.event(EVENT_NAME.USE_FILTER, props);
};

export const sortOrderEventLog = (props: ISortOrderEvent) => {
  mixpanel.event(EVENT_NAME.SORT_ORDER, props);
};

export const scrapLog = (props: IScrapEvent, isTrackGA4 = true) => {
  mixpanel.event(
    EVENT_NAME.SCRAP,
    {
      'Product Nos': props.productNos,
      'Product Status': props.productStatus,
      type: props.type,
      url: location.href.replaceAll(location.origin, ''),
    },
    isTrackGA4,
  );
};

export const buyCartLog = (props: IBuyCartLog) => {
  mixpanel.event(EVENT_NAME.BUY_CART, {
    'Discount amount': props.discountAmount,
    amount: props.amount,
    status: props.status,
    'Product Nos': props.productNos,
  });
};

// [todo]: Product Nos를 제외한 상품 번호 프로퍼티는 제거해야 함.
export const checkoutSuccessLog = (props: ICheckoutSuccessLog) => {
  mixpanel.event(
    EVENT_NAME.CHECKOUT,
    {
      state: 'success',
      orderNo: props.orderNo,
      amount: props.amount,
      'Acon Cash used': props.usedAconCash,
      'Point used': props.usedPoint,
      'Coupon used': props.usedCoupon,
      productNos: props.productNos.map((no) => no?.toString()),
      'Product Nos': props.productNos,
    },
    false,
  );
};

export const directBuyLog = (props: IDirectBuyLog) => {
  mixpanel.event(EVENT_NAME.DIRECT_BUY, {
    title: props.title,
    price: props.price,
    'discount rate': props.discountRate,
    brand: props.brand,
    extensions: props.extensions,
    'Number of reviews': props.reviewCount,
    'Number of views': props.viewCount,
    'Acon Only': props.isAconOnly,
    'Additional discount': props.isDiscount,
    'Product Nos': props.productNos,
  });
};

export const GNBClickLog = (props: IGNBClickLog) => {
  mixpanel.event(EVENT_NAME.GNB_CLICK, { name: props.name, ...(props?.destination_url ? { destination_url: props?.destination_url } : {}) });
};

export const buttonClickLog = (props: IButtonClickLog) => {
  mixpanel.event(EVENT_NAME.BUTTON_CLICK, {
    action_name: props.actionName,
    ...(props?.userId ? { userId: props?.userId } : {}),
    ...(props?.orderNo ? { orderNo: props?.orderNo } : {}),
    ...(props?.productNo ? { productNo: props?.productNo } : {}),
    ...(props?.destination_url ? { destination_url: props?.destination_url } : {}),
    ...(props?.value ? { value: props?.value } : {}),
    ...(props?.categoryName ? { 'category name': props?.categoryName } : {}),
    ...(props?.categoryCode ? { 'category code': props?.categoryCode } : {}),
    ...(props?.brandCount ? { searched_brand_count: props?.brandCount } : {}),
    ...(props?.brandIndex !== undefined && props?.brandIndex !== null ? { searched_brand_index: props?.brandIndex } : {}),
  });
};

export const productListLog = (props: IProductListLog) => {
  mixpanel.event('Product List', {
    type: props.type,
    Extensions: props.extensions,
    ...(props?.categoryCode ? { sort: props.sort } : {}),
    ...(props?.categoryCode ? { 'category code': props.categoryCode } : {}),
    ...(props?.tagId ? { 'tag id': props.tagId } : {}),
    ...(props?.brandCode ? { brand_code: props.brandCode } : {}),
    ...(props?.page ? { brand_index: props.page } : {}),
  });
};

export const clickBannerLog = (props: IClickBannerLog) => {
  mixpanel.event('Click Banner', {
    type: props.type,
    ...(props?.link ? { link: props.link } : {}),
    ...(props?.value ? { value: props?.value } : {}),
  });
};

export const clickTagLog = (props: IClickTagLog) => {
  mixpanel.event(EVENT_NAME.TAG_CLICK, {
    alias: props.alias,
    tagType: props.tagType,
    isAdult: props?.isAdult,
  });
};

export const addCartLog = (props: AddCartLogProps) => {
  mixpanel.event(
    EVENT_NAME.ADD_CART,
    {
      type: props.type,
      goodsNos: props.goodsNos,
      'Product Nos': props['Product Nos'],
      status: props.status,
    },
    false,
  );
};

export const productDetailLog = ({
  title,
  priceInfo,
  brandInfo,
  extensions,
  reviewCount,
  reviewRating,
  promotionEndDate,
  isAdultOnly,
  id,
  prevPageUrl,
  viewCount,
}: {
  title: string;
  priceInfo: {
    price: number;
    salePrice: number;
  };
  brandInfo: {
    name: string;
    isAcon3DExclusive: boolean;
  };
  extensions: string[];
  reviewCount: number;
  reviewRating: number;
  promotionEndDate: string;
  isAdultOnly: boolean;
  id: number;
  prevPageUrl?: string;
  viewCount?: number;
}) => {
  mixpanel.event(
    'Product Detail',
    {
      title: title,
      price: priceInfo.salePrice,
      'discount rate': discountAmount(priceInfo.price, priceInfo.salePrice),
      brand: brandInfo.name,
      extensions,
      'Number of views': viewCount ?? '-',
      'Number of reviews': reviewCount,
      'Review Rating': reviewRating.toFixed(2),
      'Acon Only': brandInfo.isAcon3DExclusive,
      'Additional discount': Boolean(promotionEndDate && promotionEndDate.startsWith('1970-01-01')),
      'Product Nos': [id],
      adult: isAdultOnly,
      'Previous Page': prevPageUrl ? pageHistoryToPageName(prevPageUrl) : '-',
    },
    false,
  );
};
