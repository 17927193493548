import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { INITIAL_ONGOING_EVENT_STATE } from './useOnGoingEvent.constants';
import { IOnGoingEventState, IOnGoingEventSetState, TSetterKeys } from './useOnGoingEvent.type';

/**
 * @store onGoingEventStore
 * @return {onGoingEvents: EVENTS[]}
 * @description
 *
 * 이벤트의 시작,종료 시간을 체크,
 * 오늘날짜에 진행중인 이벤트들의 코드들를 담아 리턴
 *
 * 이벤트 등록 시 utc 시간대을 세팅하여 국가별 시작,종료시간을 동기화
 *
 * onGoingEventStore} 활용 이벤트 추가 방법
 * - useOnGoingEventType.ts 파일에 enum EVENTS 이벤트 코드를 추가
 * - useOnGoingEvent.constants.ts 파일에 EVENTS_PERIOD 이벤트 시작,종료 시간을 추가
 *
 */
export const useOnGoingEventStore = createWithEqualityFn<IOnGoingEventState & IOnGoingEventSetState>((set) => {
  const INITIAL_ONGOING_EVENT_SET_STATE = {} as IOnGoingEventSetState;

  for (const key in INITIAL_ONGOING_EVENT_STATE) {
    const keyName = `set${key[0].toUpperCase()}${key.slice(1)}` as TSetterKeys;
    INITIAL_ONGOING_EVENT_SET_STATE[keyName] = (state) => set(() => ({ [key]: state }));
  }

  return {
    ...INITIAL_ONGOING_EVENT_STATE,
    ...INITIAL_ONGOING_EVENT_SET_STATE,
  };
}, shallow);
