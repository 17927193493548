import React, { FC } from 'react';
import Link, { LinkProps } from 'next/link';

interface props extends LinkProps {
  className?: string;
  disabled?: boolean;
  newWindow?: boolean;
  children?: React.ReactNode;
  onClick?: any;
  display?: string;
  prefetch?: boolean;
  [key: `data-mixpanel-${string}`]: string;
}

const getPropsAboutMixpanel = (props: Partial<props>) => Object.fromEntries(Object.entries(props).filter(([key]) => key.includes('data-mixpanel-')));

/**
 * 기존 a 태그로 영역을 감싸면 전체가 감싸지지 않아서 태깅할 시 문제가 발생 함.
 * display: block 스타일을 추가하여 전체 감싸지도록 컴포넌트 추가
 */
const CustomLink: FC<props> = ({ href, className, disabled, newWindow, children, onClick, display = 'block', prefetch = false, ...rest }: props) => {
  const styleObj: any = {
    display,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  if (!href) {
    return <span className={className} style={styleObj} onClick={onClick} {...getPropsAboutMixpanel(rest)} {...rest}>
    {children}
  </span>;
  }

  return (
    <Link href={href} prefetch={prefetch} passHref legacyBehavior>
      <a className={className} style={styleObj} target={newWindow ? '_blank' : '_self'} onClick={onClick} {...getPropsAboutMixpanel(rest)}>
        {children}
      </a>
    </Link>
  );
};

export { CustomLink };
