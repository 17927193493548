import React from 'react';

export default function CategorySoundIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.61749 22.3522C3.6228 22.3522 2 20.7294 2 18.7347C2 16.74 3.6228 15.1172 5.61749 15.1172C7.61219 15.1172 9.23499 16.74 9.23499 18.7347C9.23499 20.7294 7.57838 22.3522 5.61749 22.3522ZM5.61749 16.9428C4.63705 16.9428 3.82565 17.7542 3.82565 18.7347C3.82565 19.7151 4.63705 20.5265 5.61749 20.5265C6.59794 20.5265 7.40934 19.7151 7.40934 18.7347C7.40934 17.7542 6.59794 16.9428 5.61749 16.9428Z"
        fill="black"
      />
      <path
        d="M17.3753 20.2233C15.3806 20.2233 13.7578 18.6005 13.7578 16.6058C13.7578 14.6111 15.3806 12.9883 17.3753 12.9883C19.37 12.9883 20.9928 14.6449 20.9928 16.6058C20.9928 18.5667 19.37 20.2233 17.3753 20.2233ZM17.3753 14.8477C16.3949 14.8477 15.5835 15.6591 15.5835 16.6396C15.5835 17.62 16.3949 18.4314 17.3753 18.4314C18.3558 18.4314 19.1671 17.62 19.1671 16.6396C19.1671 15.6591 18.3896 14.8477 17.3753 14.8477Z"
        fill="black"
      />
      <path
        d="M8.28783 19.6472C7.7807 19.6472 7.375 19.2415 7.375 18.7343V7.07045C7.375 6.22525 7.91593 5.48146 8.69353 5.17719L18.2613 1.62731C18.8698 1.39065 19.5798 1.49208 20.1208 1.86397C20.6617 2.23586 20.9998 2.84441 20.9998 3.52058V16.6044C20.9998 17.1115 20.5941 17.5172 20.0869 17.5172C19.5798 17.5172 19.1741 17.1115 19.1741 16.6044V3.55439C19.1741 3.48677 19.1403 3.41915 19.1065 3.38534C19.0727 3.35154 19.0051 3.35154 18.9375 3.35154L9.33588 6.90141C9.26827 6.93522 9.20065 7.00284 9.20065 7.07045V18.7343C9.20065 19.2415 8.79495 19.6472 8.28783 19.6472Z"
        fill="black"
      />
      <path
        d="M8.28783 12.1472C7.7807 12.1472 7.375 11.7415 7.375 11.2343V10.5705C7.375 9.72525 7.91593 8.98146 8.69353 8.67719L18.2613 5.12731C18.8698 4.89065 19.5798 4.99208 20.1208 5.36397C20.6617 5.73586 20.9998 6.34441 20.9998 7.02058V8.10441C20.9998 8.61154 20.5941 9.01724 20.0869 9.01724C19.5798 9.01724 19.1741 8.61154 19.1741 8.10441V7.05439C19.1741 6.98677 19.1403 6.91915 19.1065 6.88534C19.0727 6.85154 19.0051 6.85154 18.9375 6.85154L9.33588 10.4014C9.26827 10.4352 9.20065 10.5028 9.20065 10.5705V11.2343C9.20065 11.7415 8.79495 12.1472 8.28783 12.1472Z"
        fill="black"
      />
    </svg>
  );
}
