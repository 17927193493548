import React from 'react';

export default function TwitterGrayIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_249_42330)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C15.5229 0 20 4.47715 20 10C20 15.5229 15.5229 20 10 20C4.47715 20 0 15.5229 0 10C0 4.47715 4.47715 0 10 0Z"
          fill="#6D6D70"
        />
        <path
          d="M13.1822 5H14.8792L11.1716 9.23765L15.5334 15.004H12.1181L9.44322 11.5066L6.38251 15.004H4.68439L8.64997 10.4714L4.46582 5H7.96764L10.3855 8.19662L13.1822 5ZM12.5865 13.9882H13.5269L7.45672 5.96249H6.4477L12.5865 13.9882Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_249_42330">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
