import { LanguageCode } from 'src/constants';

export type ProductCardType = {
  discountPercentage: number;
  goodsNo: string;
  brandCd: string;
  imageUrl: string;
  subImageUrl: string;
  brandName: string;
  title: string;
  price: number;
  realPrice?: number;
  reviewCount: number;
  viewCount: number;
  extensions: string[];
  productViewUrl: string;
  badgeName?: string;
  // [todo]: languageCode 사용하지 않는 것처럼 보임. 카드 타입 정리 시 확인 후 제거 필요
  languageCode?: LanguageCode;
  wishSno?: string;
  ranking?: number;
  isDisplayCartButton?: boolean;
  isGoods?: boolean;
  promotionEndDate?: string;
  isCart?: boolean;
  isDisplayOnScrap?: boolean;
  isScrapEdit?: boolean;
  selectedGoods?: any[];
  onChangeCardCheckBox?: any;
  onRemoveScrap?: any;
  onAddCart?: any;
  isPromotionTimer?: boolean;
  isAconOnly?: boolean;
  isPromotion?: boolean;
  isEndSales?: boolean;
  onSale?: boolean;
  isDisplayReviewCount?: boolean;
  isDisplayDiscountText?: boolean;
  isDisableThumbnail?: boolean;
  isBorderRound?: boolean;
  className?: string;
  onDisplay?: any;
  onClick?: ({ actionName, goodsNo, title }: ICardClickProps) => void;
  created?: any;
  isDesktop?: boolean;
  isExclusive?: boolean;
  isBook?: boolean;
};

export interface ICardClickProps {
  actionName: TActionName;
  goodsNo: string;
  title: string;
}

export type TActionName = 'title' | 'extension' | 'brand' | 'image';
/** https://www.figma.com/design/3MS0lghpcrGqXlRbXXNARx/%EB%AF%B9%EC%8A%A4%ED%8C%A8%EB%84%90-%ED%9A%A8%EC%9C%A8%ED%99%94?node-id=1-10205&m=dev */
export enum PageNameEnum {
  main = 'main',
  'acon-only' = 'acon-only',
  new = 'new',
  sale = 'sale',
  best = 'best',
  adult = 'adult',
  event = 'event',
  brand = 'brand',
  category = 'category',
  'keyword search' = 'keyword search',
  'tag search' = 'tag search',
  'prod detail' = 'prod detail',
}
export type TPageName = `${PageNameEnum}`;
