import axios from 'axios';
import { searchAutoCompleteInputType, searchAutoCompleteOutputType, searchPopularKeywordInputType, searchPopularKeywordOutputType } from '@api/artemis/artemis.types';

const artemisInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ARTEMIS_URL,
});

export async function searchAutoComplete(body: searchAutoCompleteInputType): Promise<searchAutoCompleteOutputType> {
  try {
    const { data } = await artemisInstance.get('search/auto-complete', { params: body });
    return data;
  } catch (err) {
    err.message = `[searchAutoComplete] 자동완성 데이터 호출 중 오류 발생 (${err.message})`;
    console.error(err);
  }
}

export async function searchPopularKeyword(body: searchPopularKeywordInputType): Promise<searchPopularKeywordOutputType> {
  try {
    const { data } = await artemisInstance.get('search/popular-keywords', { params: body });
    return data;
  } catch (err) {
    err.message = `[searchPopularKeyword] 인기 검색어 데이터 호출 중 오류 발생 (${err.message})`;
    console.error(err);
  }
}
