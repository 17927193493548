import React from 'react';

interface Props {
  isActive: boolean;
}

export default function HomeIcon({ isActive }: Props) {
  return (
    <>
      {isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.1111 20.5C19.602 20.5 20 20.1057 20 19.6192V10.3613C20 10.0895 19.8733 9.8329 19.6568 9.66603L12.5457 4.18553C12.2247 3.93816 11.7753 3.93816 11.4543 4.18553L4.34316 9.66603C4.12664 9.8329 4 10.0895 4 10.3613V19.6192C4 20.1057 4.39797 20.5 4.88889 20.5H19.1111Z"
            fill="black"
            stroke="black"
            strokeWidth="1.2"
          />
          <path d="M11.4 20.5V21.1H12.6V20.5H11.4ZM12.6 15C12.6 14.6686 12.3314 14.4 12 14.4C11.6686 14.4 11.4 14.6686 11.4 15H12.6ZM12.6 20.5V15H11.4V20.5H12.6Z" fill="white" />
        </svg>
      )}

      {!isActive && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.1111 20.5C19.602 20.5 20 20.1057 20 19.6192V10.3613C20 10.0895 19.8733 9.8329 19.6568 9.66603L12.5457 4.18553C12.2247 3.93816 11.7753 3.93816 11.4543 4.18553L4.34316 9.66603C4.12664 9.8329 4 10.0895 4 10.3613V19.6192C4 20.1057 4.39797 20.5 4.88889 20.5H19.1111Z"
            stroke="#313135"
            strokeWidth="1.2"
          />
          <path d="M11.4 20V20.6H12.6V20H11.4ZM12.6 15C12.6 14.6686 12.3314 14.4 12 14.4C11.6686 14.4 11.4 14.6686 11.4 15H12.6ZM12.6 20V15H11.4V20H12.6Z" fill="#313135" />
        </svg>
      )}
    </>
  );
}
